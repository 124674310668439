import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { logout } from "../../actions/auth_actions";
import { withRouter } from "react-router-dom";
import { Hidden, Typography, Badge } from "@material-ui/core";
import { ReactComponent as SmLogo } from "../Common/Logo/smLogo.svg";
import { ReactComponent as SmallCoin } from "../Common/Icons/smallCoin.svg";

import { ReactComponent as JobsIcon } from "../Common/Icons/nav_jobs.svg";
import { ReactComponent as JobsSelectedIcon } from "../Common/Icons/nav_jobs_selected.svg";

import { ReactComponent as ProfileIcon } from "../Common/Icons/nav_profile.svg";
import { ReactComponent as ProfileSelectedIcon } from "../Common/Icons/nav_profile_selected.svg";

import { ReactComponent as MissionsIcon } from "../Common/Icons/nav_missions.svg";
import { ReactComponent as MissionsSelectedIcon } from "../Common/Icons/nav_missions_selected.svg";
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import NotificationsCenter from "./NotificationsCenter";
import {
  setNotificationAmount
} from "../../actions/user_actions";
import {
  autoCompleteOccupation,
  triggerCustomerPortal
} from "../../actions/applicationAssistant_action";

import {
  manualOccSearch,
  addOccupationFilter,
} from "../../actions/job_actions";


import { OccupationSearch } from "./OccupationSearch";
const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    maxWidth: 130,
    maxHeight: 30,
    marginTop: 2,
    marginRight: 20,
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      maxHeight: 30,
      maxWidth: 86,
      marginRight: 10,
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: 28,
      maxWidth: 58,
      marginRight: 2,
    },
  },
  logocont: {
    flexGrow: 1,
  },
  menuitem: {
    minWidth: 150,
  },
  appbar: {
    maxHeight:64,
    minHeight:64,
    backgroundColor: "white",
    //boxShadow : "0px 0px 0px 1px #DDE0E5"
    //color: theme.palette.getContrastText(backgroundColorDefault)
  },
  button: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginRight: 5,
    [theme.breakpoints.down("xs")]: {
      marginRight: 10,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginRight: 3,
    },
  },
  buttonlabel: {
    fontWeight: "normal",
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      paddingLeft: 5,
      paddingRight: 5,
    },
    [theme.breakpoints.up(1250 + theme.spacing(3))]: {
      width: 1250,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    overflow: "hidden",
  },
  avatarbutton: {
    marginRight: 5,
  },
  name: {
    display: "inline",
    fontSize: 14,
    color: "#5A6375",
    fontWeight: "normal",
    cursor: "pointer",
  },
  expand: {
    width: 20,
    height: 30,
    color: "#B4B8BF",
    marginLeft: 3,
    marginBottom: -10,
  },
  colorGrey: {
    color: "#b4b8bf"
  },
  profileMenu:{
    display: "flex",
    alignItems: "center",
    width:"auto",
    [theme.breakpoints.down("sm")]: {
      width:30
    },
  }
});

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 2 : 10,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

class Navigation extends Component {
  state = {
    open: false,
    anchorEl: null,
  };

  handleMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      open: true,
    });
  };
  handleClose = () => {
    this.setState({
      anchorEl: null,
      open: false,
    });
  };

  getVariant(route) {
    if (this.props.location.pathname.indexOf(route) === 0) {
      return "primary";
    } else {
      return "default";
    }
  }

  componentDidMount() {}

  render() {
    const {
      classes,
      user: {
        fullName = "",
        firstName = "",
        //lastName = "",
        //email = "",
        showChat = true,
        profileImageURL = "",
        customNavLogo = false,
        contractDetails = {},
        notificationAmount = 0,
        orgDetails = {},
        desiredExp = [],
        preferredOccupations = [],
        previousOccupations = [],
        internalMobilityProvider,
        internalMobilityRoles
      },
      jobs: {
        fetchingJobs = false
      },
      rewards : {
        coins = 0
      },
      triggerCustomerPortal
    } = this.props;

    let user = this.props.user;
    let viewPermissions = {};
    if (user.providerDetails && user.providerDetails.viewPermissions) {
      viewPermissions = user.providerDetails.viewPermissions;
    }

    let occList = []

    for (const idx in desiredExp) {
      if(idx){
        occList.push(preferredOccupations[idx])
      }
    }
    occList.push(...previousOccupations)
  

    const careerpathwayTitle = () => {
      if (user && user.providerDetails.viewPermissions){
        if ((!user.providerDetails.viewPermissions.jobsCheckbox || !viewPermissions.promotedJobsCheckbox) && !user.providerDetails.viewPermissions.trainingCheckbox){
          return "Career Pathway";
        } else if ((user.providerDetails.viewPermissions.jobsCheckbox || viewPermissions.promotedJobsCheckbox) && !user.providerDetails.viewPermissions.trainingCheckbox){
          return "Training";
        } else {
          return "Jobs";
        }
      } else {
        return "Career Pathway";
      }
    }

    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    let [hideNotification, setHideNotifcation] = React.useState(false);
    let [searchBar, setSearchBar] = React.useState(false);
    const { hideMissions = false, jobsType = 0 } = contractDetails;
    let orgName =  (orgDetails.name) ? orgDetails.name : false;
    let orgInfo = (orgDetails.infoText) ? orgDetails.infoText : false;

    let hideMissonCheck;
    if (!viewPermissions.missionCheckbox && !viewPermissions.interventionCheckbox){
      hideMissonCheck = true
    } else if (!viewPermissions.missionCheckbox || !viewPermissions.interventionCheckbox){
      hideMissonCheck = true
    } else {
      hideMissonCheck = false;
    }

    let hideAACheck;
    if (!viewPermissions.cvBuilderCheckbox){
      hideAACheck = true
    }

    const bigMenu = () => {
      return (
        <>
          <Grid item>
            <Button
              classes={{
                label: classes.buttonlabel,
              }}
              size="small"
              component={Link}
              disabled={showChat}
              color={this.getVariant(ROUTES.JOBS)}
              to={ROUTES.JOBS}
              startIcon={
                this.getVariant(ROUTES.JOBS) === "primary" ? (
                  <JobsSelectedIcon />
                ) : (
                  <JobsIcon />
                )
              }
            >
              {careerpathwayTitle()}
            </Button>
          </Grid>
          {hideMissonCheck && (
            <Grid item>
              <Button
                classes={{
                  label: classes.buttonlabel,
                }}
                size="small"
                component={Link}
                disabled={showChat}
                to={ROUTES.MISSIONS}
                color={this.getVariant(ROUTES.MISSIONS)}
                startIcon={
                  this.getVariant(ROUTES.MISSIONS) === "primary" ? (
                    <MissionsSelectedIcon />
                  ) : (
                    <MissionsIcon />
                  )
                }
              >
                Missions
              </Button>                          
            </Grid>
          )}
          <Grid item>
            <Button
              classes={{
                label: classes.buttonlabel,
              }}
              size="small"
              component={Link}
              disabled={showChat}
              to={ROUTES.PROFILE}
              color={this.getVariant(ROUTES.PROFILE)}
              startIcon={
                this.getVariant(ROUTES.PROFILE) === "primary" ? (
                  <ProfileSelectedIcon />
                ) : (
                  <ProfileIcon />
                )
              }
            >
              Profile
            </Button>
          </Grid>
          {hideAACheck && (
            <Grid item>
              <Button
                classes={{
                  label: classes.buttonlabel,
                }}
                size="small"
                component={Link}
                disabled={showChat}
                to={ROUTES.APPLICATION_ASSISTANT}
                color={this.getVariant(ROUTES.APPLICATION_ASSISTANT)}
                startIcon={
                  <div style={{display: 'flex', width: '20px'}}>
                    {
                  this.getVariant(ROUTES.APPLICATION_ASSISTANT) === "primary" ? (
                    <DescriptionTwoToneIcon sx={{ fontSize: 40 }} style={{color: '#ff7e34'}} />
                  ) : (
                    <DescriptionTwoToneIcon sx={{ fontSize: 40 }}  style={{color: '#B4B8BF'}} />
                  )
                }
                  </div>
                }
              >
                Application Assistant
              </Button>
            </Grid>
          )}
        </>
      )
    }

    const smallMenu =() => {
      return (
        <>
          <Grid item>
            <IconButton
              aria-label="jobs"
              component={Link}
              disabled={showChat}
              to={ROUTES.JOBS}
            >
              {this.getVariant(ROUTES.JOBS) === "primary" ? (
                <JobsSelectedIcon />
              ) : (
                <JobsIcon />
              )}
            </IconButton>
          </Grid>
          {hideMissonCheck && (
            <Grid item>
            <IconButton
              aria-label="jobs"
              component={Link}
              disabled={showChat}
              color={this.getVariant(ROUTES.MISSIONS)}
              to={ROUTES.MISSIONS}
            >
              {this.getVariant(ROUTES.MISSIONS) === "primary" ? (
                <MissionsSelectedIcon />
              ) : (
                <MissionsIcon />
              )}
            </IconButton>
            </Grid>
          )}
          <Grid item>
            <IconButton
              aria-label="jobs"
              component={Link}
              disabled={showChat}
              to={ROUTES.PROFILE}
            >
              {this.getVariant(ROUTES.PROFILE) === "primary" ? (
                <ProfileSelectedIcon />
              ) : (
                <ProfileIcon />
              )}
            </IconButton>
          </Grid>
          {hideAACheck && (
            <Grid item>
              <IconButton
                aria-label="applicationAssistant"
                component={Link}
                disabled={showChat}
                to={ROUTES.APPLICATION_ASSISTANT}
              >
                {
                this.getVariant(ROUTES.APPLICATION_ASSISTANT) === "primary" ? (
                    <DescriptionTwoToneIcon sx={{ fontSize: 40 }} style={{color: '#ff7e34'}} />
                  ) : (
                    <DescriptionTwoToneIcon sx={{ fontSize: 40 }}  style={{color: '#B4B8BF'}} />
                )}
              </IconButton>
            </Grid>
          )}
        </>
      )
    }

    const smallSettings = () => {
      return (
        <>
          <Badge badgeContent={notificationAmount} color="error">
            <IconButton
              className={`{classes.button} profile-page-button ${classes.colorGrey} `}
              classes={{
              label: classes.buttonlabel,
              }}
              style={{color: (!hideNotification) ? "#b4b8bf" : "#E16A23"}}
              onClick={() => {
              let h = (hideNotification) ? false : true;
              setHideNotifcation(h);
              }}
              size="small"
              color={this.getVariant(ROUTES.PROFILE)}
              >
              <NotificationsIcon />
            </IconButton>
          </Badge>
        </>
      )
    }
    return (
      <React.Fragment>
        <NotificationsCenter display={hideNotification} setDisplay={setHideNotifcation} />
        <ElevationScroll {...this.props}>
          <AppBar
            color="default"
            classes={{ colorDefault: classes.appbar }}
          >
            <Toolbar className={classes.toolbar}>
              <div className={classes.logocont}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    {!customNavLogo && <SmLogo className={classes.logo} />}
                    {customNavLogo && (
                      <img
                        src={customNavLogo}
                        alt="Skillsminer"
                        className={classes.logo}
                      />
                    )}
                  </Grid>
                  <Grid item xs style={{margin: -5}}>
                    <Hidden smDown>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                        style={{justifyContent:"space-evenly"}}
                      >
                        <Hidden lgUp>
                          {searchBar ? smallMenu() : bigMenu()}
                        </Hidden>
                        <Hidden mdDown>
                          {bigMenu()}
                        </Hidden>

                      
                      <Grid item style={{display:"flex", alignContent:"center" }}>
                        <Hidden mdDown>
                          <OccupationSearch
                            placeholder={"What Occupation"}
                            disabled={showChat || fetchingJobs || !this.props.location.pathname.includes("jobs")}
                            autoCompleteOccupation={async (value) => {return await this.props.autoCompleteOccupation(value)}}
                            onSelect={this.props.manualOccSearch}
                            addOccupationFilter={this.props.addOccupationFilter}
                            occList={occList}
                            interalRolesOnly={internalMobilityProvider ? true : false}
                            internalMobilityRoles={internalMobilityRoles}
                            
                          />
                        </Hidden>
                        <Hidden lgUp>
                          {searchBar && (
                            <OccupationSearch
                              placeholder={"What Occupation"}
                              disabled={showChat || fetchingJobs || !this.props.location.pathname.includes("jobs")}
                              autoCompleteOccupation={async (value) => {return await this.props.autoCompleteOccupation(value)}}
                              onSelect={this.props.manualOccSearch}
                              addOccupationFilter={this.props.addOccupationFilter}
                              occList={occList}
                              interalRolesOnly={internalMobilityProvider ? true : false}
                              internalMobilityRoles={internalMobilityRoles}
                            />
                          )}
                        </Hidden>
                        <Hidden lgUp>
                          <IconButton onClick={() => setSearchBar(!searchBar)}>
                            {searchBar ? (<CloseIcon/>) : (<SearchIcon/>)}
                          </IconButton>
                        </Hidden>
                      </Grid>
                      </Grid>
                    </Hidden>



                    <Hidden mdUp>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                        style={{justifyContent:"space-evenly"}}
                      >
                        <Hidden xsDown>
                          {smallMenu()}
                        </Hidden>
                        <Hidden smUp>
                          {!searchBar && smallMenu()}
                        </Hidden>
                        
                        <Grid item style={{display:"flex", alignContent:"center" }}>
                          {searchBar && (
                            <OccupationSearch
                              placeholder={"What Occupation"}
                              disabled={showChat || fetchingJobs || !this.props.location.pathname.includes("jobs")}
                              autoCompleteOccupation={async (value) => {return await this.props.autoCompleteOccupation(value)}}
                              onSelect={this.props.manualOccSearch}
                              addOccupationFilter={this.props.addOccupationFilter}
                              occList={occList}
                              interalRolesOnly={internalMobilityProvider ? true : false}
                              internalMobilityRoles={internalMobilityRoles}
                            />
                          )}
                          <IconButton onClick={() => setSearchBar(!searchBar)}>
                            {searchBar ? (<CloseIcon/>) : (<SearchIcon/>)}
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <Hidden xsDown>
                            {smallSettings()}
                          </Hidden>
                          <Hidden smUp>
                            {!searchBar && smallSettings()}
                          </Hidden>
                        </Grid>
                      </Grid>
                    </Hidden>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.profileMenu}>
                <Hidden mdUp>
                  <IconButton
                      style={{padding: 0}}
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={this.handleMenu}
                      color="inherit"
                    >
                      <MoreVert />
                    </IconButton>
                </Hidden>

                {fullName !== "" && (
                  <>
                    <Hidden smDown>
                    <Badge badgeContent={notificationAmount} color="error"   anchorOrigin={{vertical: 'top',horizontal: 'left',}}>
                    <IconButton
                        className={` profile-page-button ${classes.colorGrey} `}
                        classes={{
                          label: classes.buttonlabel,
                        }}
                        size="small"
                        onClick={() => {
                          let h = (hideNotification) ? false : true;
                          setHideNotifcation(h);
                        }}
                        style={{color: (!hideNotification) ? "#b4b8bf" : "#E16A23"}}
                        color={this.getVariant(ROUTES.PROFILE)}
                    > <NotificationsIcon /> </IconButton>
                    </Badge>
                      <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={this.handleMenu}
                        color="inherit"
                        size="small"
                        className={classes.avatarbutton}
                      >
                        <Avatar
                          alt={fullName}
                          className={classes.small}
                          src={profileImageURL}
                        >
                          {firstName.charAt(0)}
                        </Avatar>
                      </IconButton>
                    </Hidden>{" "}
                    <Hidden mdDown>
                      <Typography
                        variant="body1"
                        component="h6"
                        onClick={this.handleMenu}
                        className={`${classes.name} settings-button`}
                      >
                        {fullName}
                      </Typography>
                      <ExpandIcon
                        className={classes.expand}
                        onClick={this.handleMenu}
                      />
                    </Hidden>
                  </>
                )}

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={this.handleClose}
                >
                  {/*    <MenuItem
                    onClick={this.handleClose}
                    component={Link}
                    to={ROUTES.CHAT}
                    className={classes.menuitem}
                  >
                    Chat
                  </MenuItem>
                  <Divider />*/}

                  <MenuItem disabled>{fullName}</MenuItem>
                  <MenuItem disabled>
                    {"Coins: " + coins}
                    <SmallCoin/>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={async () => {
                      const portal = await triggerCustomerPortal()
                      if(portal && portal.url){
                        window.location.replace(portal.url)
                      }
                    }}
                    className={classes.menuitem}
                  >
                    Manage Billing
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    component={Link}
                    to={ROUTES.PROFILE_SETTINGS}
                    onClick={this.handleClose}
                    className={classes.menuitem}
                    disabled={showChat}
                  >
                    Settings
                  </MenuItem>
                  <Divider />

                  {(orgName && orgInfo) && 
                  <MenuItem
                    component={Link}
                    to={ROUTES.ORGANISATION_PROFILE}
                    onClick={this.handleClose}
                    className={classes.menuitem}
                    disabled={showChat}
                  >
                    {orgName}
                  </MenuItem>
                  }
                  {(orgName && orgInfo) && 
                  <Divider />
                  }
                  <MenuItem
                    className={`${classes.menuitem} privacy-menu-item`}
                    disabled={showChat}
                  >
                    <a id="privacy-policy-link" href="https://www.skillsminer.ai/privacy-policy/" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                      Privacy Policy
                    </a>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    component={Link}
                    to={ROUTES.FAQ}
                    onClick={this.handleClose}
                    className={`${classes.menuitem} faq-menu-item`}
                    disabled={showChat}
                  >
                    FAQs
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      this.handleClose();
                      this.props.logout(null, this.props.history);
                    }}
                    className={classes.menuitem}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ logout, setNotificationAmount, autoCompleteOccupation, manualOccSearch, triggerCustomerPortal, addOccupationFilter }, dispatch),
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Navigation)));
