import React, { Component, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Switch from '@material-ui/core/Switch';
import { ReactComponent as SmLogo } from "../Common/Logo/smLogo.svg";
import { ReactComponent as Mountains } from "../Auth/Icons/mountains.svg";
import TextField from "@material-ui/core/TextField";
import { submitDemographics, getinternalMobilityDemographics, submitDemographicsInternalMobility, reOpenDemographics } from "../../actions/user_actions";
import {MenuItem, Select, OutlinedInput, Divider} from '@material-ui/core';
import green from "@material-ui/core/colors/green";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import ReactSelect from "react-select";
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import moment from "moment";
import config from "../../config";
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 12000,
    position: "absolute",
    backgroundColor: "#F8F9FA",
    width: "100%",
    height: "100%",
    overflow: 'scroll' 
  },
  backdrop: {
    zIndex: 8000,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    //marginTop: theme.spacing(1)
  },
  formhold: {
    marginLeft: theme.spacing(4),
    marginRight: 27,
    marginTop: theme.spacing(4),
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2),
    },
  },
  grid: {
    minHeight: "100vh",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: 720,
    maxWidth: 720,
    [theme.breakpoints.down(740)]: {
      maxWidth: 600,
      minWidth: 600,
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "100vh",
      minWidth: 100,
    },
  },
  logingrid: {
    minHeight: 450,
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
      marginBottom: 14,
    },
  },
  textField: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: 14,
  },
  progress: {},
  progressCont: {
    height: 3,
    [theme.breakpoints.down("md")]: {
      height: 2,
    },
    [theme.breakpoints.up("md")]: {
      height: 3,
    },
  },
  marginLabel: {
    margin: 20,
    marginBottom: 0,
    marginTop: 10,
  },
  snack: {
    marginTop: 10,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  success: {
    backgroundColor: green[600],
  },
  version: {
    fontSize: 9,
    color: "#bbb",
    textAlign: "right",
    marginTop: 3,
    marginRight: theme.spacing(2),
    marginBottom: 5,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: "relative",
  },
  logo: {
    width: 167,
    height: 40,
    marginBottom: 27,
    marginTop: 20,
  },
  mountainsgrid: {
    backgroundColor: "#FDEFE7",
    //height: "100%"
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    overflow: "hidden",
    display: "flex",
  },
  mountains: {
    width: "105%",
    marginLeft: -1,
    alignSelf: "flex-end",
    marginBottom: -15,
    [theme.breakpoints.down("xs")]: {
      width: "160%",
      height: "160%",
      marginBottom: -80,
      alignSelf: "flex-start",
    },
  },
  title: {
    fontSize: 32,
    [theme.breakpoints.down("sm")]: {
      fontSize: 25,
    },

    [theme.breakpoints.down("xs")]: {},
  },
  titlesubheading:{
    textAlign: "center",
    color: "#5A6375",
    fontSize: 26,
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    color: "#202A3E",
    fontSize: 14,
    marginTop: 3,
    marginBottom: theme.spacing(2),
  },
  formtitle: {
    color: "#202A3E",
    fontSize: 16,
    marginBottom: 4,
    marginTop: 18,
  },
  privacy: {
    color: "#5A6375",
    fontSize: 12,
    textAlign: "left",
    marginTop: 16,
  },
  tfroot: {
    fontSize: 14,
    backgroundColor: "#F5F6F7",
  },
  tfoutline: {
    border: "none",
  },
  signup: {
    color: "#5A6375",
    fontSize: 14,
    marginTop: 14,
    textAlign: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  poweredBylogo:{
    height: 40, 
    width: 165 
  },
  orgLogo:{
    maxHeight: 160,
  },
  textFieldSmall: {
    height:30,
    fontSize:12,
    backgroundColor:"white"
  },
  filterItem: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '14px',
    opacity: '.7',
    fontWeight: '500',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap'
  }
});

const LineManagerSelector = ({availableLineManagers=[], newLineManager, classes, onChange, isVerified}) => {
  const [currentLineManager, setCurrentLineManager] = useState(false)
  const [createNew, setCreateNew] = useState(false)
  const [createNewPlaceHolder, setCreateNewPlaceHolder] = useState(false)
  const options = availableLineManagers.filter((item) => item.userID === currentLineManager || !item.softDisabled).map((item) => {return{value:item.userID, label: item.name + " (" + item.email + ")"}})
  const currentLineManagerObject = newLineManager ? options.find((item) => item.value === newLineManager) : false

  useEffect(() => {
    async function checkIfCreatingNew() {
      if(availableLineManagers && newLineManager){
        if(isVerified === "n"){
          setCreateNew(true)
          setCreateNewPlaceHolder(newLineManager)
        }
        setCurrentLineManager(newLineManager)
      }
    }
    checkIfCreatingNew()
  }, [availableLineManagers]);
  
  return(
    <Paper elevation={1} style={{paddingInline:"15px", paddingBlock:"5px", marginBottom:"10px", backgroundColor:"#FDEFE7"}}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid style={{width:"100%"}}>
          <Grid style={{width:"100%"}}
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid>
              <Typography>
                {createNew ? "Enter Line Manager name or email:" : "Please Select:"}
              </Typography>
            </Grid>
            <Grid>
              <Typography>
                <Checkbox
                  checked={createNew}
                  onChange={() => {
                    onChange(false)
                    setCreateNew(!createNew)
                  }}
                /> 
                Create New 
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {createNew ? (
          <>
            <Grid style={{width:"100%"}} className={classes.filterItem}>
              <TextField
                placeholder={createNewPlaceHolder ? createNewPlaceHolder : "Input Line Manager"}
                InputProps={{
                  className: classes.textFieldSmall,
                }}
                onChange={(event) => {
                  onChange(event.target.value)
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid>
              <Typography style={{marginBlock: "5px", fontSize:"12px"}}>
                Newly created Line Managers will be manually invited by HR Managers
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid style={{width:"100%"}}>
              <ReactSelect
                placeholder={currentLineManagerObject ? currentLineManagerObject.label : "Please Select"}
                value={options.find(function(option) {
                  return option.ID === newLineManager;
                })}
                onChange={(event) => {
                  onChange(event.value)
                }}
                options={options}
              />
            </Grid>
            <Grid>
              <Typography style={{marginBlock: "10px", fontSize:"12px"}}>
                If your current Line Manager does not appear in the selection, click "Create New" to manually enter their name and/or email.
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
      {/* <Divider style={{width:"100%", marginBlock: "10px"}}/> */}
    </Paper>
  )
}

const DepartmentSelector = ({availableDepartments=[], newDepartment, classes, onChange, isVerified}) => {
  const [currentDepartment, setCurrentDepartment] = useState(false)
  const [createNew, setCreateNew] = useState(false)
  const [createNewPlaceHolder, setCreateNewPlaceHolder] = useState(false)
  const options = availableDepartments.filter((item) => item.ID === currentDepartment || !item.disabled).map((item) => {return{value:item.ID, label: item.departmentName}})
  const currentDepartmentObject = newDepartment ? options.find((item) => item.value === newDepartment) : false

  useEffect(() => {
    async function checkIfCreatingNew() {
      if(availableDepartments && newDepartment){
        if(isVerified === "n"){
          setCreateNew(true)
          setCreateNewPlaceHolder(newDepartment)
        }
        setCurrentDepartment(newDepartment)
      }
    }
    checkIfCreatingNew()
  }, [availableDepartments]);

  return(
    <Paper elevation={1} style={{paddingInline:"15px", paddingBlock:"5px", marginBottom:"10px", backgroundColor:"#FDEFE7"}}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid style={{width:"100%"}}>
          <Grid style={{width:"100%"}}
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid>
              <Typography>
                {createNew ? "Enter Department Name:" : "Please Select:"}
              </Typography>
            </Grid>
            <Grid>
              <Typography>
                <Checkbox
                  checked={createNew}
                  onChange={() => {
                    onChange(false)
                    setCreateNew(!createNew)
                  }}
                /> 
                Create New 
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {createNew ? (
          <>
            <Grid style={{width:"100%"}} className={classes.filterItem}>
              <TextField
                placeholder={createNewPlaceHolder ? createNewPlaceHolder : "Input Department"}
                InputProps={{
                  className: classes.textFieldSmall,
                }}
                onChange={(event) => {
                  onChange(event.target.value)
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid>
              <Typography style={{marginBlock: "5px", fontSize:"12px"}}>
                Newly created departments require verification by HR Managers
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid style={{width:"100%"}}>
              <ReactSelect
                placeholder={currentDepartmentObject ? currentDepartmentObject.label : "Please Select"}
                value={options.find(function(option) {
                  return option.ID === newDepartment;
                })}
                onChange={(event) => {
                  onChange(event.value)
                }}
                options={options}
              />
            </Grid>
            <Grid>
              <Typography style={{marginBlock: "10px", fontSize:"12px"}}>
                If your department does not appear in the selection, click "Create New" to manually enter the department name
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
      {/* <Divider style={{width:"100%", marginBlock: "10px"}}/> */}
    </Paper>
  )
}

const RoleSelector = ({availableRoles=[], newRole, classes, onChange}) => {
  const [currentRole, setCurrentRole] = useState(false)
  const [createNew, setCreateNew] = useState(false)
  const options = availableRoles.filter((item) => item.ID === newRole || !item.disabled).map((item) => {return{value:item.ID, label: item.roleName}})
  const currentRoleObject = newRole ? options.find((item) => item.value === newRole) : false

  useEffect(() => {
    async function checkIfCreatingNew() {
      if(availableRoles && newRole){
        setCurrentRole(newRole)
      }
    }
    checkIfCreatingNew()
  }, [availableRoles]);

  return(
    <Paper elevation={1} style={{paddingInline:"15px", paddingBlock:"5px", marginBottom:"10px", backgroundColor:"#FDEFE7"}}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="baseline"
      >
        <Grid style={{width:"100%"}}>
          <Grid style={{width:"100%"}}
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid>
              <Typography>
                {createNew ? "Enter Role Name:" : "Please Select:"}
              </Typography>
            </Grid>
            <Grid>
              <Typography>
                <Checkbox 
                  checked={createNew}
                  onChange={() => {
                    onChange(false)
                    setCreateNew(!createNew)
                  }}
                /> 
                Create New 
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {createNew ? (
          <>
            <Grid style={{width:"100%"}} className={classes.filterItem}>
              <TextField
                placeholder="Input Role"
                InputProps={{
                  className: classes.textFieldSmall,
                }}
                onChange={(event) => {
                  onChange(event.target.value)
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid>
              <Typography style={{marginBlock: "5px", fontSize:"12px"}}>
                Newly created roles require verification by HR Managers. Until verified you cannot progress to the next stage. Please ensure your current role isn't already listed
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid style={{width:"100%"}}>
              <ReactSelect
                placeholder={currentRoleObject ? currentRoleObject.label : "Please Select"}
                value={options.find(function(option) {
                  return option.ID === newRole;
                })}
                onChange={(event) => {
                  onChange(event.value)}
                }
                options={options}
              />
            </Grid>
            <Grid>
              <Typography style={{marginBlock: "5px", fontSize:"12px"}}>
                If your role does not appear in the selection, click "Create New" to manually enter the role title
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  )
}

const FreeTextQuestion = ({question, classes, onChange}) => {
  return(
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="baseline"
      style={{margin:5}}
    >
      <Grid>
        <Typography>
          {question.question}
        </Typography>
      </Grid>
      <Grid style={{width:"100%"}}>
        <TextField
          style={{width:"100%"}}
          InputProps={{
            className: classes.textFieldSmall,
          }}
          value={question.answer}
          onChange={(event) => {onChange(event.target.value)}}
          variant="outlined"
        />
      </Grid>
    </Grid>
  )
}

const SingleChoiceQuestion = ({question, classes, onChange}) => {
  return(
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="baseline"
      style={{margin:5}}
    >
      <Grid>
        <Typography>
          {question.question}
        </Typography>
      </Grid>
      <Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="baseline"
        >
          {question.choices.map((choice) =>
          <Grid style={{margin:5}}>
            <Button
              variant="contained"
              className={classes.button}
              color={choice === question.answer ? "primary" : ""}
              fullWidth
              onClick={() => onChange(choice)}
            >
              {choice}
            </Button>
          </Grid>
          )}

        </Grid>
      </Grid>
    </Grid>
  )
}

const MultiChoiceQuestion = ({question, classes, onChange}) => {
  return(
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="baseline"
      style={{margin:5}}
    >
      <Grid>
        <Typography>
          {question.question}
        </Typography>
      </Grid>
      <Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="baseline"
        >
          {question.choices.map((choice) =>
          <Grid style={{margin:5}}>
            <Button
              variant="contained"
              className={classes.button}
              color={question.answer ? question.answer.includes(choice) ? "primary" : "" : ""}
              fullWidth
              onClick={() => onChange(choice)}
            >
              {choice}
            </Button>
          </Grid>
          )}

        </Grid>
      </Grid>
    </Grid>
  )
}

class Demographics extends Component {

  async componentDidMount() {
    if(this.props.user.internalMobilityProvider && !this.props.user.internalMobilityQuestions){
      await this.props.getinternalMobilityDemographics();
    }
  }

  render() {
    const {
      classes,
      user: { 
        showDemographics,
        showDemographicsEdit, 
        showHello,
        allow_reverse_mine = true,
        candidateStatus = null,
        trainingOpportunities = true,
        jobOpportunities = true,
        dateOfBirth = null,
        gender,
        rightTooWork,
        hasNoticePeriod,
        providerDetails,
        organisation,
        internalMobilityProvider,
        internalMobilityQuestions,
        internalMobilityRoles,
        internalMobilityRole,
        internalMobilityDepartments,
        internalMobilityDepartment,
        internalMobilityDepartmentVerified,
        internalMobilityLineManagers,
        internalMobilityLineManager,
        internalMobilityLineManagerVerified,
        internalMobilityResponses,
        holdUser,
        orgDetails: {
          name = ""
        }
      },
      reOpenDemographics
    } = this.props;
    

    const [lookingForWork, setLookingForWork] = React.useState(allow_reverse_mine);
    const [situation, setSituation] = React.useState(candidateStatus);
    const lookingOptions = [{value:"Unemployed", label:"Unemployed"}, {value:"Employed but open to new opportunities", label:"Employed but open to new opportunities"}, {value:"Student", label:"Student"}, {value:"Looking to re-enter the labour market", label:"Looking to re-enter the labour market"}]
    const notLookingOptions = [{value:"Employed and satisfied", label:"Employed and satisfied"},{value:"Student", label:"Student"},{value:"Looking after family / home", label:"Looking after family / home"},{value:"Sick", label:"Sick"}, {value:"Retired", label:"Retired"}, {value:"Other", label:"Other"}]
    const genderOptions = [{value:"male", label:"Male"}, {value:"female", label:"Female"}, {value:"non binary", label:"Non-binary"}, {value:"transgender", label:"Transgender"}, {value:"prefer not to say", label:"Prefer not to say"}, {value:"other", label:"Other"}]
    const [newTrainingOpportunities, setTrainingOpportunities] = React.useState(trainingOpportunities);
    const [newJobOpportunities, setJobOpportunities] = React.useState(jobOpportunities);
    const [newFutureJobs, setFutureJobs] = React.useState(jobOpportunities);
    const [newDateOfBirth, setDateOfBirth] = React.useState(dateOfBirth ? moment.unix(dateOfBirth / 1000).format("YYYY-MM-DD") : null);
    const [newGender, setGender] = React.useState(gender);
    const [newRightTooWork, setRightTooWork] = React.useState(rightTooWork);
    const [newHasNoticePeriod, setHasNoticePeriod] = React.useState(hasNoticePeriod);
    const [showHelp1, setShowHelp1] = React.useState(false);
    const [showHelp2, setShowHelp2] = React.useState(false);

    const [updatedInternalMobilityQuestions, setUpdatedInternalMobilityQuestions] = React.useState(internalMobilityResponses ? internalMobilityResponses.questions : false);
    const [updateCheck, setUpdateCheck] = React.useState(false);
    const [selectedRole, setSelectedRole] = useState(internalMobilityRole ? internalMobilityRole : "")
    const [selectedDepartment, setSelectedDepartment] = useState(internalMobilityDepartment ? internalMobilityDepartment : "")
    const [selectedLineManager, setSelectedLineManager] = useState(internalMobilityLineManager ? internalMobilityLineManager : "")

    useEffect(() => {
      async function checkIMQs() {
        if(internalMobilityProvider && internalMobilityQuestions){
          if(!updatedInternalMobilityQuestions){
            setUpdatedInternalMobilityQuestions(internalMobilityQuestions)
          }
          else if(updatedInternalMobilityQuestions && !updateCheck){
            setUpdateCheck(true);
            let newQuestions = []

            internalMobilityQuestions.forEach((question) => {
              if(question && question.id){
                if(!updatedInternalMobilityQuestions.find((item) => item.id && item.id === question.id)){
                  newQuestions.push(question);
                }
              }
            })
            if(newQuestions.length > 0){
              setUpdatedInternalMobilityQuestions([...updatedInternalMobilityQuestions, ...newQuestions]);
              reOpenDemographics();
            }
          }
        }
      }
      checkIMQs()
    }, [internalMobilityProvider, internalMobilityQuestions, internalMobilityResponses]);

    let viewPermissions = (providerDetails.viewPermissions) ? providerDetails.viewPermissions : {};
    if(holdUser){
      return (
        <Backdrop
          open={showDemographics}
          transitionDuration={{
            appear: 0,
            exit: 500,
            enter: 0,
          }}
          classes={{
            root: classes.backdrop,
          }}
        >
          <div className={classes.root}>
          <Grid
            container
            spacing={0}
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.grid}
          >
            <Hidden xsDown>
              <Grid item>
                {organisation !== "skillzminer" ? (
                  <img
                    src={
                      config.STORAGE_CLOUDFRONT_URL +"/logos/"+ organisation +"_appnav_logo.png"
                    }
                    className={classes.orgLogo}
                  />
                ) : (
                  <SmLogo className={classes.logo} />
                )}
              </Grid>
            </Hidden>
            <Grid item>
              <Paper className={classes.main}>
                <Grid
                  container
                  spacing={0}
                  direction="row-reverse"
                  justify="center"
                  alignItems="stretch"
                  className={classes.logingrid}
                >
                  <Grid item xs={12} sm={5} className={classes.mountainsgrid}>
                    <Mountains className={classes.mountains} />
                  </Grid>
                  <Grid item xs={12} sm={7} className={classes.fieldholdergrid}>
                    <div className={classes.formhold}>
                      <Typography variant="h4" className={classes.title}>
                        Thank you for submitting your details
                      </Typography>
                      <Typography variant="h6" className={classes.subtitle}>
                        You will receive an email when your role has been assigned
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
              {organisation !== "skillzminer" && (
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item style={{marginBottom:5, marginTop:0}}>
                      <Typography variant="body2" className={classes.signup}>
                        Powered By
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img
                        src={
                          config.STORAGE_CLOUDFRONT_URL +"/logos/"+ organisation +"_powered_by_logo.png"
                        }
                        className={classes.poweredBylogo}
                      />
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </Grid>
          </div>
        </Backdrop>

      )
    }
    else if (showDemographics && (!internalMobilityProvider && !viewPermissions.additionalPersonalInfoCheckbox) || (internalMobilityProvider && ((internalMobilityQuestions && internalMobilityQuestions.length > 0) || internalMobilityDepartments || internalMobilityRoles || internalMobilityLineManagers))){
      function updateIMQs(index, newValue, muiltiple){
        // console.log(index)
        setUpdatedInternalMobilityQuestions((prevState) =>
          prevState.map((_value, _index) => {
            if(index === _index){
              if(muiltiple){
                if(!_value.answer){
                  _value.answer = []
                }
                if(_value.answer.includes(newValue)){
                  _value.answer = _value.answer.filter((arrvalues) => arrvalues !== newValue)
                }
                else{
                  _value.answer = [..._value.answer, newValue]
                }
              }
              else{
                _value.answer = newValue
              }
            }
            return _value
          })
        )
      }

      // console.log(updatedInternalMobilityQuestions)
      const submitDisabled = updatedInternalMobilityQuestions ? updatedInternalMobilityQuestions.find((item) => !item.answer || item.answer === "" || item.answer.length === 0) || ["", false].includes(selectedRole) || ["", false].includes(selectedDepartment) || ["", false].includes(selectedLineManager) : false

      return (
        <Backdrop
          open={showDemographics}
          transitionDuration={{
            appear: 0,
            exit: 500,
            enter: 0,
          }}
          classes={{
            root: classes.backdrop,
          }}
        >
          <div className={classes.root}>
          <Grid
            container
            spacing={0}
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.grid}
          >
            <Hidden xsDown>
              <Grid item>
                {organisation !== "skillzminer" ? (
                  <img
                    src={
                      config.STORAGE_CLOUDFRONT_URL +"/logos/"+ organisation +"_appnav_logo.png"
                    }
                    className={classes.orgLogo}
                  />
                ) : (
                  <SmLogo className={classes.logo} />
                )}
              </Grid>
            </Hidden>
            <Grid item>
              <Paper className={classes.main}>
                <Grid
                  container
                  spacing={0}
                  direction="row-reverse"
                  justify="center"
                  alignItems="stretch"
                  className={classes.logingrid}
                >
                  <Grid item xs={12} sm={5} className={classes.mountainsgrid}>
                    <Mountains className={classes.mountains} />
                  </Grid>
                  <Grid item xs={12} sm={7} className={classes.fieldholdergrid}>
                    <div className={classes.formhold}>
                    { internalMobilityProvider ? (
                      <>
                        <Typography variant="h4" className={classes.title}>
                          {name + " Employee Questions"}
                        </Typography>
                        <Typography variant="h6" className={classes.subtitle}>
                          {showDemographicsEdit ? "Please update the following information if your current situation has changed." : "Before we begin, please complete the following form.  You are able to update this information later via the settings page."}
                        </Typography>
                      </>
                      ) : ( showHello ? (
                      <>
                        <Typography variant="h4" className={classes.title}>
                          One more thing
                        </Typography>
                        <Typography variant="h6" className={classes.subtitle}>
                          Before we begin, please complete the following form.  You are able to update this information later via the settings page.  
                        </Typography>
                      </>
                      ):(
                      <>
                        <Typography variant="h4" className={classes.title}>
                          Update information
                        </Typography>
                        <Typography variant="h6" className={classes.subtitle}>
                          Please update the following information if your current situation has changed.
                        </Typography>
                      </>
                      )
                      )
                    }
                      <form className={classes.form}>
                        {internalMobilityProvider ? (
                        <>
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="baseline"
                        >
                          {!showDemographicsEdit && (
                            <>
                              <RoleSelector availableRoles={internalMobilityRoles} classes={classes} onChange={(value) => setSelectedRole(value)} newRole={selectedRole}/>
                            </>
                          )}
                          <DepartmentSelector availableDepartments={internalMobilityDepartments} classes={classes} onChange={(value) => setSelectedDepartment(value)} newDepartment={selectedDepartment} isVerified={internalMobilityDepartmentVerified}/>
                          <LineManagerSelector availableLineManagers={internalMobilityLineManagers} classes={classes} onChange={(value) => setSelectedLineManager(value)} newLineManager={selectedLineManager} isVerified={internalMobilityLineManagerVerified}/>
                          {updatedInternalMobilityQuestions && updatedInternalMobilityQuestions.map((question, index) => {
                            if(question.questionType === "freetext"){
                              return (<FreeTextQuestion classes={classes} question={question} onChange={(value) => updateIMQs(index, value)}/>)
                            }
                            if(question.questionType === "hasChoices"){
                              return (<SingleChoiceQuestion classes={classes} question={question} onChange={(value) => updateIMQs(index, value)}/>)
                            }
                            if(question.questionType === "multipleChoices"){
                              return (<MultiChoiceQuestion classes={classes} question={question} onChange={(value) => updateIMQs(index, value, true)}/>)
                            }
                          }
                          )
                          }
                          <div className={classes.wrapper}>
                              <Button
                                variant="contained"
                                className={classes.button}
                                color="primary"
                                fullWidth
                                disabled={submitDisabled}
                                onClick={async () => await this.props.submitDemographicsInternalMobility({
                                  questions: updatedInternalMobilityQuestions, 
                                  department: selectedDepartment, 
                                  role: selectedRole, 
                                  lineManager: selectedLineManager
                                })}
                              >
                                Submit
                            </Button>
                          </div>
                        </Grid>

                        </>) : 
                        (
                          <>
                          <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="baseline"
                          >
                            <Grid>
                              <Typography
                                variant="body1"
                                className={classes.formtitle}
                              >
                                Not looking for work
                              </Typography>
                            </Grid>
                            <Grid>
                              <Switch
                              checked={lookingForWork}
                              onChange={() => {
                                setLookingForWork(!lookingForWork)
                                setSituation(false)
                                setTrainingOpportunities(true)
                                setJobOpportunities(true)
                                setFutureJobs(true)
                              }}
                              color="primary"
                              name="checkedB"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              />
                            </Grid>
                            <Grid>
                              <Typography
                                variant="body1"
                                className={classes.formtitle}
                              >
                                Looking for work
                              </Typography>
                            </Grid>
                          </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="baseline">
                              <Grid>
                                <Typography
                                  variant="body1"
                                  className={classes.formtitle}
                                >
                                  What is your current situation?
                                </Typography>
                              </Grid>
                              <Grid>
                                <IconButton id="help" onClick={()=> setShowHelp1(!showHelp1)}>
                                  <HelpIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                            <Grid>
                            {showHelp1 && (
                              <Typography
                                variant="body1"
                                className={classes.subtitle}
                                styles={{margin:5}}
                              >
                                These responses are optional but it will help employers and recruiters better understand your situation.  
                              </Typography>
                            )}
                          </Grid>
                            <ReactSelect
                              placeholder="Select Situation"
                              value={lookingForWork ? lookingOptions.filter(function(option) {
                                return option.label === situation;
                              }) : notLookingOptions.filter(function(option) {
                                return option.label === situation;
                              })}
                              onChange={(event) => {
                                setSituation(event.label)}}
                              options={lookingForWork ? lookingOptions : notLookingOptions}
                            />
                            <Typography
                                variant="body1"
                                className={classes.formtitle}
                              >
                                I'm open to:
                            </Typography>
                            <FormControl component="fieldset" className={classes.formControl} style={{ marginLeft: 15 }}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox checked={newTrainingOpportunities} onChange={()=>{setTrainingOpportunities(!newTrainingOpportunities)}} name="training" />}
                                label="Training opportunities"
                              />
                              {lookingForWork ? (
                                <FormControlLabel
                                  control={<Checkbox checked={newJobOpportunities} onChange={()=>{setJobOpportunities(!newJobOpportunities)}} name="jobs" />}
                                  label="All job opportunities"
                                />
                              ):(
                              <FormControlLabel
                                control={<Checkbox checked={newFutureJobs} onChange={()=>{setFutureJobs(!newFutureJobs)}} name="futureJobs" />}
                                label="Eventually looking for work again"
                              />
                              )
                            }
                            </FormGroup>
                          </FormControl>
                          <Grid container direction="row" justify="flex-start" alignItems="baseline">
                            <Grid>
                              <Typography
                                variant="body1"
                                className={classes.formtitle}
                              >
                                Additional information
                              </Typography>
                            </Grid>
                            <Grid>
                              <IconButton id="help" onClick={()=> setShowHelp2(!showHelp2)}>
                                <HelpIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                          <Grid>
                            {showHelp2 && (
                              <Typography
                                variant="body1"
                                className={classes.subtitle}
                                styles={{margin:5}}
                              >
                                We don’t discriminate based on age and gender so employers and recruiters will never see this information.  We collect it so that if a training programme or opportunity arises that is limited by these criteria you will be highlighted.  However, these are optional fields so if you don’t feel comfortable inputting this data simply ignore them.  
                              </Typography>
                            )}
                          </Grid>
                          <Grid container style={{ marginLeft: 15 }}>
                            <Grid container direction="row" justify="flex-start" alignItems="baseline">
                              <Grid xs={2}>
                                <Typography variant="body1" className={classes.subtitle}>
                                  Date of Birth
                                </Typography>
                              </Grid>
                              <Grid xs={4}>
                              <TextField
                                id="date"
                                type="date"
                                value={newDateOfBirth}
                                className={classes.textField}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={(event) => {setDateOfBirth(event.target.value)}}
                              />
                              </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="baseline" style={{ marginTop: 15 }}>
                              <Grid xs={2}>
                                <Typography variant="body1" className={classes.subtitle}>
                                  Gender
                                </Typography>
                              </Grid>
                              <Grid xs={6}>
                                <ReactSelect
                                  placeholder="Select Gender"
                                  value={genderOptions.filter(function(option) {
                                    return option.label === newGender;
                                  })}
                                  onChange={(event) => {
                                    setGender(event.label)}}
                                  options={genderOptions}
                                />
                              </Grid>
                            </Grid>                       
                          </Grid>
                          <Typography variant="body1" className={classes.formtitle}>
                            Do you have the right to work in the UK?
                          </Typography>
                          <RadioGroup name="rightTooWork" value={newRightTooWork} onChange={(event) => {setRightTooWork(event.target.value)}} style={{ marginLeft: 15 }}>
                          {
                            ["Yes", "No"].map(item => (
                              <>
                                <FormControlLabel value={item} control={<Radio />} label={item} />
                              </>
                            ))
                          }
                          </RadioGroup>
                          {situation && situation.includes("Employed")  && (
                            <>
                              <Typography variant="body1" className={classes.formtitle}>
                                Do you have a notice period?
                              </Typography>
                              <RadioGroup name="hasNoticePeriod" value={newHasNoticePeriod} onChange={(event) => {setHasNoticePeriod(event.target.value)}} style={{ marginLeft: 15 }}>
                              {
                                ["Yes", "No"].map(item => (
                                  <>
                                    <FormControlLabel value={item} control={<Radio />} label={item} />
                                  </>
                                ))
                              }
                              </RadioGroup>
                            </>
                          )}
                          <div className={classes.wrapper}>
                            <Button
                              variant="contained"
                              className={classes.button}
                              color="primary"
                              fullWidth
                              onClick={async () => {
                                await this.props.submitDemographics({
                                  lookingForWork: lookingForWork,
                                  situation: situation,
                                  trainingOpportunities: newTrainingOpportunities,
                                  jobOpportunities: lookingForWork ? newJobOpportunities : newFutureJobs,
                                  dateOfBirth : moment(newDateOfBirth).valueOf(),
                                  gender: newGender,
                                  rightTooWork : newRightTooWork,
                                  hasNoticePeriod: newHasNoticePeriod
                                })
                              }
                            }
                            >
                              Submit
                            </Button>
                          </div>
                          </>                     
                          )}
                        </form>
                      </div>
                  </Grid>
                </Grid>
              </Paper>
              {organisation !== "skillzminer" && (
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item style={{marginBottom:5, marginTop:0}}>
                      <Typography variant="body2" className={classes.signup}>
                        Powered By
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img
                        src={
                          config.STORAGE_CLOUDFRONT_URL +"/logos/"+ organisation +"_powered_by_logo.png"
                        }
                        className={classes.poweredBylogo}
                      />
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </Grid>
          </div>
        </Backdrop>
      );
    }
    return (<></>)
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ submitDemographics,getinternalMobilityDemographics, submitDemographicsInternalMobility, reOpenDemographics }, dispatch),
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Demographics)));
