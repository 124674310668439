export const DEFAULT = "/";
export const HELLO = "/hello";
export const LOGIN = "/login";
export const FORGOT = "/forgot";
export const SIGNUP = "/signup";
export const APP = "/app";
export const UNSUB = "/unsub";
export const APPLICATION = "/application";

export const PROFILE = "/app/profile";
export const PROFILE_SETTINGS = "/app/profile/settings";
export const ORGANISATION_PROFILE = "/app/profile/org";
export const CHAT = "/app/chat";
export const JOBS = "/app/jobs";
export const JOB_DETAILS = "/app/jobs/details";
export const RESOURCE_DETAILS = "/app/resource/details";
export const MISSIONS = "/app/missions";
export const PRIVACY = "/app/privacy";
export const FAQ = "/app/faq";
export const APPLICATION_ASSISTANT = "/app/applicationAssistant";
