import { useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

export const RestoreScroll = withRouter(({ location }) => {
  const prevLocation = useRef();

  useEffect(() => {
    if (prevLocation.current !== location.pathname) {
      window.scrollTo(0, 0);
      prevLocation.current = location.pathname;
    }
  }, [location]);
  return null;
});
