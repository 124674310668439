/*eslint-disable */
import React, { useState } from 'react';
/*eslint-enable */
import { logout } from "../../actions/auth_actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

function AutoLogout(props){

    const [warnTimeout, setWarnTimeout] = useState(0);
    let logoutcb;

    if (warnTimeout === 0){
        let mouseEvents = [
            // "load",
            "mousemove",
            "mousedown",
            "click",
            "scroll",
            "keypress"
        ];


        let logoutCallback = () => {
            let dispatch = logout(null, ["/login"], "inactivity");
            dispatch(props.dispatch);
        };

        let clearTimeoutCheck = () => {
            if (logoutcb) clearTimeout(logoutcb);
        }
    
        let setTimeoutCheck = () => {
            logoutcb = setTimeout(logoutCallback, 60 * 60 * 1000);
        }
    
        let resetTimeout = () => {
            clearTimeoutCheck();
            setTimeoutCheck();
        }

        for (var i in mouseEvents) {
            window.addEventListener(mouseEvents[i], () => {
                resetTimeout();
            });
        }
        setTimeoutCheck();
        setWarnTimeout(1);

    }
    
    
    return "";
}

function mapDispatchToProps(dispatch) {
    return {
      ...bindActionCreators(
        {
          logout,
        },
        dispatch
      ),
      dispatch,
    };
  }

export default connect(
    mapDispatchToProps
  )(AutoLogout);