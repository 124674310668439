import {
  FETCH_USER,
  USER_CLICK_HELLO,
  FETCH_USER_PROFILE_URL,
  OPEN_UPDATE_SKILLS,
  CLOSE_UPDATE_SKILLS,
  USER_CLICK_PROFILE_EDIT,
  USER_PROFILE_EDIT_DONE,
  FETCH_SKILLS_LIST,
  ADD_NEW_SKILL,
  MARK_SKILLS_UPDATED,
  BLACKLIST_OCC,
  RESET_BLACKLIST,
  SET_NOTIFICATION,
  SET_NOTIFICATION_AMOUNT,
  SET_SKILLS,
  SET_USER_PERMISSIONS,
  UPDATE_DEMOGRAPHICS,
  ADD_TO_VIEWED_LIST,
  HIDE_AA_HELP_TEXT,
  UPDATE_DEMOGRAPHICS_QUESTIONS,
  OPEN_DEMOGRAPHICS,
  FETCH_INTERNAL_MOBILITY_ROLES,
  SET_COMPLETED_SKILLS_ASSESSMENTS
} from "../actions/types";
import _ from "lodash";
import { Logger, Cache } from "aws-amplify";
const logger = new Logger("USER.REDUCER", "DEBUG");

export default function (state = {}, action) {
  switch (action.type) {
    case USER_CLICK_HELLO: {
      const returnProps = {
        ...state,
        clickedHello: true,
        showHello: false,
      };
      return returnProps;
    }
    case USER_CLICK_PROFILE_EDIT: {
      const returnProps = {
        ...state,
        isProfileBeingEdited: true,
        showChat: true,
      };
      return returnProps;
    }
    case USER_PROFILE_EDIT_DONE: {
      const returnProps = {
        ...state,
        showChat: false,
        isProfileBeingEdited: false,
      };
      return returnProps;
    }

    case OPEN_UPDATE_SKILLS: {

      let occupation = _.find(state.occupationSkills, (i) => {
        if (i.occupation === action.occkey) return i;
      });

      const returnProps = {
        ...state,
        skillEditOpen: true,
        skillEditing: action.occkey,
        skillBeingEdited: occupation,
        skillWasEdited: false
      };

      if(returnProps.skillBeingEdited && !returnProps.skillBeingEdited.deletedSkills){
        returnProps.skillBeingEdited.deletedSkills = []
      }
      return returnProps;
    }
    case CLOSE_UPDATE_SKILLS: {
      const returnProps = {
        ...state,
        skillEditOpen: false,
        skillEditing: null,
        skillBeingEdited: {},
        skillWasEdited: false
      };
      return returnProps;
    }

    case MARK_SKILLS_UPDATED: {
      const returnProps = {
        ...state,
        skillWasEdited: true
      };
      return returnProps;
    }

    case FETCH_SKILLS_LIST: {
      const skills = action.skills;
      const newOccupationSkills = state.occupationSkills;
      let newSkills_names = []
    
      function skillLoop(_skill_list, notOptional){
        _skill_list.forEach((s) => {
          if (notOptional){
            newSkills_names.push(s.name)
          }
          const f = skills[s.name.toLowerCase()];
          if (f) {
            s.description = f;
          }
          else{
            s.description = "No description available"
          }
        })
        return _skill_list
      }

      newOccupationSkills.forEach((mainSkill, i) => {
        mainSkill.skills = skillLoop(mainSkill.skills, true)
        mainSkill.optionalSkills = skillLoop(mainSkill.optionalSkills, false)
      });
      const returnProps = {
        ...state,
        occupationSkills: newOccupationSkills,
        skills_names: [...new Set(newSkills_names)]
      };
      return returnProps;
    }
    case SET_SKILLS: {

      let rawdata = state.rawdata;
      rawdata.occupationSkills = action.occupationSkills;
      return {
        ...state,
        rawdata: rawdata,
        occupationSkills: action.occupationSkills
      }
    }
    case ADD_NEW_SKILL: {
      const newOccupationSkills =  _.cloneDeep(action.newOccupationSkills);
      const newOccIdx = action.skillObjIdx;
      const newSkillNames = state.skills_names;

      newOccupationSkills[newOccIdx].skills.forEach((s) => {
        newSkillNames.push(s.name.toLowerCase())
      })
      
      const returnProps = {
        ...state,
        occupationSkills: newOccupationSkills,
        skills_names: [...new Set(newSkillNames)]
      };
      return returnProps;
    }

    case FETCH_USER_PROFILE_URL: {
      const profileURL = action.profileURL;
      const returnProps = {
        ...state,
        profileImageURL: profileURL,
      };
      return returnProps;
    }

    case SET_USER_PERMISSIONS: {
      return {
        ...state,
        isCookiePermissionsEnabled: action.enabled
      }
    }

    case FETCH_USER: {
      const {
        lastName,
        firstName,
        fullName,
        contract,
        assessmentCompleted,
        reAssessmentDue,
        isInterventionOngoing,
        activeIntervention = {},
        interventions = {},
        occupationSkills = [],
        email,
        organisation,
        isJobNotificationEnabled,
        isEmailNotificationEnabled,
        isCookiePermissionsEnabled,
        location,
        locationOriginal,
        phoneNumber,
        desiredOccupationsOriginal = [],
        occupationsOriginal = [],
        orgDetails = {},
        provider,
        keyworkerID,
        desiredExp,
        assessmentVersion = 0,
        assessmentResult = {},
        latestAssessmentResult = {},
        phonePreferred,
        qualifications,
        customNavLogo,
        contractDetails = {}, 
        skills_names = null,
        candidate_status_history = [],
        allow_reverse_mine,
        trainingOpportunities = undefined,
        jobOpportunities = undefined,
        providerDetails = undefined,
        dateOfBirth = undefined,
        gender = undefined,
        rightTooWork = undefined,
        hasNoticePeriod = undefined,
        s2Cell = "",
        updatedAt,
        viewedJobs = [],
        hideApplicationAssistantHelpText = false,
        internalMobilityProvider = false,
        internalMobilityResponses = undefined,
        internalMobilityRole,
        internalMobilityRoleVerified,
        internalMobilityDepartment,
        internalMobilityDepartmentVerified,
        internalMobilityRoleDetails,
        internalMobilityLineManager,
        internalMobilityLineManagerVerified,
        internalMobilityLineManagerDetails
      } = action.user;

      const {
        interests: {
          salary = [],
          interestsOriginal: {
            creator,
            investigator,
            driver,
            crafter,
            doer,
            facilitator,
          } = {},
          locationRadius = [],
          contract: contractype = [],
          jobTypes = []
        } = {},
        results: {
          COMM: { percentage: latestCOMM = null } = {},
          RTF: { percentage: latestRTF = null } = {},
          ADY: { percentage: latestADY = null } = {},
          SPC: { percentage: latestSPC = null } = {},
          RLS: { percentage: latestRLS = null } = {},
          DMA: { percentage: latestDMA = null } = {},
          CONF: { percentage: latestCONF = null } = {},
          CONT: { percentage: latestCONT = null } = {},
          PSA: { percentage: latestPSA = null } = {},
        } = {},
      } = latestAssessmentResult || {};

      let { name: qualificationsName, level: qualificationsLevel = 0 } =
        qualifications || {};
      if(typeof qualifications === 'string'){
        qualificationsName = "Not Entered";
      }
      const {
        results: {
          COMM: { percentage: initCOMM = null } = {},
          RTF: { percentage: initRTF = null } = {},
          ADY: { percentage: initADY = null } = {},
          SPC: { percentage: initSPC = null } = {},
          RLS: { percentage: initRLS = null } = {},
          DMA: { percentage: initDMA = null } = {},
          CONF: { percentage: initCONF = null } = {},
          CONT: { percentage: initCONT = null } = {},
          PSA: { percentage: initPSA = null } = {},
        } = {},
      } = assessmentResult || {};

      const [_salary] = salary;
      const [_contractype] = contractype;
      const [_travel] = locationRadius;      

      var k = false;
      if (assessmentVersion > 1) {
        k = true;
      }
      //styles graph
      const profileStylesGraphData = [
        {
          name: "Complexity",
          Latest: latestSPC,
          Initial: k ? initSPC : latestSPC,
        },
        {
          name: "Speed",
          Latest: latestDMA,
          Initial: k ? initDMA : latestDMA,
        },
        {
          name: "Accuracy",
          Latest: latestPSA,
          Initial: k ? initPSA : latestPSA,
        },
        {
          name: "Responsiveness",
          Latest: latestRTF,
          Initial: k ? initRTF : latestRTF,
        },
        {
          name: "Adaptability",
          Latest: latestADY,
          Initial: k ? initADY : latestADY,
        },
        {
          name: "Learning",
          Latest: latestRLS,
          Initial: k ? initRLS : latestRLS,
        },
        {
          name: "Confidence",
          Latest: latestCONF,
          Initial: k ? initCONF : latestCONF,
        },
        {
          name: "Control",
          Latest: latestCONT,
          Initial: k ? initCONT : latestCONT,
        },
        {
          name: "Commitment",
          Latest: latestCOMM,
          Initial: k ? initCOMM : latestCOMM,
        }
      ];

      //interets
      const profileInterestsGraphData = [
        {
          id: "Doer",
          label: "Doer",
          value: doer,
        },
        {
          id: "Driver",
          label: "Driver",
          value: driver,
        },
        {
          id: "Investigator",
          label: "Investigator",
          value: investigator,
        },
        {
          id: "Facilitator",
          label: "Facilitator",
          value: facilitator,
        },
        {
          id: "Crafter",
          label: "Crafter",
          value: crafter,
        },
        {
          id: "Creator",
          label: "Creator",
          value: creator,
        },
      ];

      let candidateStatus = "Status not entered"
      if (candidate_status_history && candidate_status_history.length > 0){
        candidateStatus = candidate_status_history[candidate_status_history.length -1].status
      }
      let showHello = !state.clickedHello && !Cache.getItem("clickedHello")
      let showDemographics = false
      let holdUser = false
      if(showHello && internalMobilityProvider && ((internalMobilityResponses === undefined || internalMobilityRole === undefined || internalMobilityDepartment === undefined || internalMobilityLineManager === undefined) || (internalMobilityRoleVerified === "n"))){
        showDemographics = true
        if(internalMobilityRoleVerified === "n"){
          holdUser = true
        }
      }
      else if(showHello && !internalMobilityProvider && trainingOpportunities === undefined && jobOpportunities === undefined){
        showDemographics = true
      }
      const returnProps = {
        ...state,
        rawdata: action.user,
        lastName,
        firstName,
        contract,
        assessmentCompleted,
        reAssessmentDue,
        isInterventionOngoing,
        email,
        assessmentVersion,
        organisation,
        location: locationOriginal ? locationOriginal : location,
        phoneNumber,
        customNavLogo,
        phonePreferred,
        qualificationsLevel,
        qualificationsName,
        occupationSkills,
        orgDetails,
        provider,
        providerDetails,
        keyworkerID,
        activeIntervention,
        isJobNotificationEnabled,
        isEmailNotificationEnabled,
        isCookiePermissionsEnabled,
        interventions,
        fullName,
        travelToWork: _travel,
        salary: _salary === "0" ? "No Preference" : _salary,
        contractType: _contractype,
        jobTypes: jobTypes && jobTypes.length > 0 ? jobTypes : [],
        previousOccupations: occupationsOriginal,
        preferredOccupations: desiredOccupationsOriginal,
        desiredExp,
        profileStylesGraphData,
        profileInterestsGraphData,
        showChat:
          !assessmentCompleted ||
          !!reAssessmentDue ||
          !!isInterventionOngoing ||
          state.isProfileBeingEdited === true,
        showHello: showHello,
        showDemographics: showDemographics,
        firstLogin: (assessmentVersion && assessmentVersion >= 1) ? false : true,
        contractDetails,
        skills_names,
        candidateStatus,
        allow_reverse_mine,
        trainingOpportunities,
        jobOpportunities,
        dateOfBirth,
        gender,
        rightTooWork,
        hasNoticePeriod,
        s2Cell,
        updatedAt,
        viewedJobs,
        hideApplicationAssistantHelpText,
        internalMobilityProvider,
        internalMobilityResponses,
        internalMobilityRole,
        internalMobilityRoleVerified,
        internalMobilityDepartment,
        internalMobilityDepartmentVerified,
        internalMobilityRoleDetails,
        internalMobilityLineManager,
        internalMobilityLineManagerVerified,
        internalMobilityLineManagerDetails,
        holdUser
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case SET_NOTIFICATION: {
      let goto = action.goto;
      return {
        ...state,
        goto: goto
      }
    }
    case SET_NOTIFICATION_AMOUNT: {
      let notificationAmount = action.notificationAmount;
      return {
        ...state,
        notificationAmount: notificationAmount
      }
    }
    case OPEN_DEMOGRAPHICS: {
      return {
        ...state,
        showDemographics: true,
        showDemographicsEdit: true
      }
    }
    case UPDATE_DEMOGRAPHICS: {
      let candidateStatus = "Status not entered"
      if (action.demoData.candidate_status_history && action.demoData.candidate_status_history.length > 0){
        candidateStatus = action.demoData.candidate_status_history[action.demoData.candidate_status_history.length -1].status
      }

      return {
        ...state,
        showDemographics: action.showPage,
        showDemographicsEdit: action.showPage,        
        holdUser: action.holdUser,
        candidateStatus: candidateStatus,
        internalMobilityResponses:  action.IMdata,
        ...action.demoData
      }
    }
    case UPDATE_DEMOGRAPHICS_QUESTIONS: {
      return {
        ...state,
        internalMobilityQuestions: action.data,
        internalMobilityRoles: action.roles,
        internalMobilityDepartments: action.departments,
        internalMobilityLineManagers:action.lineManagers
      }
    }
    case FETCH_INTERNAL_MOBILITY_ROLES: {
      return {
        ...state,
        internalMobilityRoles: action.data,
      }
    }
    case ADD_TO_VIEWED_LIST: {
      return {
        ...state,
        viewedJobs: [...state.viewedJobs, action.jobId]
      }
    }
    case HIDE_AA_HELP_TEXT: {
      return {
        ...state,
        hideApplicationAssistantHelpText: true
      }
    }
    case SET_COMPLETED_SKILLS_ASSESSMENTS: {
      return {
        ...state,
        completedSkillAssessments: action.data
      }
    }
    default:
      return state;
  }
}
