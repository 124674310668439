import { Typography, withStyles, TextField  } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, {useState} from "react";
import ReactSelect from "react-select";
import { autoCompleteV2, autoCompleteV2Skill } from "../../actions/applicationAssistant_action"
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";

const styles = (theme) => ({
    nav:{
      width:230,
      marginTop:5,
      [theme.breakpoints.down("sm")]: {
          width: 200
      },
      [theme.breakpoints.down("xs")]: {
          width: 200
      },
    },
    application: {
      width:"100%",
      marginTop:5,
    },
    chat: {
      marginTop:10,
      backgroundColor: "#f5f5f5",
      borderColor: "#fa6a14",
      borderWidth: 0.5,
    }
  });

const OccupationAutoComplete = ({
    classes,
    onSelect,
    autoCompleteV2,
    autoCompleteV2Skill,
    error,
    disabled,
    occListOptions,
    fontSize = "small",
    size = "small",
    placeholder,
    type,
    fullWidth = false
    }) => {
    const fontSizeNumber = fontSize === "small" ? "0.8125rem" : "1rem"
    const [filteredOptions, setFilteredOptions] = useState(type === "occupation" && occListOptions ? occListOptions.map((item) => {return {label: item, group: "Profile Occupations"}}) : []); 
    const [input, setInput] = useState("");
    const onInputChange = async (event, searchText) => {
      setInput(searchText)
      if(searchText === ""){
        setFilteredOptions(type === "occupation" && occListOptions ? occListOptions.map((item) => {return {label: item, group: "Profile Occupations"}}) : [])
      }
      else{
        const response = type === "occupation" ? await autoCompleteV2(searchText) : await autoCompleteV2Skill(searchText)
        // console.log(response)
        if(response && (response.directMatches || response.closeMatches)){
          setFilteredOptions([
            ...response.directMatches.map((item) => {return {label: item.low, group: "Direct Matches"}}), 
            ...response.closeMatches.map((item) => {return {label: item.low, group: "Close Matches"}})])
        }
        else{
          setFilteredOptions([])        
        }
      }
    };
  
    return (
        <Autocomplete
          fullWidth={fullWidth}
          className={classes}
          disabled={disabled}
          onInputChange={onInputChange}
          onChange={(e, v) => {
            if(v){
              onSelect(v.label);
              setInput(v.label);             
            }
          }}
          inputValue={input}
          options={filteredOptions}
          getOptionLabel={(option) => option.label}
          groupBy={(option) => option.group}
          renderInput={(params) => {
            return (
              <TextField 
                {...params}
                size={size}
                InputLabelProps={{...params.InputLabelProps, style: { fontSize:fontSizeNumber}}}
                InputProps={{...params.InputProps, style: { fontSize: fontSizeNumber}}}
                label={placeholder}
                placeholder={placeholder}
                variant="outlined" 
                fullWidth 
                error={error}
              />
            ) 
          }}
          renderOption={(option) => {
            if(option.group === "Direct Matches" && option.label.includes(input)){
              const nonBold = option.label.split(input);
              return (
                <Typography 
                  style={{fontSize:fontSizeNumber, display:"flex", alignItems:"center", whiteSpace: "pre"}} 
                >
                  <strong>{nonBold[0]}</strong>
                  {input}
                  <strong>{nonBold[1]}</strong>
                </Typography>
              )
            }
            else{
              return (
                <Typography 
                  style={{fontSize:fontSizeNumber, display:"flex", alignItems:"center"}}
                >
                  {option.label}
                </Typography>
                )
            }
            }
          }
          filterOptions={(options) => options}
        />
    );
  };

const OccupationSearchComponent = (({
    classes, 
    autoCompleteV2, 
    autoCompleteV2Skill,
    onSelect, 
    addOccupationFilter,
    disabled, 
    occList, 
    interalRolesOnly, 
    internalMobilityRoles,
    stylePreset = "nav",
    size="small",
    fontSize="small",
    fullWidth,
    placeholder="",
    type = "occupation",
}) => {
    if(interalRolesOnly && internalMobilityRoles){
        const options = internalMobilityRoles.map((item) => {return{value:item.ID, label: item.roleName}})
        return (
            <>
               <ReactSelect
                placeholder="What Occupation"
                onChange={(event) => {
                    if(event.value){
                        const role = internalMobilityRoles.find((item) => item.ID === event.value);
                        addOccupationFilter(role.occupation, false)
                    }
                }}
                options={options}
                menuPortalTarget={document.body}
                menuPosition={'fixed'} 
                isDisabled={disabled}
                className={classes.nav}
                />
            </>
        )
    }
    else if(!interalRolesOnly){
        return (
            <>
                <OccupationAutoComplete
                    type={type}
                    classes={classes[stylePreset]}
                    placeholder={placeholder}
                    fontSize={fontSize}
                    size={size}
                    onSelect={(value) => onSelect([value])}
                    occListOptions={occList}
                    error={false}
                    disabled={disabled}
                    autoCompleteV2={autoCompleteV2}
                    autoCompleteV2Skill={autoCompleteV2Skill}
                    fullWidth={fullWidth}
                />
            </>
        )
    }
    else{
        return <></>
    }
})

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ autoCompleteV2, autoCompleteV2Skill }, dispatch),
    dispatch
  };
}

export const OccupationSearch = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(OccupationSearchComponent));

