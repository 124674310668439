import { API } from "aws-amplify";
import config from "../config";
import {
  FETCH_INTERVENTIONS_MISSIONS,
  START_INTERVENTION,
  START_MISSION,
} from "./types";
import { fetchUser } from "./user_actions";

import { recordMissionClick } from "./analytics_actions";

export const getMissionsAndInterventions = () => async (dispatch, getState) => {
  const {
    user: {
      rawdata: { interventions },
    },
    missions: { missions, interventions: fullInterventions },
  } = getState();
  let result = [];
  if (!missions || !fullInterventions) {
    result = await API.get(config.API_NAME, "/missioninterventions");
  } else {
    result = missions.concat(fullInterventions);
  }
  return dispatch({
    type: FETCH_INTERVENTIONS_MISSIONS,
    payload: result,
    missionsInProgress: Object.values(interventions || {}),
    interventionsInProgress: Object.values(interventions || {}),
  });
};

// Both Intervention and Mission
export const startMission = (id, type) => async (dispatch, getState) => {
  // let payload = null;
  const {
    missions: { missions, interventions },
  } = getState();
  const whereToLook = type === "intervention" ? interventions : missions;
  const payload = whereToLook.find(({ sort }) => sort === id);

  const { data, error } = await API.post(
    config.API_NAME,
    "/missioninterventions",
    {
      body: payload,
    }
  );
  if (data) {
    dispatch(fetchUser());
    dispatch({ type: START_MISSION, id, entityType: type });

    dispatch(recordMissionClick("start-mission", payload.title, id));
  }
  if (error) {
    throw new Error(error);
  }
};

export const startIntervention = (interventionId) => async (dispatch) => {

  dispatch(recordMissionClick("start-intervention", interventionId, interventionId));

  return dispatch({ type: START_INTERVENTION, interventionId });
};
