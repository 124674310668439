import {
  FETCH_INTERVENTIONS,
  FETCH_INTERVENTIONS_MISSIONS,
  FETCH_MISSIONS,
  START_INTERVENTION,
  START_MISSION,
} from "../actions/types";

export const missionReducer = (state = {}, action) => {
  switch (action.type) {
    // TODO REMOVAL START
    case FETCH_MISSIONS:
      return {
        ...state,
        missions: action.missions.sort((a, b) => {
          if (a.has_started && !b.has_started) {
            return 1;
          }
          if (a.has_started && b.has_started) {
            return 0;
          }
          return -1;
        }),
      };
    case FETCH_INTERVENTIONS: {
      const {
        interventions,
        scores: { initialScore, finalScore },
      } = action;
      return {
        ...state,
        interventions: interventions
          .map((intervention) => ({
            ...intervention,
            initialScore: initialScore || "Not Started",
            finalScore: finalScore || "Not Started",
          }))
          .sort((a, b) => {
            if (a.has_started && !b.has_started) {
              return 1;
            }
            if (a.has_started && b.has_started) {
              return 0;
            }
            return -1;
          }),
      };
    }

    case START_INTERVENTION: {
      const intervention = state.interventions.find(
        ({ sort }) => sort === action.interventionId
      );
      intervention.has_started = true;
      return {
        ...state,
        interventions: state.interventions.sort((a, b) => {
          if (a.has_started && !b.has_started) {
            return 1;
          }
          if (a.has_started && b.has_started) {
            return 0;
          }
          return -1;
        }),
      };
    }
    // TODO REMOVAL END
    case START_MISSION: {
      const { entityType, id } = action;
      const { missions, interventions } = state;
      const whereToLook =
        entityType === "intervention" ? interventions : missions;
      const payload = whereToLook.find(({ sort }) => sort === id);
      payload.has_started = true;
      return {
        ...state,
        missions: state.missions.sort((a, b) => {
          if (a.has_started && !b.has_started) {
            return 1;
          }
          if (a.has_started && b.has_started) {
            return 0;
          }
          return -1;
        }),
      };
    }

    case FETCH_INTERVENTIONS_MISSIONS: {
      const { payload, missionsInProgress, interventionsInProgress } = action;
      const missions = payload
        .filter(({ userID }) => userID === "missions")
        .filter(({ type }) => type === 1);
      const interventions = payload
        .filter(({ userID }) => userID === "intervention")
        .concat(payload.filter(({ type }) => type === 3));

      return {
        ...state,
        missions: missions
          .map((mission) => ({
            ...mission,
            has_completed:
              missionsInProgress.findIndex(
                ({ sort }) => sort === mission.sort
              ) >= 0
                ? true
                : false,
          }))
          .sort((a, b) => {
            if (a.has_completed && !b.has_completed) {
              return 1;
            }
            if (a.has_completed && b.has_completed) {
              return 0;
            }
            return -1;
          }),
        interventions: interventions
          .map((intervention) => {
            const initialScore = (
              interventionsInProgress.find(
                ({ sort }) => sort === intervention.sort
              ) || {}
            ).initialScore;
            const finalScore = (
              interventionsInProgress.find(
                ({ sort }) => sort === intervention.sort
              ) || {}
            ).finalScore;
            let has_completed = false;
            if (intervention.type === 3) {
              has_completed =
                (
                  interventionsInProgress.find(
                    ({ sort }) => sort === intervention.sort
                  ) || {}
                ).status === "Complete";
            } else {
              has_completed =
                Number(initialScore) >= 0 && Number(finalScore) >= 0;
            }
            return {
              ...intervention,
              initialScore: initialScore,
              finalScore: finalScore,
              has_completed,
              has_started:
                interventionsInProgress.findIndex(
                  ({ sort }) => sort === intervention.sort
                ) >= 0
                  ? true
                  : false,
            };
          })
          .sort((a, b) => {
            if (a.has_started && !b.has_started) {
              return 1;
            }
            if (a.has_started && b.has_started) {
              return 0;
            }
            return -1;
          }),
      };
    }

    default:
      return state;
  }
};
