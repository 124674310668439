import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import classnames from "classnames";
import { Spring, config} from "react-spring/renderprops";

import { ReactComponent as HelloIcon } from "./Icons/hello.svg";
import { ReactComponent as SkillsIcon } from "../Common/Icons/skill.svg";
import { ReactComponent as InterestsIcon } from "../Common/Icons/interests.svg";
import { ReactComponent as StylesIcon } from "../Common/Icons/styles.svg";
import { ReactComponent as SmallCoin } from "../Common/Icons/smallCoin.svg";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import { clickHello } from "../../actions/user_actions";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 12000,
    position: "absolute",
    backgroundColor: "#F8F9FA",
    width: "100%",
    height: "100%",
    overflow: 'scroll'
  },
  grid: {
    height: "100%",
  },
  gridin: {
    textAlign: "center",
    width:"80%"
  },
  icon: {
    width: 162,
    height: 166,
  },
  icondiv: {
    width: 162,
    height: 166,
    margin: 'auto'
  },
  title: {
    fontSize: 28,
    fontWeight: 600,
    color: "#202A3E",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  body: {
    //maxWidth: "70%",
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8),
    textAlign: "center",
    fontSize: 18,
    color: "#5A6375",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      fontSize: 14,
    },
  },
  cardholder: {
    maxWidth: 880,
    margin: "auto",
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  card: {
    height: "100%",
    padding: 15,
    textAlign: "left",
  },
  bodycard: {
    fontSize: 18,
    color: "#5A6375",
    textAlign: "left",
    marginTop: 5,
  },
  button: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  cardtitle: {
    fontWeight: 600,
    fontSize: 20,
  },
  skills: {
    color: "#53D28E",
  },
  interests: {
    color: "#2988FF",
  },
  styles: {
    color: "#FF9800",
  },
  backdrop: {
    zIndex: 8000,
  },
  coinIcon: {
    marginInline: "5px",
    marginBottom: -1,
  },
mathIcon :{ 
    marginBottom: "-3px",
}
});

class Hello extends Component {
  render() {
    const {
      classes,
      user: { firstName = "", showHello = false, firstLogin, internalMobilityProvider, orgDetails = {}}, rewards: { coins },
    } = this.props;
    const { name = ""} = orgDetails;
    return (
      <Backdrop
        open={showHello}
        transitionDuration={{
          appear: 0,
          exit: 500,
          enter: 0,
        }}
        classes={{
          root: classes.backdrop,
        }}
      >
        <div className={classes.root}>
          <Grid
            container
            spacing={0}
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.grid}
            style={{marginTop: 100}}
          >
            <Grid item className={classes.gridin}>
            <Spring
                from={{ transform: "scale(0.5)" }}
                to={{ transform: "scale(1)" }}
                delay={100}
                config={config.wobbly}
              >
                {(props) => (
                  <div style={props} className={classes.icondiv}>
                    <HelloIcon className={classes.icon} />
                  </div>
                )}
              </Spring>

              
              <Typography variant="h4" className={classes.title}>
                {firstLogin ? (
                  "Hello " + firstName
                ):(
                  "Welcome back " + firstName
                )}
              </Typography>
              {firstLogin ? (
                internalMobilityProvider ? (                  
                  <Typography variant="body1" className={classes.body}>
                    Welcome aboard! Thanks for signing up, it's fantastic to have you here!
                    <br />
                    Engage with our virtual assistant, and over the next few minutes, we'll explore what sets you apart.  
                    <br />
                    {"This exploration will enable us to identify the perfect opportunities for you, granting "+ name + " insight into your skills, knowledge, abilities, competencies, and potential."}
                    <br />
                  </Typography>
                ) :(
                  <Typography variant="body1" className={classes.body}>
                    You’ve taken a step towards a better career. Now it’s our turn to do our bit.  
                    <br />
                    We’ll unearth your potential in just four minutes by understanding what makes you tick. We’ll match you to jobs that you never knew you could do.  
                    <br />
                    Just chat to our virtual assistant and let us do the job searching for you. It’s simple, and you’ll learn a lot about yourself along the way – including your skills, interests and styles.
                    <br />
                    Once you’ve answered our questions - we remember all the details so you won’t need to do it again
                  </Typography>
                )
              ):(
                internalMobilityProvider ? (
                  <Typography variant="body1" className={classes.body}>
                    Ensure your profile remains current to ensure accurate matching with the right opportunities.
                    <br />
                    {"Keeping your information up to date also allows "+ name +" to stay informed about your progress and development."}
                    <br />
                    We're thrilled to have you back!
                  </Typography>
                ) : (
                  <Typography variant="body1" className={classes.body}>
                    Whether you want to find jobs based on experience or find something new based on your skills, styles or interests.
                    <br />
                    We’ve been mining the internet to find you the best matched jobs.
                    <br />
                  </Typography>
                )
              )}
              <Hidden xsDown>
                <div className={classes.cardholder}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="center"
                    alignItems="stretch"
                  >
                    <Grid item sm={4}>
                      <Paper className={classes.card}>
                        <Grid
                          container
                          spacing={1}
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <Grid item>
                            <SkillsIcon />
                          </Grid>
                          <Grid item xs>
                            <Typography
                              variant="body1"
                              className={classnames(
                                classes.cardtitle,
                                classes.skills
                              )}
                            >
                              Skills
                            </Typography>
                          </Grid>
                        </Grid>

                        <Typography
                          variant="body1"
                          className={classes.bodycard}
                        >
                          {firstLogin ? (
                            "We’ll assess what you can do based on what you’ve done in previous jobs"
                          ) : (
                            "We’ve found the best jobs for you based on your skills, knowledge and abilities"
                          )}
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item sm={4}>
                      <Paper className={classes.card}>
                        <Grid
                          container
                          spacing={1}
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <Grid item>
                            <InterestsIcon />
                          </Grid>
                          <Grid item xs>
                            <Typography
                              variant="body1"
                              className={classnames(
                                classes.cardtitle,
                                classes.interests
                              )}
                            >
                              Interests
                            </Typography>
                          </Grid>
                        </Grid>

                        <Typography
                          variant="body1"
                          className={classes.bodycard}
                        >
                          {firstLogin ? (
                            "We’ll analyse what you like doing – in and out of work"
                          ) : (
                            "We’re bringing opportunities to you centred on your interests - we all perform better doing jobs we enjoy"
                          )}
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item sm={4}>
                      <Paper className={classes.card}>
                        <Grid
                          container
                          spacing={1}
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <Grid item>
                            <StylesIcon />
                          </Grid>
                          <Grid item xs>
                            <Typography
                              variant="body1"
                              className={classnames(
                                classes.cardtitle,
                                classes.styles
                              )}
                            >
                              Styles
                            </Typography>
                          </Grid>
                        </Grid>

                        <Typography
                          variant="body1"
                          className={classes.bodycard}
                        >
                          {firstLogin ? (
                            "We’ll figure out your strengths and how you like to work"
                          ) : (
                            "All occupations require a unique combination of resilience, brainpower and agility; we’ve found you jobs based on your unique profile"
                          )}
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </div>
              </Hidden>
              {!firstLogin && coins && (
                <Typography variant="body1" className={classes.body}>
                    You have {coins} 
                    <SmallCoin className={classes.coinIcon} />
                    coins
                </Typography>
              )}
              {!firstLogin && (
                <Typography variant="body1" className={classes.body}>
                    You can use your coins to buy Application Assistant content '
                    <RemoveIcon className={classes.mathIcon} fontSize="small"/>
                    <SmallCoin className={classes.coinIcon} />
                    ', and don’t worry if you run out because you can earn more '
                    <AddIcon className={classes.mathIcon} fontSize="small"/>
                    <SmallCoin className={classes.coinIcon} />
                    ' or buy more.
                </Typography>
              )}
              {!internalMobilityProvider && (
                <Typography variant="body1" className={classes.body}>
                  {firstLogin && "Let's get started.  "}
                </Typography>
              )}
              <Button
                id="lets-go-btn"
                variant="contained"
                className={classes.button}
                color="primary"
                onClick={() => {
                  this.props.clickHello();
                }}
              >
                Lets go!
              </Button>
              <div style={{height: 60}}> </div>
            </Grid>
          </Grid>
        </div>
      </Backdrop>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ clickHello }, dispatch),
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Hello)));
