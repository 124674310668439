import { GiftedChat } from "react-native-gifted-chat";
import {
  CHAT_WELL_DONE,
  RECEIVE_BOT_DONE,
  RECEIVE_BOT_MESSAGE,
  RECEIVE_BOT_STATE,
  SEND_CHAT_MESSAGE,
  RESET_MESSAGES,
  UPDATE_CHAT_SKILLS
} from "../actions/types";

export const chatReducer = (state = {}, action) => {
  switch (action.type) {
    case SEND_CHAT_MESSAGE:
      return {
        ...state,
        messages: GiftedChat.append(state.messages, action.messages),
        isTyping: true,
        disableInput: action.disableInput,
      };
    case RESET_MESSAGES:
      return {
        messages: [],
        isTyping: false,
        disableInput: true,
        showWellDone : state.showWellDone
      };
    case RECEIVE_BOT_MESSAGE:
      // console.log("message : " + action.messages)
      return {
        ...state,
        isTyping: action.messages[0].typing || false,
        messages: GiftedChat.append(state.messages, action.messages),
        disableInput: action.disableInput || state.showWellDone,
      };
    case RECEIVE_BOT_DONE:
      return {
        ...state,
        showWellDone: true,
        disableInput: true,
      };

    case UPDATE_CHAT_SKILLS:
      let USERnewOccupationSkills = action.newOccupationSkills;
      const newOccIdx = action.skillObjIdx;
      let newOccupationSkills = state.occupationSkills;
      let newSkillsNumber = state.skillsNumber;
      
      if (USERnewOccupationSkills[newOccIdx].skills.length) {
        newSkillsNumber += USERnewOccupationSkills[newOccIdx].skills.length;
        USERnewOccupationSkills[newOccIdx].skills = USERnewOccupationSkills[newOccIdx].skills.map((item) => {
          return item.name.charAt(0).toUpperCase() + item.name.slice(1);
        })
      

        if (newOccIdx === newOccupationSkills.length){
          newOccupationSkills.push(USERnewOccupationSkills[newOccIdx])
        }
        else{
          newSkillsNumber -= newOccupationSkills[newOccIdx].skills.length;
          newOccupationSkills[newOccIdx] = USERnewOccupationSkills[newOccIdx]
        }
      }
      return {
        ...state,
        occupationSkills: newOccupationSkills,
        skillsNumber: newSkillsNumber
      };

    case RECEIVE_BOT_STATE:
      const {
        header = "",
        progress,
        focus,
        ui_event = [],
        assesmentResult = {},
        occupationsData = {},
      } = action.payload;

      const {
        interests: {
          salary = [],
          interestsOriginal: {
            creator = 0,
            investigator = 0,
            driver = 0,
            crafter = 0,
            doer = 0,
            facilitator = 0,
          } = {},
          locationRadius = [],
          locationOriginal = [],
          contract: contractype = [],
          jobTypes = []
        } = {},
        results: {
          COMM: { percentage: latestCOMM = null } = {},
          RTF: { percentage: latestRTF = null } = {},
          ADY: { percentage: latestADY = null } = {},
          SPC: { percentage: latestSPC = null } = {},
          RLS: { percentage: latestRLS = null } = {},
          DMA: { percentage: latestDMA = null } = {},
          CONF: { percentage: latestCONF = null } = {},
          CONT: { percentage: latestCONT = null } = {},
          PSA: { percentage: latestPSA = null } = {},
        },
        /*eslint-disable */
        resultOriginal: {
          COMM = 0,
          RTF = 0,
          ADY = 0,
          SPC = 0,
          RLS = 0,
          DMA = 0,
          CONF = 0,
          CONT = 0,
          PSA = 0,
        },
      } = assesmentResult;
      /*eslint-enable */
      
      const {
        desiredOccupationsOriginal = [],
        occupationsOriginal = [],
        occupationSkills = [],
        desiredExp = [],
        qualifications,
        candidate_status_history = [],
        allow_reverse_mine =  true,
        internalMobilityRoleDetails
      } = occupationsData;

      var skillsNumber = 0;

      var ocSkills = occupationSkills;

      let { name: qualificationsName, level: qualificationsLevel = 0 } =
        qualifications || {};
      if(typeof qualifications === 'string'){
        qualificationsName = "Not Entered";
      }

      if (ocSkills.length) {
        ocSkills.forEach((item) => {
          if (item.skills) {
            skillsNumber += item.skills.length;
            var arr = [];

            item.skills.forEach((elem) => {
              arr.push(elem.name.charAt(0).toUpperCase() + elem.name.slice(1));
            });
            item.skills = arr;
          }
        });
      }

      /*
      var latestCOMM = (Math.max(parseInt(COMM), 3) / 20) * 100;
      var latestRTF = (Math.max(parseInt(RTF), 3) / 20) * 100;
      var latestADY = (Math.max(parseInt(ADY), 3) / 20) * 100;
      var latestSPC = (Math.max(parseInt(SPC), 3) / 20) * 100;
      var latestRLS = (Math.max(parseInt(RLS), 3) / 20) * 100;
      var latestDMA = (Math.max(parseInt(DMA), 3) / 20) * 100;
      var latestCONF = (Math.max(parseInt(CONF), 3) / 20) * 100;
      var latestCONT = (Math.max(parseInt(CONT), 3) / 20) * 100;
      var latestPSA = (Math.max(parseInt(PSA), 3) / 20) * 100;
*/
      const {
        results: {
          COMM: { percentage: initCOMM = null } = {},
          RTF: { percentage: initRTF = null } = {},
          ADY: { percentage: initADY = null } = {},
          SPC: { percentage: initSPC = null } = {},
          RLS: { percentage: initRLS = null } = {},
          DMA: { percentage: initDMA = null } = {},
          CONF: { percentage: initCONF = null } = {},
          CONT: { percentage: initCONT = null } = {},
          PSA: { percentage: initPSA = null } = {},
        } = {},
      } = assesmentResult || {};

      var k = false;
      /*if (assessmentVersion > 1) {
        k = true;
      }*/
      //styles graph

      const minstyle = 15;

      const stylesGraphData = [
        {
          name: "Complexity",
          Latest: Math.max(latestSPC, minstyle),
          Initial: k ? initSPC : Math.max(latestSPC, minstyle),
        },
        {
          name: "Speed",
          Latest: Math.max(latestDMA, minstyle),
          Initial: k ? initDMA : Math.max(latestDMA, minstyle),
        },
        {
          name: "Accuracy",
          Latest: Math.max(latestPSA, minstyle),
          Initial: k ? initPSA : Math.max(latestPSA, minstyle),
        },
        {
          name: "Responsiveness",
          Latest: Math.max(latestRTF, minstyle),
          Initial: k ? initRTF : Math.max(latestRTF, minstyle),
        },
        {
          name: "Adaptability",
          Latest: Math.max(latestADY, minstyle),
          Initial: k ? initADY : Math.max(latestADY, minstyle),
        },
        {
          name: "Learning",
          Latest: Math.max(latestRLS, minstyle),
          Initial: k ? initRLS : Math.max(latestRLS, minstyle),
        },
        {
          name: "Confidence",
          Latest: Math.max(latestCONF, minstyle),
          Initial: k ? initCONF : Math.max(latestCONF, minstyle),
        },
        {
          name: "Control",
          Latest: Math.max(latestCONT, minstyle),
          Initial: k ? initCONT : Math.max(latestCONT, minstyle),
        },
        {
          name: "Commitment",
          Latest: Math.max(latestCOMM, minstyle),
          Initial: k ? initCOMM : Math.max(latestCOMM, minstyle),
        },
      ];

      const [_salary] = salary;
      const [_contractype] = contractype;
      const [_travel] = locationRadius;
      const [_location] = locationOriginal;

      const isInterestActive = focus === "interests" || focus === "styles";

      const isProfileActive =
        focus === "profile" ||
        focus === "skills" ||
        focus === "interests" ||
        focus === "styles";

      const isStylesActive = focus === "styles";

      const isSkillsActive =
        focus === "skills" || focus === "interests" || focus === "styles";

      var def =
        parseInt(doer, 10) +
        parseInt(creator, 10) +
        parseInt(investigator, 10) +
        parseInt(driver, 10) +
        parseInt(crafter, 10) +
        parseInt(facilitator, 10);

      const interestsGraphData = [
        {
          id: "Doer",
          label: "Doer",
          value: !isInterestActive || def === 0 ? 1 : parseInt(doer, 10),
        },
        {
          id: "Driver",
          label: "Driver",
          value: parseInt(driver, 10),
        },
        {
          id: "Investigator",
          label: "Investigator",
          value: parseInt(investigator, 10),
        },
        {
          id: "Facilitator",
          label: "Facilitator",
          value: parseInt(facilitator),
        },
        {
          id: "Crafter",
          label: "Crafter",
          value: parseInt(crafter),
        },
        {
          id: "Creator",
          label: "Creator",
          value: parseInt(creator),
        },
      ];
      var prevOcc = [] 
      for (const idx in desiredExp) {
        if (desiredExp[idx]){
          prevOcc.push(desiredOccupationsOriginal[idx])
        }
      }
      prevOcc.push(...occupationsOriginal);
      
      let candidateStatus = "Status not entered"
      if (candidate_status_history && candidate_status_history.length > 0){
        candidateStatus = candidate_status_history[candidate_status_history.length -1].status
      }

      return {
        ...state,
        progress,
        header,
        focus,
        ui_event,
        salary: _salary === "0" ? "No Preference" : _salary,
        travelToWork: _travel,
        contractType: _contractype,
        jobTypes: jobTypes && jobTypes.length > 0 ? jobTypes : [],
        location: _location,
        interestsGraphData,
        stylesGraphData,
        isInterestActive,
        isStylesActive,
        qualificationsName,
        qualificationsLevel,
        skillsNumber,
        desiredExp,
        isSkillsActive,
        isProfileActive,
        preferredOccupations: desiredOccupationsOriginal,
        previousOccupations: prevOcc,
        occupationSkills: ocSkills,
        candidateStatus,
        allow_reverse_mine,
        internalMobilityRoleDetails
      };
    case CHAT_WELL_DONE:
      return {
        ...state,
        showWellDone: false,
      };
    default:
      return state;
  }
};
