/* eslint-disable */
import { filter } from "core-js/fn/array";
/* eslint-enable */
import _ from 'lodash';
import moment from "moment";
/* eslint-disable no-redeclare */
import { Cache } from "aws-amplify";
import {
  APPLY_FOR_A_JOB,
  APPLY_FOR_A_JOB_UPDATE,
  FETCH_APPLIED_JOBS,
  FETCH_FAVOURITED_JOBS,
  SET_FAVOURITE_JOB,
  SET_SELECTED_JOB,
  BLACKLIST_OCC,
  SET_BLACKLIST,
  RESET_JOBS,
  FETCH_JOB_CATEGORIES,
  FETCH_QUALIFICATIONS,
  FETCH_ALL_JOBS,
  FETCH_INDIVIDUAL_JOBS,
  FETCH_NEARME_JOBS,
  ADD_FILTER,
  OCC_FILTER,
  JOBS_FILTER,
  LEVEL_FILTER,
  FETCH_CENTRES,
  TOGGLE_LOADING,
  SET_RESOURCES,
  SET_ONLINE_COURSES,
  SET_ACTIVE_RESOURCE,
  LOADING_RESOURCES,
  UPDATE_OCCUPATION_SKILL,
  MARK_ITEM_READ,
  UPDATE_FULL_DESCRIPTION,
  OPTIONAL_SKILL_CHECK,
  LOADED_CONTENT_DASHBOARD,
  LOADING_ONLINE_COURSES,
  UPDATE_JOB_CATEGORIES,
  UPDATE_LOAD_MORE_COUNT,
  FETCH_ALL_JOBS_COMPLETE,
  SET_APPLICATIONS_NOTIFICATION
} from "../actions/types";


export const jobReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_JOBS:
      Cache.removeItem("jobCategories");
      Cache.removeItem("jobCategoriesV2");
      Cache.removeItem("jobs_nearme");
      Cache.removeItem("jobs_nearmeV2");
      Cache.removeItem("jobs");
      Cache.removeItem("jobsV2");
      Cache.removeItem("quals");
      Cache.removeItem("qualsV2");
      Cache.removeItem("interestStyles");

      return {
        occupationListFilter: [
          { name: "Preferred", value: true },
          { name: "Previous", value: true },
          { name: "Skills", value: true },
          { name: "Styles", value: true },
          { name: "Interest", value: true },
          { name: "Searched", value: true },
        ],
      };
      //return {};

    case TOGGLE_LOADING:
      return {
        ...state,
        loading : action.setToggle
      };

    case LOADING_RESOURCES: {
      return {
        ...state,
        resourceLoading: action.loading
      }
    }
    
    case LOADING_ONLINE_COURSES: {
      return {
        ...state,
        coursesLoading: action.loading
      }
    }

    case SET_APPLICATIONS_NOTIFICATION: {
      return {
        ...state,
        applicationNotification: true
      }
    } 

    case FETCH_JOB_CATEGORIES:

      var jobCategories = [];

      var {
        desired_occupations = [],
        past_occupations = [],
        skills_backpack_occupations = [],
        styles_occupations = [],
        interest_occupations = [],
        searched_occupations = []
      } = action.jobs || state.rawJobCategories;
      
    
      var quals = [];
      let occupationArr = [];

      let both = [];
      for (const idx in action.desiredExp) {
        if (action.desiredExp[idx]){
          both.push(action.preferredOccupations[idx])
        }
      }

      for (let i = 0; i < desired_occupations.length; i++){
        let item = desired_occupations[i];
        if (!item) continue;
        if (item.main_occupation){
           if (item.qualification_name) {
            quals = [...quals, ...item.qualification_name];
           }
        }
        
        let title = "Preferred";
        if (both.includes(item.occupation)){
          title = "Previous & Preferred";
        }

        occupationArr.push({
          ...item,
          catType: "desired",
          catTitle: title,
          occKey: "desired_occupations",
          jobsCount: 0,
        });
      }


      
      for (let i = 0;  i < past_occupations.length; i++){
        let item = past_occupations[i];
        if (!item) continue;
        if (item.main_occupation && item.qualification_name){
          quals = [...quals, ...item.qualification_name];
        }
        occupationArr.push({
          ...item,
          catType: "past",
          catTitle: "Previous",
          occKey: "past_occupations",
          jobsCount: 0,
        });
      }

      for (let i = 0; i < skills_backpack_occupations.length; i++){
        let item = skills_backpack_occupations[i];
        if (!item) continue;
        if (item.main_occupation && item.qualification_name){
          quals = [...quals, ...item.qualification_name];
        }
        occupationArr.push({
          ...item,
          catType: "skills_backpack",
          catTitle: "Skills",
          occKey: "skills_backpack_occupations",
          jobsCount: 0,
        });
      }

      for (let i = 0;  i < styles_occupations.length; i++){
        let item = styles_occupations[i];
        if (!item) continue;
        if (item.qualification_name){
          quals = [...quals, ...item.qualification_name];
        }
        occupationArr.push({
          ...item,
          catType: "styles",
          catTitle: "Styles",
          occKey: "styles_occupations",
          jobsCount: 0,
        });
      }


      for (let i = 0; i < interest_occupations.length; i++){
        let item = interest_occupations[i];
        if (!item) continue;
        if (item.qualification_name){
          quals = [...quals, ...item.qualification_name];
        }
        occupationArr.push({
          ...item,
          catType: "interest",
          catTitle: "Interest",
          occKey: "interest_occupations",
          jobsCount: 0
        });
      }


      for (let i = 0;  i < searched_occupations.length; i++){
        let item = searched_occupations[i];
        if (item.qualification_name){
          quals = [...quals, ...item.qualification_name];
        }
        occupationArr.push({
          ...item,
          catType: "searched",
          catTitle: "Searched",
          occKey: "searched_occupations",
          jobsCount: 0,
        });
      }

      jobCategories = [
        ...occupationArr
      ];
      return {
        ...state,
        jobCategories,
        rawJobCategories: action.jobs,
        allJobCategories: jobCategories,
        qualNames: [...new Set(quals)],
      };

    case UPDATE_JOB_CATEGORIES:
      return {
        ...state,
        rawJobCategories: action.jobCategories,
      };

    case FETCH_NEARME_JOBS:
      
    let jobsarr = [];
    action.jobs.forEach(element => {
      if (element.postedDate.toString().length === 10){
        element.postedDate = element.postedDate * 1000;
      }
      jobsarr.push(element);
    });

    jobsarr = _.orderBy(jobsarr, "radius", "asc");

    return {
        ...state,
        jobsNearMe: jobsarr.slice(),
      };

    case FETCH_ALL_JOBS:

      // var j = state.jobCategories || [];

      let occupationListFilter = state.occupationListFilter
      let idList = []
      var _allJobCategories = state.allJobCategories || []

      // rather doing a find each time we need to look up an occupation, create a key dict where we can just lookup the key occupationName
      let occupations = {};
      _.each(_allJobCategories, (o) => { if (!occupations[o.occupation]) occupations[o.occupation] = o;});
      // rather than doing o net scores each item we should do it each occupation item instead. 
      _.each(occupations, (o) => {
        if (o && o.ONET_styles){ 
          _.each(o.ONET_styles, (style, key) => {
            switch(key){
              case "Commitment":
                o.COMM = style;
                break;
              case "Adaptability":
                o.ADY = style;
                break;
              case "Confidence":
                o.CONF = style;
                break;
              case "Control":
                o.CONT = style;
                break;
              case "Accuracy":
                o.PSA = style;
                break;
              case "Responsiveness to feedback":
                o.RTF = style;
                break;
              case "Complexity":
                o.SPC = style;
                break;
              default:
                break;
            }
            });
          }
          if (o && o.ONET_interests){
            _.each(o.ONET_interests, (interest, key) => {
              o[key.toLowerCase()] = interest;
            });
          }
      })
      Object.keys(action.jobs).forEach((key) => {
        action.jobs[key] = action.jobs[key].map((item) => {
          item.jobId = "" + item.jobId
          if (idList.includes(item.jobId)){ // duplicate
            return false
          }
          else{
            idList.push(item.jobId)
          }
          //TODO BELONG_TO
            var f =  _.cloneDeep(occupations[item.occupation]);

            // if (key === "past_occupations"){
            //    f = occupations[item.matched_occupation];
            // }
            if (!f) { 
              return false; 
            } 
            else { 
              var findCat = false
              if (key === "desired_occupations"){
                findCat = occupations[f.parent_occupation];
                item.original_occupation = item.occupation;
                item.occupation = findCat.occupation;
                item.main_occupation = true;
                
              } else {
                findCat = occupations[item.occupation];
              }
              findCat.jobsCount++; 

            // cache isn't being overwriten by new occupation scores...
            // so we need to ensure the values are deleted first.
            
              if(item.score_bp){
                delete f.score_bp;
              }
              else{
                delete item.score_bp;
              }

              if(item.score_interest){
                delete f.score_interest;
              }
              else{
                delete item.score_interest;
              }

              if(item.score_styles){
                delete f.score_styles;
              }
              else{
                delete item.score_styles;
              }

              if(item.missing_skills || item.missing_skills){
                delete f.missing_skills;
                delete f.similar_skills;
              }
              else{
                delete item.missing_skills;
                delete item.missing_skills;
              }

              var catType = "desired";
              var catTitle = "Preferred";

              if (key === "past_occupations") {
                catType = "past";
                catTitle = "Previous";
              }
              if (key === "skills_backpack_occupations") {
                catType = "skills_backpack";
                catTitle = "Skills";
              }
              if (key === "styles_occupations") {
                catType = "styles";
                catTitle = "Styles";
              }

              if (key === "interest_occupations") {
                catType = "interest";
                catTitle = "Interest";
              }
              if (key === "searched_occupations") {
                catType = "searched";
                catTitle = "Searched";
              }
            const lower_contract = item.job_contract.toLowerCase()
            let obj = {
              ...f,
              ...item,
              catType,
              catTitle,
              postedDate: item.postedDate ? item.postedDate.toString().length > 10 ? item.postedDate : item.postedDate * 1000 : "Unknown",
              employerName:
                item.employerName === "" || item.employerName === "not_specified"
                  ? "Employer Unknown"
                  : item.employerName,
              provider_image: item.is_apprenticeship ? "apprenticeships" : item.provider_image,
              job_contract: lower_contract.includes("full") ? "Full Time" : lower_contract.includes("part") ? "Part Time" : item.job_contract
            }
            return obj;
          }
        }).filter(Boolean);
      })


      var {
        desired_occupations = [],
        past_occupations = [],
        skills_backpack_occupations = [],
        styles_occupations = [],
        interest_occupations = [],
        searched_occupations = [],
      } = action.jobs;

      let allJobs = [
        ...desired_occupations,
        ...past_occupations,
        ...skills_backpack_occupations,
        ...styles_occupations,
        ...interest_occupations,
        ...searched_occupations
      ];

      allJobs = _.orderBy(allJobs, [({ vacancy_flag }) => vacancy_flag === true, "score_bp"], ["desc", "desc"]);
      
      let jc = [];
      //convert from asso array to flat array.
      _.each(occupations, (i) => {
        jc.push(i);
      });

      for (const cat of jc) {
        // if(cat.jobsCount && cat.jobsCount > 0){
          occupationListFilter = occupationListFilter.map((filterCat) => {
            if(filterCat.catType === cat.catType && !(filterCat.subList.find((subOcc) => subOcc.name === cat.occupation))){
              filterCat.subList = [...filterCat.subList, { name: cat.occupation, value: false }]
            }

            return filterCat
          })
        // }
      }

      return {
        ...state,
        allJobCategories: jc,
        allJobs: allJobs,
        idList: idList,
        occupationListFilter: occupationListFilter
      };

    case FETCH_INDIVIDUAL_JOBS:

      let _occupationListFilter = state.occupationListFilter
      let idFilterList = state.idList || []
      var ajc = state.allJobCategories || []
      var allJobsList = state.allJobs || []
      const key = action.jobsKey

      // rather doing a find each time we need to look up an occupation, create a key dict where we can just lookup the key occupationName
      let _occupations = {};
      _.each(ajc, (o) => { if (!_occupations[o.occupation]) _occupations[o.occupation] = o});
      // rather than doing o net scores each item we should do it each occupation item instead. 
      _.each(_occupations, (o) => {
        if (o && o.ONET_styles){ 
          _.each(o.ONET_styles, (style, key) => {
            switch(key){
              case "Commitment":
                o.COMM = style;
                break;
              case "Adaptability":
                o.ADY = style;
                break;
              case "Confidence":
                o.CONF = style;
                break;
              case "Control":
                o.CONT = style;
                break;
              case "Accuracy":
                o.PSA = style;
                break;
              case "Responsiveness to feedback":
                o.RTF = style;
                break;
              case "Complexity":
                o.SPC = style;
                break;
              default:
                break;
            }
            });
          }
          if (o && o.ONET_interests){
            _.each(o.ONET_interests, (interest, key) => {
              o[key.toLowerCase()] = interest;
            });
          }
      })
      
      let newJobsList = action.jobs[key].map((item) => {
        item.jobId = "" + item.jobId
        if (idFilterList.includes(item.jobId)){ // duplicate
          //console.log("duplicate")
          // console.log(item)
          return false
        }
        else{
          idFilterList.push(item.jobId)
        }
        //TODO BELONG_TO
          var f = _.cloneDeep(_occupations[item.occupation]);

          // if (key === "past_occupations"){
          //     f = _occupations[item.matched_occupation];
          // }
          if (!f) { 
            // console.log("no f")
            // console.log(item)
            return false; } 
          
          else { 
            var findCat = false
            if (key === "desired_occupations"){
              findCat = _occupations[f.parent_occupation];
              item.original_occupation = item.occupation;
              item.occupation = findCat.occupation;
              item.main_occupation = true;
            } else {
              findCat = _occupations[item.occupation];
            }
            findCat.jobsCount++; 

          // cache isn't being overwriten by new occupation scores...
          // so we need to ensure the values are deleted first.

            if(item.score_bp){
              delete f.score_bp;
            }
            else{
              delete item.score_bp;
            }

            if(item.score_interest){
              delete f.score_interest;
            }
            else{
              delete item.score_interest;
            }

            if(item.score_styles){
              delete f.score_styles;
            }
            else{
              delete item.score_styles;
            }

            if(item.missing_skills || item.missing_skills){
              delete f.missing_skills;
              delete f.similar_skills;
            }
            else{
              delete item.missing_skills;
              delete item.missing_skills;
            }
            

            var catType = "desired";
            var catTitle = "Preferred";

            if (key === "past_occupations") {
              catType = "past";
              catTitle = "Previous";
            }
            if (key === "skills_backpack_occupations") {
              catType = "skills_backpack";
              catTitle = "Skills";
            }
            if (key === "styles_occupations") {
              catType = "styles";
              catTitle = "Styles";
            }

            if (key === "interest_occupations") {
              catType = "interest";
              catTitle = "Interest";
            }
            if (key === "searched_occupations") {
              catType = "searched";
              catTitle = "Searched";
            }

          const lower_contract = item.job_contract.toLowerCase()
          let obj = {
            ...f,
            ...item,
            catType,
            catTitle,
            postedDate: item.postedDate ? item.postedDate.toString().length > 10 ? item.postedDate : item.postedDate * 1000 : "Unknown",
            employerName:
              item.employerName === "" || item.employerName === "not_specified"
                ? "Employer Unknown"
                : item.employerName,
            job_contract: lower_contract.includes("full") ? "Full Time" : lower_contract.includes("part") ? "Part Time" : item.job_contract
          }
          
          return obj;
        }
      }).filter(Boolean);

      if(action.loadMore){
        allJobsList = allJobsList.concat(_.orderBy(newJobsList, [({ vacancy_flag }) => vacancy_flag === true, "score_bp"], ["desc", "desc"]))
      }
      else{
        allJobsList = _.orderBy(allJobsList.concat(newJobsList), [({ vacancy_flag }) => vacancy_flag === true, "score_bp"], ["desc", "desc"])
      }
      

      let newJobCategories = [];
      //convert from asso array to flat array.
      _.each(_occupations, (i) => {
        newJobCategories.push(i);
      });
      for (const cat of newJobCategories) {
        // if(cat.jobsCount && cat.jobsCount > 0){
          _occupationListFilter = _occupationListFilter.map((filterCat) => {
            if(filterCat.catType === cat.catType && !(filterCat.subList.find((subOcc) => subOcc.name === cat.occupation))){
              filterCat.subList = [...filterCat.subList, { name: cat.occupation, value: false }]
            }
            return filterCat
          })
        // }
      }

      return {
        ...state,
        allJobCategories: newJobCategories,
        allJobs: allJobsList,
        idList: idFilterList,
        loading: allJobsList.length > 0 ? false : state.loading,
        occupationListFilter: _occupationListFilter
      };

    case FETCH_ALL_JOBS_COMPLETE:
      return{
        ...state,
        fetchingJobs: action.data
      }
    case UPDATE_LOAD_MORE_COUNT:
      if (action.status){
        const oldCount = action.jobCount || []
        let newJobs = state.allJobs || []
        const diff = newJobs.length - oldCount.length
        return {
          ...state,
          loadMoreStatus: {
            snackbar: action.status,
            snackbarText: action.text ? action.text : "Loaded " + diff + " New Jobs.  "
          }
        };
      }
      else{
        return {
          ...state,
          loadMoreStatus: {
            snackbar: false,
            snackbarText: false
          }
        };
      }


    case FETCH_QUALIFICATIONS:
      const { jobs = [] } = action;
      var categories = state.allJobCategories || [];

      var ja = jobs.map((item) => {
        var found = categories.find((cat) => {
          return cat.qualification_name.includes(item.id);
        });
        if (found && found.ONET_styles){
          _.each(found.ONET_styles, (style, key) => {
            switch(key){
              case "Commitment":
                found.COMM = style;
                break;
              case "Adaptability":
                found.ADY = style;
                break;
              case "Confidence":
                found.CONF = style;
                break;
              case "Control":
                found.CONT = style;
                break;
              case "Accuracy":
                found.PSA = style;
                break;
              case "Responsiveness to feedback":
                found.RTF = style;
                break;
              case "Complexity":
                found.SPC = style;
                break;
              default:
                break;
            }
            });
          }
          if (found && found.ONET_interests){
            _.each(found.ONET_interests, (interest, key) => {
              found[key.toLowerCase()] = interest;
            });
          }


        let obj = { ...item, jobId: item.qual_id, ...found, isQual: true };
        return obj;
        
      });

      //ja = ja.filter((item) => item.qual_centre_flag === false);

      var levelObject = {};
      ja.forEach((element) => {
        levelObject[element.qual_level] = true;
      });
      var levels = Object.keys(levelObject);
      
      return {
        ...state,
        loadingQualifications: action.loading,
        allQualifications: ja,
        levelFilters: levels,
      };
    case ADD_FILTER:
      // const { occupation = "", isRemove = false, userOccs } = action;
      // var { occupationFilter = [],  } = state;

      // const ao = state.jobCategories || [];
      // const aj = state.allJobs || [];
      // const aq = state.allQualifications || [];
      // const ar = state.allResourcesPerOccupationFlat || [];
      // const ac = state.allCoursesPerOccupationFlat || [];
      // var filteredJobs = state.allJobs || [];
      // var filteredQuals = state.allQualifications || [];
      // let filteredResources = state.allResourcesPerOccupationFlat || [];
      // let filteredCourse = state.allCoursesPerOccupationFlat || [];

      // //not needed in new filter
      // var x = state.occupationListFilter.map((item) => {
      //   if (item.value) {
      //     return item.name;
      //   } else {
      //     return null;
      //   }
      // });

      // //PRE FILTER PER OCCUPATION CATEGORIES LIKE PREFERRED OR SKILLS
      // filteredJobs = filteredJobs.filter((item) => x.includes(item.catTitle));

      // var qnames = [];
      // state.jobCategories.forEach((item) => {
      //   qnames = [...qnames, ...item.qualification_name];
      // });

      // filteredQuals = aq.filter(({ id }) => qnames.includes(id));

      // if (occupation) {
      //   if (!isRemove) {
      //     occupationFilter.push(occupation);
      //   } else {
      //     occupationFilter = occupationFilter.filter(
      //       (item) => item !== occupation
      //     );
      //   }
      // }
      // occupationFilter = [...new Set(occupationFilter)];
      // //filtering jobs and quals

      // if (occupationFilter.length) {
      //   filteredJobs = aj.filter(({ occupation, matched_occupation}) => {
      //     return occupationFilter.includes(occupation) || occupationFilter.includes(matched_occupation);
      //   })
          

      //   const filteredOccs = ao.filter((item) =>
      //     occupationFilter.includes(item.occupation)
      //   );

        
      //   filteredResources = ar.filter((item) =>
      //     occupationFilter.includes(item.occupation)
      //   );


      //   filteredCourse = ac.filter((item) => 
      //     occupationFilter.includes(item.occupation)
      //   );

      //   var qnames = [];
      //   filteredOccs.forEach((item) => {
      //     qnames = [...qnames, ...item.qualification_name];
      //   });
      //   qnames = [...new Set(qnames)];
      //   filteredQuals = aq.filter(({ id }) => qnames.includes(id));
      // }

      // var levelObject = {};
      // filteredQuals.forEach((element) => {
      //   levelObject[element.qual_level] = true;
      // });
      // var levels = Object.keys(levelObject);
      // const filterAlts = []
      // if(userOccs){
      //   // console.log(userOccs)
      //   for (const occ of occupationFilter) {
      //     if(userOccs.occupations.includes(occ.toLowerCase())){
      //       filterAlts.push(userOccs.occupationsOriginal[userOccs.occupations.findIndex((item) => occ.toLowerCase() === item)])
      //     }
      //     if(userOccs.desiredOccupations.includes(occ.toLowerCase())){
      //       filterAlts.push(userOccs.desiredOccupationsOriginal[userOccs.desiredOccupations.findIndex((item) => occ.toLowerCase() === item)])
      //     }
      //   }
      // }
      // let occupationFilterWithAlts = [...occupationFilter, ...filterAlts]
      // // console.log(occupationFilterWithAlts)
      // // console.log(filteredJobs)
      // return {
      //   ...state,
      //   occupationFilter,
      //   occupationFilterWithAlts: occupationFilterWithAlts,
      //   recommendedJobs: filteredJobs,
      //   qualifications: filteredQuals,
      //   resourcesPerOccupationFlat: filteredResources,
      //   coursesPerOccupationFlat: filteredCourse,
      //   levelFilters: levels,
      // };
      return {
        ...state
      }
    case SET_SELECTED_JOB:
      return { ...state, selectedJobId: action.jobId };
    case SET_FAVOURITE_JOB: {
      const { favourited, job } = action.payload;
      if (favourited) {
        return {
          ...state,
          favourited: [] // Need a flag to sort
            .concat(state.favourited)
            .concat(job)
            .sort((a, b) => b.savedTimestamp - a.savedTimestamp),
        };
      } else {
        return {
          ...state,
          favourited: [] // Need a flag to sort
            .concat(state.favourited)
            .filter((favouritedJob) => favouritedJob.jobId !== job.jobId)
            .sort((a, b) => b.savedTimestamp - a.savedTimestamp),
        };
      }
    }
    case FETCH_FAVOURITED_JOBS: {
      let invited = action.invited.map((item) => {
        item.isInvited = true
        return item
      })
      let favourited = action.favourited.concat(invited)
      return {
        ...state,
        favourited: favourited.sort(
          (a, b) => b.savedTimestamp - a.savedTimestamp
        ),
      };
    }

    case MARK_ITEM_READ: {
      let itemSort = action.itemSort
      if (itemSort.includes("invited") || itemSort.includes("saved")){
        let favourited = state.favourited.map((item) => {
          if (itemSort === item.itemSort){
            item.read = true
          }
          return item
        })
        return {
          ...state,
          favourited: favourited
        };
      }
      else{
        let appliedJobs =  state.appliedJobs.map((item) => {
          if (itemSort === item.itemSort){
            item.read = true
          }
          return item
        })
        return {
          ...state,
          appliedJobs: appliedJobs
        };
      }
    }

    case APPLY_FOR_A_JOB: {


      let found = false;
      _.each(state.appliedJobs, (applied) => {
        if (applied.jobId === action.job.jobId){
          found = true;
        }
      });
      if (!found){

        let appliedJobs = [] // Need a flag to sort
        .concat(state.appliedJobs, action.job)

        if (!action.updatePosition){
          appliedJobs = appliedJobs.sort((a, b) => b.savedTimestamp - a.savedTimestamp);
        }
        
        return {
          ...state,
          appliedJobs: appliedJobs
        };
      } else {
        return {
          ...state
        }
      }
    }
    case APPLY_FOR_A_JOB_UPDATE: {
      let appliedJobs = _.map(state.appliedJobs, (_job) => {
        if (_job.jobId === action.job.jobId){
          if (action.deleteJob){
            return false
          }
          else{
            return action.job;
          }
        } else {
          return _job;
        }
      }).filter(Boolean);

      return {
        ...state,
        appliedJobs: appliedJobs.sort((a, b) => b.savedTimestamp - a.savedTimestamp),
      };
    }
    case FETCH_CENTRES: {
      if (action.jobs) {
        return {
          ...state,
          centres: action.jobs,
          centreLoading: action.loading,
        };
      }

      return {
        ...state,
        centreLoading: action.loading,
      };
    }
    case SET_ACTIVE_RESOURCE: {
      return {
        ...state,
        activeResource: action.resource
      }
    }
    case SET_RESOURCES: {
      let occ = {};
      _.each(action.data, (index, key) => {
        if (index){
          const cat = action.categoryKey[index.occupation]
          occ[index.occupation] = index.video_suggestions.map((item) => {return {...item,category: cat}});
        }
      });

      let occFlat = [];
      _.each(occ, (index, key) => {
          index = _.map(index, (ii) => {
            ii.occupation = key;
            return ii;
          })
          occFlat = [...index, ...occFlat];
      });
      let allResourcesFlat = [...occFlat];
      let allResources = {...occ};

      occFlat = occFlat.sort((a, b) => action.orderDict.indexOf(a.occupation) - action.orderDict.indexOf(b.occupation));
      allResourcesFlat = allResourcesFlat.sort((a, b) => action.orderDict.indexOf(a.occupation) - action.orderDict.indexOf(b.occupation));
      return {
        ...state,
        resourcesPerOccupation: occ,
        resourcesPerOccupationFlat: occFlat,
        allResourcesPerOccupation: allResources,
        allResourcesPerOccupationFlat: allResourcesFlat
      }
    }

    case SET_ONLINE_COURSES: {
      let occ = {};
      _.each(action.data, (index, key) => {
        if (index){
          const cat = action.categoryKey[index.occupation]
          index.course_list.tier_one_category = index.tier_one_category;
          occ[index.occupation] = index.course_list.map((item) => {return {...item,category: cat}});
        }
      });


      let occFlat = [];
      _.each(occ, (index, key) => {
          index = _.map(index, (ii) => {
            ii.occupation = key;
            ii.tier_one_category = index.tier_one_category;

            return ii;
          })
          occFlat = [...index, ...occFlat];
      });

      let allCoursesFlat = [...occFlat];
      let allCourses = {...occ};

      occFlat = occFlat.sort((a, b) => action.orderDict.indexOf(a.occupation) - action.orderDict.indexOf(b.occupation));
      allCoursesFlat = allCoursesFlat.sort((a, b) => action.orderDict.indexOf(a.occupation) - action.orderDict.indexOf(b.occupation));

      return {
        ...state,
        coursesPerOccupation: occ,
        coursesPerOccupationFlat: occFlat,
        allCoursesPerOccupation: allCourses,
        allCoursesPerOccupationFlat: allCoursesFlat
      }
    }


    case FETCH_APPLIED_JOBS: {
      return {
        ...state,
        appliedJobs: action.jobs.sort(
          (a, b) => b.savedTimestamp - a.savedTimestamp
        ),
      };
    }

    case LEVEL_FILTER: {
      // console.log("LEVEL_FILTER")
      var levelFilters = state.levelFilters;
      var qualifications = state.qualifications;

      levelFilters = levelFilters.filter((item) => item !== action.level);

      qualifications = qualifications.filter(({ qual_level }) =>
        levelFilters.includes(qual_level)
      );

      return {
        ...state,
        qualifications,
        levelFilters,
      };
    }
    case UPDATE_OCCUPATION_SKILL: {
      

      _.each(state.recommendedJobs, (job) => {
        if (job.occupation === action.job.occupation){
          job.similar_skills = action.job.similar_skills;
          job.missing_skills = action.job.missing_skills;
        }
      })

      _.each(state.qualifications, (job) => {
        if (job.occupation === action.job.occupation){
          job.similar_skills = action.job.similar_skills;
          job.missing_skills = action.job.missing_skills;
        }
      });

      _.each(state.favourited, (job) => {
        if (job.occupation === action.job.occupation){
          job.similar_skills = action.job.similar_skills;
          job.missing_skills = action.job.missing_skills;
        }
      })

      _.each(state.appliedJobs, (job) => {
        if (job.occupation === action.job.occupation){
          job.similar_skills = action.job.similar_skills;
          job.missing_skills = action.job.missing_skills;
        }
      })

      _.each(state.jobsNearMe, (job) => {
        if (job.occupation === action.job.occupation){
          job.similar_skills = action.job.similar_skills;
          job.missing_skills = action.job.missing_skills;
        }
      })

      // state.allJobCategories[indexFound].missing_skills = action.job.missing_skills;

      return {
        ...state
      }
    }

    case UPDATE_FULL_DESCRIPTION: {
      let desc = action.description;
      let id = action.id;
      let newAllJobs =  state.allJobs
      let newRecommendedJobs = state.recommendedJobs
      return {
        ...state,
        recommendedJobs: newRecommendedJobs.map((item) => {
          if(item.jobId === id){
            return {...item, jobDescriptionFull: desc}
          }
          else{
            return item
          }
        }),
        allJobs: newAllJobs.map((item) => {
          if(item.jobId === id){
            return {...item, jobDescriptionFull: desc}
          }
          else{
            return item
          }
        }),
      }
    }
    case OPTIONAL_SKILL_CHECK: {
      let values = action.values;
      
      return {
        ...state,
        optional_skill_check: values
      }
    }

    case LOADED_CONTENT_DASHBOARD: {

      return {
        ...state,
        loadedContentDashboard: true
      }
    }
    case BLACKLIST_OCC:
      const _occupation = action.occupation
      const _isRemove = action.isRemove
      let newOccupationBlacklist = state.occupationBlacklist;
      let allJobCategories = state.allJobCategories;

      if (!newOccupationBlacklist){
        newOccupationBlacklist = []
      }
      if(_isRemove){
        newOccupationBlacklist = newOccupationBlacklist.filter((item) => item != _occupation)
      }
      else{
        newOccupationBlacklist.push(_occupation)
        allJobCategories = allJobCategories.filter((item) => !(item.catType === "searched" && item.parent_occupation === _occupation))
      }
      return {
        ...state,
        occupationBlacklist: newOccupationBlacklist,
        allJobCategories: allJobCategories
      };
    case SET_BLACKLIST:
      const newBlackList = action.newBlackList || []
      return {
        ...state,
        occupationBlacklist: newBlackList ? newBlackList : []
      };
    case JOBS_FILTER: {
      const { 
        useRecommended,
        occupationCategoryFilterChange,
        occupation = "", 
        isRemove = false, 
        userOccs, 
        occupationFilterChange= false, 
        occupationFilterReset=false 
      } = action;

      //this is category
      let occupationListFilter = action.occupationListFilter ? action.occupationListFilter : state.occupationListFilter   
      
      let sortBy = action.sortBy ? action.sortBy : state.sortBy 
      
      let distanceSelect = action.distanceSelect ? action.distanceSelect : state.distanceSelect
      let contractFilters = action.contractFilters ? action.contractFilters : state.contractFilters
      let datePosted = action.datePosted ? action.datePosted : state.datePosted
      let stringSearch = action.stringSearch !== undefined ? action.stringSearch.toLowerCase() : state.stringSearch.toLowerCase()
      let qualLevelSelect = action.qualLevelSelect ? action.qualLevelSelect : state.qualLevelSelect
      let salarySelect = action.salary ? action.salary : state.salary

      let jobCategories = state.allJobCategories;
      let occupationBlacklist = state.occupationBlacklist || [];
      let allJobs = state.allJobs || [];
      let recommendedJobs = useRecommended ? state.recommendedJobs || [] : allJobs
      let appliedJobs = useRecommended ? state.appliedJobsFiltered || [] : state.appliedJobs || [] 
      let favourited = useRecommended ? state.favouritedFiltered || [] : state.favourited || []
      var qualifications = useRecommended ? state.qualifications : state.allQualifications || [];
      let { occupationFilter = [], occupationFilterWithAlts = {low: [],alt: [],newAlt: [],meta: []}, allResourcesPerOccupationFlat = [], allCoursesPerOccupationFlat = []} = state;

      // console.log(occupation)

      if(occupationFilterChange){
        if(occupationFilterReset){
          occupationFilter = []
          occupationFilterWithAlts = {
            low: [],
            alt: [],
            newAlt: [],
            meta: []
          }
          occupationListFilter = occupationListFilter.map((item) => {
            return {
              ...item, 
              subList: item.subList ? item.subList.map((_item) => {return {..._item, value:false}}) : false
            }
          })
        }
        if (occupation) {
          if (!isRemove && !occupationBlacklist.includes(occupation)) {
            occupationFilter.push(occupation);
            occupationListFilter = occupationListFilter.map((item) => {
              return {...item, subList: item.subList ? item.subList.map((_item) => {
                return _item.name === occupation ? {..._item, value:true} : _item
              }) : false}
            })
          } else {
            occupationFilter = occupationFilter.filter(
              (item) => item !== occupation
            );
            occupationListFilter = occupationListFilter.map((item) => {
              return {...item, subList: item.subList ? item.subList.map((_item) => {
                return _item.name === occupation ? {..._item, value:false} : _item
              }): false}
            })
          }
          occupationFilter = [...new Set(occupationFilter)];
          if(userOccs){
            for (const occ of occupationFilter) {
              occupationFilterWithAlts.low.push(occ)
              for (const jobCat of jobCategories) {
                if(jobCat.occupation === occ.toLowerCase()){
                  if(jobCat.alt_labels){
                    occupationFilterWithAlts.alt = [...occupationFilterWithAlts.alt, ...jobCat.alt_labels]
                  }
                  if(jobCat.new_alt_labels){
                    occupationFilterWithAlts.newAlt = [...occupationFilterWithAlts.newAlt, ...jobCat.new_alt_labels]
                  }
                  if(jobCat.meta_labels){
                    occupationFilterWithAlts.meta = [...occupationFilterWithAlts.meta, ...jobCat.meta_labels]
                  }
                }
              }              
            }
          }
        }
        //if we have occ filter try use relevance, else skills
        if(occupationFilter.length){
          sortBy = sortBy.map((item) => item.name === "Relevance" ? {...item, value:true} : {...item, value:false})
        }
        else{
          sortBy = sortBy.map((item) => item.name === "Skills" ? {...item, value:true} : {...item, value:false})
        }       
      }

      let sortByItem = sortBy.find((item) => item.value)
      let occupationCategoryFilterList = occupationListFilter ? occupationListFilter.filter((item) => item.value).map((item) => item.name) : []

      if (occupationCategoryFilterList.includes("Previous") || occupationCategoryFilterList.includes("Preferred")){
        occupationCategoryFilterList.push("Previous & Preferred")
      }
      jobCategories = jobCategories.filter((item) => occupationCategoryFilterList.includes(item.catTitle) && !occupationBlacklist.includes(item.occupation));
      
      //For changes in occ cat && for sorting quals
      if(occupationCategoryFilterList && (occupationCategoryFilterChange || occupationFilterChange)){

        if(occupationFilter.length){
          allResourcesPerOccupationFlat = allResourcesPerOccupationFlat.filter((item) =>
            occupationFilter.includes(item.occupation) && !occupationBlacklist.includes(item.occupation)
          );
          allCoursesPerOccupationFlat = allCoursesPerOccupationFlat.filter((item) => 
            occupationFilter.includes(item.occupation) && !occupationBlacklist.includes(item.occupation)
          );
        }
        else{
          allResourcesPerOccupationFlat = allResourcesPerOccupationFlat.filter((item) =>
            occupationCategoryFilterList.includes(item.category) && !occupationBlacklist.includes(item.occupation)
          )
          allCoursesPerOccupationFlat = allCoursesPerOccupationFlat.filter((item) => 
            occupationCategoryFilterList.includes(item.category) && !occupationBlacklist.includes(item.occupation)
          );
        }
      }
      //Filtering from all jobs
      if(!useRecommended){
        const currentDistance = distanceSelect.find((item) => item.value)
        const maxDistance = currentDistance ? parseInt(currentDistance.name.replace(/[^0-9]/g, "")) : false
        const contractList = contractFilters ? contractFilters.filter((item) => item.value).map((item) => item.name) : false
        const activeDatePosted  = datePosted ? datePosted.find((item) => item.value) : false
        const maxDate = activeDatePosted && activeDatePosted.filterValue > 0 ? moment().subtract(activeDatePosted.filterValue, "days").startOf("day").format("x") : false
        const salary = salarySelect.find((item) => item.value);
        recommendedJobs = recommendedJobs.filter((job) => {
          if(occupationCategoryFilterList && !occupationCategoryFilterList.includes(job.catTitle)){
            return false
          }
          if(occupationFilter.length && !(occupationFilter.includes(job.occupation) || occupationFilter.includes(job.matched_occupation))){
            return false
          }
          if(occupationBlacklist.includes(job.occupation)){
            return false
          }
          if(maxDistance && !(job.distance_in_miles < maxDistance)){
            return false
          }
          if(contractList){
            if (job.is_apprenticeship && !contractList.includes("Apprenticeships")){
              return false
            }
            else if(job.original_location === "remote" && !contractList.includes("Remote Jobs")){
              return false
            }
            else if (job.job_contract && job.job_contract !=="unknown" && !contractList.includes(job.job_contract + " Jobs")){
              return false
            }
          }
          if(maxDate && job.postedDate && !(job.postedDate > maxDate)){
            return false
          }
          if(stringSearch && !((job.jobTitle && job.jobTitle.toLowerCase().includes(stringSearch)) || (job.qual_name && job.qual_name.toLowerCase().includes(stringSearch)) || (job.original_location && job.original_location.toLowerCase().includes(stringSearch)) || (job.provider && job.provider.toLowerCase().includes(stringSearch)) || (job.employerName && job.employerName.toLowerCase().includes(stringSearch)))){
            return false
          }
          if(salary && salary.filterValue && (job.annual_salary_max || job.annual_salary_min)){
            if(job.annual_salary_min){
              if(job.salary_type && !["unknown", "yearly", "annually", "annual"].includes(job.salary_type)){
                if(["hourly", "daily"].includes(job.salary_type)){
                  if((job.annual_salary_min * 36.4 * 52) < salary.filterValue){
                    return false
                  }
                }
              }
              else if(job.annual_salary_min < salary.filterValue){
                return false
              }
            }
            else{
              if(job.salary_type && !["unknown", "yearly", "annually", "annual"].includes(job.salary_type)){
                if(["hourly", "daily"].includes(job.salary_type)){
                  if((job.annual_salary_max * 36.4 * 52) < salary.filterValue){
                    return false
                  }
                }
              }
              else if(job.annual_salary_max < salary.filterValue){
                return false
              }
            }
          }


          return job
        })

        appliedJobs = appliedJobs.filter((job) => {
          if(stringSearch && !((job.jobTitle && job.jobTitle.toLowerCase().includes(stringSearch)) || (job.qual_name && job.qual_name.toLowerCase().includes(stringSearch)) || (job.original_location && job.original_location.toLowerCase().includes(stringSearch)))){
            return false
          }
          return job
        })

        favourited = favourited.filter((job) => {
          if(stringSearch && !((job.jobTitle && job.jobTitle.toLowerCase().includes(stringSearch)) || (job.qual_name && job.qual_name.toLowerCase().includes(stringSearch)) || (job.original_location && job.original_location.toLowerCase().includes(stringSearch)))){
            return false
          }
          return job
        })

        const qualLevelList = qualLevelSelect ? qualLevelSelect.filter((item) => item.value).map((item) => item.name) : false
        //filtered job cats from occupationCategoryFilterList
        let filteredJobCategories = occupationFilter.length ? jobCategories.filter((item) => occupationFilter.includes(item.occupation)) : jobCategories;

        //qual filtering and level populating 
        var qnames = [];
          filteredJobCategories.forEach((item) => {
            qnames = [...qnames, ...item.qualification_name];
          });
        qnames = [...new Set(qnames)];
        // console.log(qualifications.length)
        qualifications = qualifications.filter(({ id, qual_name, qual_level }) => {
          if(!qnames.includes(id)){
            return false
          }
          if(stringSearch && !((qual_name && qual_name.toLowerCase().includes(stringSearch)))){
            return false
          }
          if(qualLevelList && !(qualLevelList.includes(qual_level))){
            return false
          }
          return true
        });
      }
      if(sortByItem){
        if(sortByItem.name === "Relevance"){
          function ranker(jobsList) {
            return jobsList.map((item) => {
              const lowerJob = item.qual_name ? item.qual_name.toLowerCase() : item.jobTitle.toLowerCase()
              let rank = 6;
              if(occupationFilterWithAlts.low.includes(lowerJob)){
                rank = 0;
              }
              else if(occupationFilterWithAlts.low.find((filterItem) => lowerJob.includes(filterItem))){
                rank = 1;
              }
              else if(occupationFilterWithAlts.alt.includes(lowerJob)){
                rank = 2;
              }
              else if(occupationFilterWithAlts.alt.find((filterItem) => lowerJob.includes(filterItem))){
                rank = 3;
              }
              else if(occupationFilterWithAlts.newAlt.find((filterItem) => lowerJob.includes(filterItem))){
                rank = 4;
              }
              else if(occupationFilterWithAlts.meta.find((filterItem) => lowerJob.includes(filterItem))){
                rank = 5;
              }
              return {...item, rank: rank}
            })
          }
          recommendedJobs = _.orderBy(ranker(recommendedJobs), ["rank"], ["asc"]);
          appliedJobs = _.orderBy(ranker(appliedJobs), ["rank"], ["asc"]);
          favourited = _.orderBy(ranker(favourited), ["rank"], ["asc"]);
          qualifications = _.orderBy(ranker(qualifications), ["rank"], ["asc"]);
        }
        else if(sortByItem.name === "Skills"){
          recommendedJobs = _.orderBy(recommendedJobs, "score_bp", "desc")
          appliedJobs = _.orderBy(appliedJobs, "score_bp", "desc")
          favourited = _.orderBy(favourited, "score_bp", "desc")
          qualifications = _.orderBy(qualifications, "score_bp", "desc")
        }
        else if(sortByItem.name === "Style"){
          recommendedJobs = _.orderBy(recommendedJobs, "score_styles", "desc")
          appliedJobs = _.orderBy(appliedJobs, "score_styles", "desc")
          favourited = _.orderBy(favourited, "score_styles", "desc")
          qualifications = _.orderBy(qualifications, "score_styles", "desc")
        }
        else if(sortByItem.name === "Interests"){
          recommendedJobs = _.orderBy(recommendedJobs, "score_interest", "desc")
          appliedJobs = _.orderBy(appliedJobs, "score_interest", "desc")
          favourited = _.orderBy(favourited, "score_interest", "desc")
          qualifications = _.orderBy(qualifications, "score_interest", "desc")
        }
      }
      recommendedJobs = _.orderBy(recommendedJobs, "vacancy_flag", ["asc"]);

      if(occupationCategoryFilterChange || occupationFilterChange){
        return {
            ...state,
          resourcesPerOccupationFlat: allResourcesPerOccupationFlat,
          coursesPerOccupationFlat: allCoursesPerOccupationFlat,
          occupationListFilter: occupationListFilter,
          occupationFilter,
          occupationFilterWithAlts: occupationFilterWithAlts,
          allJobCategories: jobCategories,
          recommendedJobs,
          qualifications,
          sortBy: sortBy,
          distanceSelect: distanceSelect,
          contractFilters: contractFilters,
          datePosted: datePosted,
          stringSearch: stringSearch,
          appliedJobsFiltered: appliedJobs,
          favouritedFiltered: favourited,
          qualLevelSelect,
          salary: salarySelect
        };        
      }
      else{
        return {
          ...state,
          recommendedJobs,
          sortBy: sortBy,
          distanceSelect: distanceSelect,
          contractFilters: contractFilters,
          datePosted: datePosted,
          stringSearch: stringSearch,
          appliedJobsFiltered: appliedJobs,
          favouritedFiltered: favourited,
          qualifications
        };
      }
    }
    case OCC_FILTER: {
      // console.log("OCC_FILTER")
      var jobCategories = state.allJobCategories;
      var recommendedJobs = state.allJobs || [];
      var qualifications = state.allQualifications || [];

      var filters = action.filter;
      var x = action.filter.map((item) => {
        if (item.value) {
          return item.name;
        } else {
          return null;
        }
      });

      let previousAndPreferred = false;
      _.each(x, (i) => {
        if (i === "Previous" && !previousAndPreferred){
          x.push("Previous & Preferred");
          previousAndPreferred = true;
        }
        if (i === "Preferred" && !previousAndPreferred){
          x.push("Previous & Preferred");
          previousAndPreferred = true;
        }
      });


      if (!x.find((element) => element !== null)) {
        x = filters.map((item) => {
          return item.name;
        });

        filters = action.filter.map((item) => {
          item.value = true;
          return item;
        });
      }

      jobCategories = jobCategories.filter((item) => x.includes(item.catTitle));
      recommendedJobs = recommendedJobs.filter((item) =>
        x.includes(item.catTitle)
      );

      var qnames = [];
      jobCategories.forEach((item) => {
        qnames = [...qnames, ...item.qualification_name];
      });

      qualifications = qualifications.filter(({ id }) => qnames.includes(id));

      var levelObject = {};
      qualifications.forEach((element) => {
        levelObject[element.qual_level] = true;
      });
      var levels = Object.keys(levelObject);

      return {
        ...state,
        occupationListFilter: filters,
        occupationFilter: [],
        jobCategories,
        recommendedJobs,
        qualifications,
        levelFilters: levels,
      };
    }

    default:
      return {
        ...state,
        occupationListFilter: state.occupationListFilter ? state.occupationListFilter : [
          { name: "Preferred", catType: "desired", value: true, subList: [] },
          { name: "Previous", catType: "past", value: true, subList: [] },
          { name: "Skills", catType: "skills_backpack", value: true, subList: [] },
          { name: "Styles", catType: "styles", value: true, subList: [] },
          { name: "Interest", catType: "interest", value: true, subList: [] },
          { name: "Searched", catType: "searched", value: true, subList: [] },
        ],
        sortBy: [
          { name: "Relevance", value: true},
          { name: "Skills", value: false },
          { name: "Style", value: false },
          { name: "Interests", value: false }
        ],
        distanceSelect: [
          { name: "Up to 10 miles", value: false },
          { name: "Up to 30 miles", value: false },
          { name: "Up to 50 miles", value: false },
          { name: "No limit", value: true }
        ],
        contractFilters: [
          { name: "Full Time Jobs", value: true},
          { name: "Part Time Jobs",value: true},
          { name: "Apprenticeships", value: true },
          // {name: "Remote Jobs", value: true}
        ],
        salary : [
          { name: "All Salaries", value: true, filterValue: 0},
          { name: "£20k +", value: false, filterValue: 20000},
          { name: "£30k +", value: false, filterValue: 30000},
          { name: "£40k +", value: false, filterValue: 40000},
          { name: "£50k +", value: false, filterValue: 50000},
          { name: "£60k +", value: false, filterValue: 60000},
          { name: "£70k +", value: false, filterValue: 70000},
        ],
        datePosted: [
          { name: "Last 24 hours", value: false, filterValue:1 },
          { name: "Last 7 days", value: false, filterValue:7 },
          { name: "Last 14 days", value: false, filterValue:14 },
          { name: "All time", value: true, filterValue:0 }
        ],
        stringSearch: "",
        qualLevelSelect: [
          { name: "Level 1/2", value: true},
          { name: "Level 1", value: true},
          { name: "Level 2", value: true},
          { name: "Level 3", value: true},
          { name: "Level 4", value: true},
          { name: "Level 5", value: true},
        ],
      };
  }
};
