let config;

if (process.env.REACT_APP_REGION === "eu-west-1") {
  //IRELAND REGION
  config = {
    AWS_REGION: "eu-west-1",
    AWS_COGNITO_IDENTITY_POOL_ID:
      "eu-west-1:8bccb92c-292d-4cbb-91cb-6d44298f4039",
    AWS_COGNITO_USER_POOL_ID: "eu-west-1_koTdi2ZaJ",
    AWS_COGNITO_CLIENT_ID: "124d3svghej0hc92jgjh5mlfji",
    AWS_API_APIKEY: "QXqWyKDZi87BsAAAmz0q63etiwgikrrs8wP3AOHg",
    API_ENDPOINT: "https://app-api-ie.skillzminer.io/pwa-v1",
    API_NAME: "APP-API",
    STORAGE_BUCKET: "pwa-storage-prod-ireland",
    DEFAULT_SESSIONCHECK_TIME: 30 * 60000, //ms = 30min
    AWS_PINPOINT_APPID: "349853e7629347f495f4244640d28f45",
    AWS_PINPOINT_REGION: "eu-west-1",
    STORAGE_CLOUDFRONT_URL : "https://db444pytybfjs.cloudfront.net",
    aws_appsync_graphqlEndpoint:
      "https://2nxefqcr5ffqta2fkqrgqxuxza.appsync-api.eu-west-1.amazonaws.com/graphql",
    aws_appsync_region: "eu-west-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    distanceName: "km",
    ga_uuid: "UA-194449745-2",//
    tinymceKey:"bsd0s4jajj9donw55xgu0ft2l9d7bhlhcshgibxnpmxzewee"
  };
} else {
  config = {
    AWS_REGION: "eu-west-2",
    AWS_COGNITO_IDENTITY_POOL_ID:
      "eu-west-2:393263a8-b48a-4dac-b886-76e835afa1f6",
    AWS_COGNITO_USER_POOL_ID: "eu-west-2_OCQsJ92H1",
    AWS_COGNITO_CLIENT_ID: "29mjchir2hhrtgdqoa59g4eskk",
    AWS_API_APIKEY: "gZd0sZ5Nha6gaYEYZBoa43QrYUdHMFpeECCqOEb4",
    API_ENDPOINT: "https://app-api.skillzminer.io/pwa-v1",
    API_NAME: "APP-API",
    STORAGE_BUCKET: "pwa-storage-prod",
    DEFAULT_SESSIONCHECK_TIME: 30 * 60000, //ms = 30min
    AWS_PINPOINT_APPID: "0eaa96b6a4854e8f8f81f24d592b97de",
    AWS_PINPOINT_REGION: "eu-west-1",
    STORAGE_CLOUDFRONT_URL : "https://db444pytybfjs.cloudfront.net",
    aws_appsync_graphqlEndpoint:
      "https://63icgnmx7fgrzl3bplnatxna4a.appsync-api.eu-west-2.amazonaws.com/graphql",
    aws_appsync_region: "eu-west-2",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    distanceName: "miles",
    ga_uuid: "UA-194449745-2",//
    tinymceKey:"bsd0s4jajj9donw55xgu0ft2l9d7bhlhcshgibxnpmxzewee"
  };
}

export default config;
