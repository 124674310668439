import React, {useState, useEffect, useRef} from "react";
import {makeStyles, Typography, Grid, Button, OutlinedInput, MenuItem, Select, Dialog, Paper, DialogContent, IconButton, Box
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import moment from "moment";
import { ReactComponent as SmLogo } from "../Common/Logo/smLogo.svg";


const useStyles = makeStyles((theme) => ({
    logocont: {
        flexGrow: 1,
    },
    logo: {
        maxWidth: 130,
        maxHeight: 30,
        marginTop: 2,
        marginRight: 20,
        flexGrow: 1,
        [theme.breakpoints.down("sm")]: {
          maxHeight: 30,
          maxWidth: 86,
          marginRight: 10,
        },
        [theme.breakpoints.down("xs")]: {
          maxHeight: 28,
          maxWidth: 58,
          marginRight: 2,
        },
      },
}));

export default function PurchaseComplete({open, stripeOrders, onClose}){
    let classes = useStyles();
    // console.log("stripeOrders")
    // console.log(open)
    // console.log(stripeOrders)
    if(open && stripeOrders && stripeOrders.length > 0){
        return (
            <Dialog 
                onClose={() => onClose()}
                open={open}
            >
                <Paper elevation={3} style={{padding: '20px', width:"100%", height: "100%"}} >
                    <DialogContent style={{width:"100%", height: "100%", overflow:"hidden"}}>
                        <Grid spacing={1} container direction="column" justifyContent="flex-start" alignItems="flex-start" style={{width:"100%", height: "100%", flexWrap: "nowrap"}}>
                            <Grid item style={{width:"100%"}}>
                                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start" style={{width: "100%", justifyContent:"flex-end"}}>
                                    <Grid item className={classes.logocont}>
                                        <SmLogo className={classes.logo} />
                                    </Grid>
                                    <Grid item>
                                        <IconButton 
                                            onClick={() => onClose()}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" style={{width: "100%", justifyContent:"center"}}>
                                <Grid item>
                                    <CheckCircleIcon fontSize="large" style={{color:"#1dcf4c"}}/>
                                </Grid>

                                <Grid item>
                                    <Typography>
                                        {stripeOrders[0].invoiceType === "subscription_create" ? "Subscription Created" :  stripeOrders[0].invoiceType === "subscription_cycle" ?  "Subscription Coins Added" : "Purchase Complete"}
                                    </Typography>
                                </Grid>
                                {stripeOrders.map((item) => {
                                    if(item.invoiceType === "subscription_create"){
                                        return (
                                            <>
                                                <Grid item>
                                                    <Grid spacing={1} container direction="column" justifyContent="center" alignItems="flex-start">
                                                        <Grid item>
                                                            <Typography>
                                                                {"Payment Reference : " + item.id}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography>
                                                                {item.value + " Coins have been added to your account"}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )
                                    }
                                    else if(item.invoiceType === "subscription_cycle"){
                                        return (
                                            <>
                                                <Grid item>
                                                    <Grid spacing={1} container direction="column" justifyContent="center" alignItems="flex-start">
                                                        <Grid item>
                                                            <Typography>
                                                                {"Payment Reference : " + item.id}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography>
                                                                {"Your monthly " + item.value + " Coins have been added to your account"}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )
                                    }
                                    else{
                                        return (
                                            <>
                                                <Grid item>
                                                    <Grid spacing={1} container direction="column" justifyContent="center" alignItems="flex-start">
                                                        <Grid item>
                                                            <Typography>
                                                                {"Payment Reference : " + item.id}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography>
                                                                {item.value + " Coins have been added to your account"}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )
                                    }
                                })}
                                {stripeOrders[0].invoiceType === "subscription_create" && (
                                    <Grid item>
                                        <Typography>
                                            {"This subscription will be charged montly.  You can change/cancel your subscription or manage your payment details via the 'Manage Billing' option in the top right menu toolbar."}
                                        </Typography>
                                    </Grid>
                                )}
                                {stripeOrders[0].invoiceType === "subscription_cycle" && (
                                    <Grid item>
                                        <Typography>
                                            {"To updated your change your subscription, you can manage your payment details via the 'Manage Billing' option in the top right menu toolbar."}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Paper>
            </Dialog>
        );
    }
    else{
        return <></>
    }
}