let config;

if (process.env.REACT_APP_REGION === "eu-west-1") {
  //IRELAND REGION DEV
  config = {
    AWS_REGION: "eu-west-1",//
    AWS_COGNITO_IDENTITY_POOL_ID:
      "eu-west-1:02522edb-5b78-4fb6-9ac3-bce9df1bad66",//
    AWS_COGNITO_USER_POOL_ID: "eu-west-1_TmjYcTgU4",//
    AWS_COGNITO_CLIENT_ID: "6uiposdvkftqrl43sftl49tr91",//
    AWS_API_APIKEY: "MmmUHcoWxP5tVd48qg7gO9BAB2fSHORW1FjLbE5g",//
    API_ENDPOINT: "https://0io4y3zh7d.execute-api.eu-west-1.amazonaws.com/test",//
    API_NAME: "APP-API",
    STORAGE_BUCKET: "pwa-storage-dev",//
    DEFAULT_SESSIONCHECK_TIME: 300000, //ms = 5min
    AWS_PINPOINT_APPID: "4e2945d24696406dac2c839e4ab9fb06",//
    AWS_PINPOINT_REGION: "eu-west-1",//
    STORAGE_CLOUDFRONT_URL : "https://d3s3u49ckgeceh.cloudfront.net",
    aws_appsync_graphqlEndpoint:
      "https://ctxip64gi5hzjctrmse6jhujpy.appsync-api.eu-west-1.amazonaws.com/graphql",//
    aws_appsync_region: "eu-west-1",//
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",//
    distanceName: "km",//
    ga_uuid: "UA-194449745-1",//
    tinymceKey:"bsd0s4jajj9donw55xgu0ft2l9d7bhlhcshgibxnpmxzewee"
  };

} else {
  //LONDON DEV
  config = {
    AWS_REGION: "eu-west-2",
    AWS_COGNITO_IDENTITY_POOL_ID:
      "eu-west-2:8a41a4c3-1443-4da8-ade3-5d1e8fecbe87",
    AWS_COGNITO_USER_POOL_ID: "eu-west-2_C3vIXDYpb",
    AWS_COGNITO_CLIENT_ID: "34bee6o73f762rn5e441vtgngn",
    AWS_API_APIKEY: "CYY9WKyPxK4Tgp4Yh5eJW45GXB0mxQyoVXfBnpef",
    API_ENDPOINT: "https://8n29trsog3.execute-api.eu-west-2.amazonaws.com/test",
    API_NAME: "APP-API",
    STORAGE_BUCKET: "pwa-storage-dev",
    DEFAULT_SESSIONCHECK_TIME: 300000, //ms = 5min
    AWS_PINPOINT_APPID: "4e2945d24696406dac2c839e4ab9fb06",
    AWS_PINPOINT_REGION: "eu-west-1",
    aws_appsync_graphqlEndpoint:
      "https://wrtqb6lnm5cf3mtyin7vyzymae.appsync-api.eu-west-2.amazonaws.com/graphql",
    aws_appsync_region: "eu-west-2",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    distanceName: "miles",
    ga_uuid: "UA-194449745-1",
    STORAGE_CLOUDFRONT_URL : "https://d3s3u49ckgeceh.cloudfront.net",
    tinymceKey:"bsd0s4jajj9donw55xgu0ft2l9d7bhlhcshgibxnpmxzewee"
  };
}

export default config;
