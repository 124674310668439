import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import green from "@material-ui/core/colors/green";

import { unsubscribe } from "../../actions/auth_actions";
import * as ROUTES from "../../constants/routes";

import { ReactComponent as SmLogo } from "../Common/Logo/smLogo.svg";
import { ReactComponent as Mountains } from "./Icons/mountains.svg";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    //marginTop: theme.spacing(1)
  },
  formhold: {
    marginLeft: theme.spacing(4),
    marginRight: 27,
    marginTop: theme.spacing(4),
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2),
    },
  },
  grid: {
    minHeight: "100vh",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: 720,
    maxWidth: 720,
    [theme.breakpoints.down(740)]: {
      maxWidth: 600,
      minWidth: 600,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: 100,
    },
  },
  logingrid: {
    minHeight: 200,
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
      marginBottom: 14,
    },
  },
  textField: {
    marginTop: 0,
    marginBottom: 0,

    fontSize: 14,
  },
  progress: {},
  progressCont: {
    height: 3,
    [theme.breakpoints.down("md")]: {
      height: 2,
    },
    [theme.breakpoints.up("md")]: {
      height: 3,
    },
  },
  marginLabel: {
    margin: 20,
    marginBottom: 0,
    marginTop: 10,
  },
  snack: {
    marginTop: 10,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  success: {
    backgroundColor: green[600],
  },
  version: {
    fontSize: 9,
    color: "#bbb",
    textAlign: "right",
    marginTop: 3,
    marginRight: theme.spacing(2),
    marginBottom: 5,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: "relative",
  },
  logo: {
    width: "100%",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      maxWidth:"80%",
      marginTop:"15px"
    },
    [theme.breakpoints.up("sm")]: {
      maxHeight: 160,
      maxWidth:"100%",
    }
  },
  logoContainer:{
    display: "flex",
    justifyContent: "center"
  },
  mountainsgrid: {
    backgroundColor: "#FDEFE7",
    //height: "100%"
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    overflow: "hidden",
    display: "flex",
  },
  mountains: {
    width: "105%",
    marginLeft: -1,
    alignSelf: "flex-end",
    marginBottom: -15,
    [theme.breakpoints.down("xs")]: {
      width: "160%",
      height: "160%",
      marginBottom: -20,
      alignSelf: "flex-start",
    },
  },
  title: {
    paddingBottom: 10,
    fontSize: 32,
    [theme.breakpoints.down("sm")]: {
      fontSize: 25,
    },

    [theme.breakpoints.down("xs")]: {},
  },
  titlesubheading:{
    textAlign: "center",
    color: "#5A6375",
    fontSize: 26,
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    color: "#5A6375",
    fontSize: 14,
    marginTop: 3,
    marginBottom: theme.spacing(2),
  },
  formtitle: {
    color: "#202A3E",
    fontSize: 14,
    marginBottom: 4,
    marginTop: 18,
  },
  forgotpw: {
    color: "#FF6A14",
    fontSize: 12,
    textAlign: "right",
    marginTop: 16,
  },
  tfroot: {
    fontSize: 14,
    backgroundColor: "#F5F6F7",
  },
  tfoutline: {
    border: "none",
  },
  signup: {
    color: "#5A6375",
    fontSize: 14,
    marginTop: 14,
    textAlign: "center",
  },
});

class Unsub extends Component {
  state = {
    urlString: window.location.search,
    completeQuery: "",
    awaitingResponse: false,
    version:
      (process.env.REACT_APP_REGION === "eu-west-2" ? "uk." : "ireland.") +
      process.env.REACT_APP_STAGE +
      "@" +
      process.env.REACT_APP_VERSION +
      (process.env.REACT_APP_BUILD
        ? "." + parseInt(process.env.REACT_APP_BUILD, 10)
        : "")
  };

  async componentDidMount() {
    if (!this.state.completeQuery && !this.state.awaitingResponse){
      this.setState({awaitingResponse: true});
      let queryDict = {}
      this.state.urlString.substr(1).split("&").forEach(function(item) {queryDict[item.split("=")[0]] = item.split("=")[1]})
      if (queryDict && queryDict.email){
        await this.props.unsubscribe(queryDict.email)        
        this.setState({completeQuery: true});           
        this.setState({awaitingResponse: false});    
      }
      else{
        this.props.history.push("/login");
      }
      
       
    }
  }

  render() {
    let   { completeQuery, awaitingResponse, version } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.progressCont}>
          {(awaitingResponse) && <LinearProgress className={classes.progress} />}
        </div>

        <Grid
          container
          spacing={0}
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.grid}
        >
          <Grid className={classes.logoContainer}>
            <SmLogo className={classes.logo} />
          </Grid>
          {completeQuery && (
            <Grid item>
              <Paper className={classes.main}>
                <Grid
                  container
                  spacing={0}
                  direction="row-reverse"
                  justify="center"
                  alignItems="stretch"
                  className={classes.logingrid}
                >
                  <Hidden xsDown>
                    <Grid item xs={12} sm={5} className={classes.mountainsgrid}>
                      <Mountains className={classes.mountains} />
                    </Grid>
                  </Hidden>
                  <Grid item xs={12} sm={7} className={classes.fieldholdergrid}>
                      <div className={classes.formhold}>
                        <Typography variant="h4" className={classes.title}>
                          You have now been unsubscribed
                        </Typography>
                        <Typography variant="body1" className={classes.subtitle}>
                          You might still receive emails such as invites and responses to job applications.
                        </Typography>
                        <Typography variant="body1" className={classes.subtitle}>
                          To unsubscribe from those emails as well, or to re-subscribe to these job notification emails please visit the setting page of the app.  
                        </Typography>
                        <Divider />

                        <Typography variant="body2" className={classes.signup}>
                          Click to go {" "}
                          <Link id="login-link" component={RouterLink} to={ROUTES.LOGIN}>
                            Login In
                          </Link>
                        </Typography>

                      </div>
                    <div className={classes.version}>{version}</div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.auth.error,
    mfaRequired: state.auth.mfa,
    changePassword: state.auth.changePassword,
    currentAuthUser: state.auth.currentAuthUser,
    success: state.auth.success,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        unsubscribe,
      },
      dispatch
    ),
    dispatch,
  };
}

Unsub = connect(mapStateToProps, mapDispatchToProps)(Unsub);

export default withStyles(styles)(Unsub);
