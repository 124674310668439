import { Auth, Cache, Logger, API } from "aws-amplify";
import config from "../config";
import {
  AUTH_USER,
  AUTH_MFA,
  AUTH_NEW_PASSWORD_REQUIRED,
  UNAUTH_USER,
  REGISTER_USER,
  REGISTER_USER_CONFIRM,
  CODE_RESEND_SUCCESS,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_CONFIRM,
  AUTH_ERROR,
  RESET_APP,
  AUTH_CONFIRM_SUCCESS,
} from "./types";
import dexie from "dexie";

// import { fetchUser } from "./user_actions";

const logger = new Logger("AUTH.ACTIONS", "INFO");

export function authError(error) {
  if (
    error.code === "InvalidParameterException" &&
    error.message.indexOf("password") !== -1
  ) {
    error.message = "Please enter a valid password!";
  }

  return {
    type: AUTH_ERROR,
    payload: error.message ? error.message : error,
  };
}

// Cognito - Auth.signIn()
export function login({ username, password }, history) {
  return function (dispatch) {
    logger.info("actions.login(): username:", { username });
    let db = new dexie("Jobs");
    db.delete();
    Cache.clear();
    // signIn (cognito)
    Auth.signIn(username, password)
      .then((data) => {
        Cache.removeItem("lastpath");
        Cache.removeItem("previouspath");
        // success -- dispatch AUTH_USER
        logger.info("login():Auth.signIn() data:", data);

        // inspect response 'data' and check whether
        // 1. MFA confirmation is required, dispatch->AUTH_MFA
        // 2. New Password is required (change 'FORCE_CHANGE_PASSWORD'
        //    to 'CONFIRMED'), dispatch-> AUTH_NEW_PASSWORD_REQUIRED with payload
        // 3. otherwise, authenticate user, dispatch -> AUTH_USER
        if (data.challengeName === "NEW_PASSWORD_REQUIRED") {
          dispatch({ type: AUTH_NEW_PASSWORD_REQUIRED, payload: data });
        } else if (
          data.challengeName === "MFA_REQUIRED" ||
          data.challengeName === "SMS_MFA"
        ) {
          dispatch({ type: AUTH_MFA, payload: data });
        } else {
          // dispatch AUTH_USER
          dispatch({ type: AUTH_USER, currentAuthUser: data });
          API.patch(config.API_NAME, `/user/updatedLastAccessedCount`, {}).catch(err => {
            logger.error("login():updatedLastAccessed err:", err);
          });
          // we have authenticated, lets navigate to /app route
          history.push("/app");
        }
      })
      .catch((err) => {
        logger.error("login():Auth.signIn() err:", err);
        // error -- invoke authError which dispatches AUTH_ERROR
        if (err.code === "UserNotConfirmedException") {
          dispatch({
            type: REGISTER_USER_CONFIRM,
            payload: {
              username: username,
            },
          });

          history.push("/signup");
        } else {
          dispatch(authError(err));
        }
      });
  };
}

// Cognito - Auth.currentAuthenticatedUser()
// Cognito - Auth.userSession()
// This is a pass-through function to indicate that user has already authenticated
// and has a valid Amplify session.

export function validateUserSession(bypass = true) {
  return function (dispatch) {
    logger.info("validateUserSession()");

    Auth.currentAuthenticatedUser({
      bypassCache: bypass,
    })
      .then((currentAuthUser) => {
        logger.info(
          "validateUserSession():Auth.currentAuthenticatedUser() currentAuthUser:",
          currentAuthUser
        );
        // grab the user session
        Auth.userSession(currentAuthUser)
          .then(async (session) => {
            logger.info(
              "actions.validateUserSession():Auth.userSession() session:",
              session
            );
            // finally invoke isValid() method on session to check if auth tokens are valid
            // if tokens have expired, lets call "logout"
            // otherwise, dispatch AUTH_USER success action and by-pass login screen

            if (session.isValid()) {
              // fire user is authenticated

              Auth.currentUserCredentials().then((credentials) => {
                dispatch({ type: AUTH_USER, currentAuthUser });
                //dispatch(fetchUser());
                logger.warn("ACCESSKEYID:", credentials.accessKeyId);
                logger.warn("SESSIONTOKEN:", credentials.sessionToken);
                logger.warn("SECRET ACCESS KEY:", credentials.secretAccessKey);
              });
              API.patch(config.API_NAME, `/user/updatedLastAccessed`, {}).catch(err => {
                logger.error("login():updatedLastAccessed err:", err);
              });
              
              //
            } else {
              logger.error("user session is not valid anymore");
              // fire user is unauthenticated
              Cache.clear();
              dispatch({ type: UNAUTH_USER });
              window.location.reload();
              dispatch({ type: RESET_APP });
            }
          })
          .catch((err) => {
            logger.error("validateUserSession():Auth.userSession() err:", err);
            // error occured during session validation, fire user is unauthenticated
            Cache.clear();
            dispatch({ type: UNAUTH_USER });
            dispatch({ type: RESET_APP });
          });
      })
      .catch((err) => {
        logger.error(
          "validateUserSession():Auth.currentAuthenticatedUser() err:",
          err
        );
        Cache.clear();
        // error occured while retrieving current auth user, fire user is unauthenticated
        dispatch({ type: UNAUTH_USER });
        dispatch({ type: RESET_APP });
      });
  };
}

// Cognito - Auth.completeNewPassword()
export function setNewPassword(cognitoUser, newPassword, history) {
  return function (dispatch) {
    logger.info("setNewPassword(): cognitoUSer, newPassword:", {
      cognitoUser,
      newPassword,
    });

    // completeNewPassword (cognito)
    Auth.completeNewPassword(cognitoUser, newPassword)
      .then((data) => {
        logger.info("setNewPassword():Auth.completeNewPassword() data: ", data);

        // inspect response 'data' and check whether
        // 1. MFA confirmation is required, dispatch->AUTH_MFA
        // 2. otherwise, authenticate user, dispatch -> AUTH_USER
        if (data.challengeName === "SMS_MFA") {
          dispatch({ type: AUTH_MFA, payload: data });
        } else {
          // dispatch AUTH_USER
          Auth.currentAuthenticatedUser({
            bypassCache: true,
          })
            .then((currentAuthUser) => {
              Auth.userSession(currentAuthUser)
                .then((session) => {
                  dispatch({ type: AUTH_USER, currentAuthUser });
                  //for first time login new passwords
                  API.patch(config.API_NAME, `/user/updatedLastAccessed`, {}).catch(err => {
                    logger.error("login():updatedLastAccessed err:", err);
                  });
                  history.push("/app");
                })
                .catch((err) => {
                  logger.error("usersession: ", err);
                });
            })
            .catch((err) => {
              logger.error("usersession: ", err);
            });

          // we have authenticated, lets navigate to /main route
        }
      })
      .catch((err) => {
        logger.error("setNewPassword():Auth.completeNewPassword() err:", err);
        // error -- invoke authError which dispatches AUTH_ERROR
        dispatch(authError(err));
      });
  };
}

// Cognito - Auth.signOut()
export function logout(username = "", history, message) {
  return function (dispatch) {
    logger.info("logout(): username: ", username);
    Cache.removeItem("lastpath");
    Cache.removeItem("previouspath");
    // signOut (cognito)
    Auth.signOut()
      .then((data) => {
        logger.info("logout():Auth.signOut() data:", data);

        dispatch({ type: UNAUTH_USER });
        dispatch({ type: RESET_APP });
        let db = new dexie("Jobs");
        db.delete();
        Cache.clear();
        // we have authenticated, lets navigate to /main route
        history.push("/");
        if (message === "inactivity"){
          window.location.assign("/login?message=inactivity");
        } else {
          window.location.reload();
        }
      })
      .catch((err) => {
        logger.error("logout():Auth.signOut() err:", err);
        // error -- invoke authError which dispatches AUTH_ERROR
        dispatch(authError(err));
      });
  };
}

// Cognito - Auth.confirmSignIn()
export function confirmLogin({ cognitoUser, code }, history) {
  return function (dispatch) {
    logger.info("confirmLogin(): cognitoUSer, code:", {
      cognitoUser,
      code,
    });

    // confirmSignIn (cognito)
    Auth.confirmSignIn(cognitoUser, code)
      .then((data) => {
        logger.info("confirmLogin():Auth.confirmSignIn() data: ", data);

        // dispatch AUTH_USER
        dispatch({ type: AUTH_USER, cognitoUser });

        // we have authenticated, lets navigate to /main route
        history.push("/app");
      })
      .catch((err) => {
        logger.error("confirmLogin():Auth.confirmSignIn() err:", err);
        // error -- invoke authError which dispatches AUTH_ERROR
        dispatch(authError(err));
      });
  };
}

// Cognito - Auth.signUp()
export function register(
  { username, password, email, phone, given_name, family_name, captcha },
  history
) {
  return function (dispatch) {
    logger.info("register(): username, password, email, phone: ", {
      username,

      email,
      phone,
    });

    /***
 * 
 * 
 * const mapObj = f => obj =>
  Object.keys(obj).reduce((acc, key) => ({ ...acc, [key]: f(obj[key]) }), {});
const toArrayOfStrings = value => [`${value}`];
const mapToArrayOfStrings = mapObj(toArrayOfStrings);
 * 
 */
    const origin = Cache.getItem("origin") || {};

    const {
      organisation = "skillzminer",
      contract = "sm",
      provider = "skillzminer",
    } = origin;

    var signupObject = {
      username,
      password,
      clientMetadata: {
        'captcha': captcha
      },
      attributes: {
        email: email,
        phone_number: phone,
        given_name,
        family_name,
        name: given_name + " " + family_name,
        "custom:organisation": organisation, //org
        "custom:contract": contract, //one contract
        "custom:origin": provider, //this will be the provider
        // other custom attributes
      }, //optional
    };
    //console.log(signupObject);


    // signUp (cognito)
    Auth.signUp(signupObject, {captcha: captcha})
      .then((data) => {
        logger.info("register():Auth.signUp() data:", data);

        // user confirm
        if (
          typeof data.userConfirmed != "undefined" &&
          data.userConfirmed === false
        ) {
          dispatch({ type: REGISTER_USER_CONFIRM, payload: data });
          //history.push("/signup");
        } else {
          dispatch({ type: REGISTER_USER });

          // user registration successful, lets navigate to / route
          history.push("/app");
        }
      })
      .catch((err) => {
        logger.error("register():Auth.signUp() err:", err);

        // error -- invoke authError which dispatches REGISTER_USER_ERROR
        dispatch(authError(err));
      });
  };
}

// Cognito - Auth.confirmSignUp()
export function confirmRegistration({ username, code }, history) {
  return function (dispatch) {
    logger.info("confirmRegistration(): cognitoUSer, code:", {
      username,
      code,
    });

    // confirmSignUp (cognito)
    Auth.confirmSignUp(username, code)
      .then((data) => {
        logger.info("confirmRegistration():Auth.confirmSignUp() data: ", data);

        // A successful registration response doesnt contain idToken.
        // So we must redirect to login screen

        // dispatch REGISTER_USER_CONFIRM
        //dispatch({ type: REGISTER_USER_CONFIRM });
        dispatch({ type: AUTH_CONFIRM_SUCCESS });
        // we have authenticated, lets navigate to /main route
        history.push("/login");
      })
      .catch((err) => {
        logger.error("confirmRegistration():Auth.confirmSignUp() err:", err);

        // error -- invoke authError which dispatches AUTH_ERROR
        //dispatch(authError(err));
        dispatch({
          type: REGISTER_USER_ERROR,
          payload: err.message,
          username,
        });
      });
  };
}

// Cognito - Auth.resendSignUp()
export function resendConfirmationCode(username, history) {
  return function (dispatch) {
    logger.info("resendConfirmationCode(): username: ", username);

    // resendSignUp (cognito)
    Auth.resendSignUp(username)
      .then((data) => {
        logger.info("resendConfirmationCode():Auth.resendSignUp() data:", data);

        dispatch({ type: CODE_RESEND_SUCCESS });
      })
      .catch((err) => {
        logger.error(
          "resendConfirmationCode():Auth.forgotPasswordSubmit() err:",
          err
        );

        // error -- invoke authError which dispatches AUTH_ERROR
        dispatch(authError(err));
      });
  };
}

// Cognito - Auth.forgotPassword()
export function forgotPassword({ username }, history) {
  return function (dispatch) {
    logger.info("forgotPassword(): username: ", { username });

    // forgotPassword (cognito)
    Auth.forgotPassword(username)
      .then((data) => {
        logger.info("forgotPassword():Auth.forgotPassword() data:", data);

        dispatch({ type: FORGOT_PASSWORD });
      })
      .catch((err) => {
        logger.error("forgotPassword():Auth.forgotPassword() err:", err);

        // error -- invoke authError which dispatches AUTH_ERROR
        dispatch(authError(err));
      });
  };
}

// Cognito - Auth.forgotPasswordSubmit()
export function confirmForgotPassword(
  { username, code, newPassword },
  history
) {
  return function (dispatch) {
    logger.info("confirmForgotPassword(): username, code, newPassword: ", {
      username,
      code,
      newPassword,
    });

    // forgotPasswordSubmit (cognito)
    Auth.forgotPasswordSubmit(username, code, newPassword)
      .then((data) => {
        logger.info(
          "confirmForgotPassword():Auth.forgotPasswordSubmit() data:",
          data
        );

        // TODO - User password changed successfully, do we need to login again?
        dispatch({ type: FORGOT_PASSWORD_CONFIRM });

        history.push("/login");
      })
      .catch((err) => {
        logger.error(
          "confirmForgotPassword():Auth.forgotPasswordSubmit() err:",
          err
        );

        // error -- invoke authError which dispatches AUTH_ERROR
        dispatch(authError(err));
      });
  };
}

export function unsubscribe(email) { //uses separate api gateway path
  return async function (dispatch) {
    logger.info("unsubscribe(): email: ", { email });
    await API.post(config.API_NAME, `/unsubscribe`, {
      body: { email: email }
    });
  };
}

export function subscribe(email) {  //uses regular api-gateway proxy
  return async function (dispatch) {
    logger.info("subscribe(): email: ", { email });
    await API.post(config.API_NAME, `/user/subscribe`, {
      body: { email: email }
    });
  };
}


export function autoApply(userID, sort) {
  return async function (dispatch) {
    logger.info("updateJobTracking()");

    const { jobitem } = await API.post(config.API_NAME, `/jobitems/get`, {
      body: { userID: userID, sort: sort}
    });

    if (jobitem){
      let { jobTitle, occupation, qual_name, rangeKey, vacancy_flag, job_url, jobId} = jobitem;
      let isQualification = false

      if (qual_name) {
        isQualification = true;
        jobTitle = qual_name;
        rangeKey = jobitem.qual_awarding_org + "#" + occupation + "#" + jobitem.jobId;
      }
      let body = {
        rangeKey: rangeKey,
        jobitem: jobitem,
        isQualification,
        userID: userID,
        doVacancyApply: vacancy_flag ? true : false,
        jobId: jobId
      }
      await API.post(config.API_NAME, "/jobitems/applied", {
        body: body,
      });

      if (job_url && job_url !== "https://dev-pwa.skillzminer.io/login"){
        return job_url.replace(/^(?!https?:\/\/)/, "https://");
      } 
    }
  }
}

export function updateJobTracking(userID, sort, updateAction) {  //uses regular api-gateway proxy
  return async function (dispatch) {
    logger.info("updateJobTracking()");

    const { jobitem, jobitemType } = await API.post(config.API_NAME, `/jobitems/get`, {
      body: { userID: userID, sort: sort}
    });

    if (jobitem){
      let { jobTitle, occupation, qual_name, rangeKey } = jobitem;
      let isQualification = false

      if (qual_name) {
        isQualification = true;
        jobTitle = qual_name;
        rangeKey = jobitem.qual_awarding_org + "#" + occupation + "#" + jobitem.jobId;
      }

      let body = {
        rangeKey: rangeKey,
        jobitem: jobitem,
        isQualification,
        verified: true,
        recreate:false,
        userID: userID
      }

      switch(updateAction) {
        case "didntapply":
          if (jobitemType === "applied"){
            await API.del(config.API_NAME, "/jobitems/applied", {
              body: body
            });
          }
          else{
            await API.del(config.API_NAME, "/jobitems/interviewed", {
              body: body
            });
          }
          break;
        case "haventappliedyet":
          body.verified = false
          body.recreate = true
          body.applicationDelay = true
          await API.post(config.API_NAME, "/jobitems/applied", {
            body: body,
          });
          // need to make a new item for this
          break;
        case "applied":
          //need to add verified thing,  also check if already existing 
          await API.post(config.API_NAME, "/jobitems/applied", {
            body: body,
          });
          break;
        case "interviewed":
          await API.post(config.API_NAME, "/jobitems/interviewed", {
            body: body,
          });
          break;
        case "started":
          await API.post(config.API_NAME, "/jobitems/started", {
            body: body
          });
          break;
        case "wasntsuccessful":
          await API.post(config.API_NAME, "/jobitems/unsuccessful", {
            body: body
          });
          break;
        default:
          break;
      }
   }
  };
}