import { Logger, Analytics } from "aws-amplify";

const logger = new Logger("ANALYTICS.ACTIONS", "INFO");

// Cognito - Auth.signIn()
export function updateEndpoint() {
  return async function (dispatch, getState) {
    const state = getState();

    try {
      const {
        email = "",
        firstName = "",
        location = "",
        assessmentVersion = 0,
        assessmentCompleted = false,
        organisation = "",
        contract = "",
        rawdata,
      } = state.user;

      const {
        desiredOccupationsOriginal = [],
        occupationsOriginal = [],
        latestAssessmentResult = {},
        keyworkerID = "",
      } = rawdata;

      const lar = latestAssessmentResult || {};

      const { interests = {} } = lar;

      /* eslint-disable */
      const { interestsOriginal = [], contract: contractType } = interests;
      /* eslint-enable */
      
      const version =
        process.env.REACT_APP_STAGE +
        "@" +
        process.env.REACT_APP_VERSION +
        (process.env.REACT_APP_BUILD
          ? "." + parseInt(process.env.REACT_APP_BUILD, 10)
          : "");

      var updateObject = {
        address: state.auth.userID,
        userId: state.auth.userID,
        userAttributes: {
          sub: [state.auth.userID],
          email: [email],
          firstName: [firstName],
        },
        attributes: {
          assessmentCompleted: [assessmentCompleted],
          organisation: [organisation],
          contract: [contract],
          contractType,
          desiredOccupations: desiredOccupationsOriginal,
          occupations: occupationsOriginal,
          keyworkerID: [keyworkerID],
        },
        location: {
          city: location,
          country: "GB",
        },
        metrics: {
          assessment_version: assessmentVersion,
        },
        demographic: {
          appVersion: version,
        }
      };

      await Analytics.updateEndpoint(updateObject);
    } catch (e) {
      logger.error("updateEndpoint():", e);
    }
  };
}

export function recordEvent() {
  return async function (dispatch, getState) {
    /* eslint-disable */
    const state = getState();
    /* eslint-enable */

    try {
    } catch (e) {
      logger.error("recordEVent():", e);
    }
  };
}

export function recordJobClick(
  type,
  title,
  occupation,
  tier_one_category,
  isQualification = false
) {
  return async function (dispatch, getState) {
    /* eslint-disable */
    const state = getState();
    /* eslint-enable */

    try {
      var sort = isQualification ? "qual" : "job";
      Analytics.record({
        name: sort + "-" + type,
        // Attribute values must be strings
        attributes: {
          type: sort + "-" + type,
          jobTitle: title,
          occupation,
          tier_one_category,
          isQualification,
        },
      });
    } catch (e) {
      logger.error("recordEVent():", e);
    }
  };
}

export function recordCentreClick(type, centreName, centreId) {
  return async function (dispatch) {
    try {
      Analytics.record({
        name: "qual-centre-" + type,
        // Attribute values must be strings
        attributes: {
          centreName,
          centreId,
        },
      });
    } catch (e) {
      logger.error("recordEVent():", e);
    }
  };
}

export function recordMissionClick(type, title, id) {
  return async function (dispatch, getState) {
    /* eslint-disable */
    const state = getState();
    /* eslint-enable */

    try {
      Analytics.record({
        name: "mission-" + type,
        // Attribute values must be strings
        attributes: { type: type, missionTitle: title, missionID: id },
      });
    } catch (e) {
      logger.error("recordEVent():", e);
    }
  };
}

export function recordSkillUpdate(occupation) {
  return async function (dispatch, getState) {
    /* eslint-disable */
    const state = getState();
    /* eslint-enable */

    try {
      Analytics.record({
        name: "skillUpdate",
        // Attribute values must be strings
        attributes: { occupation: occupation },
      });
    } catch (e) {
      logger.error("recordEVent():", e);
    }
  };
}
