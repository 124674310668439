import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import React from "react";
//import * as Sentry from "@sentry/browser";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, eventId: null,  };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  /*
  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }
*/
  render() {
    if (this.state.hasError) {
      return (
        <Dialog
          open={this.state.hasError}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Oops!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              We're sorry, something went wrong and we couldn't show the page
              you were looking for.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/*<Button
              color="primary"
              autoFocus
              onClick={() =>
                Sentry.showReportDialog({ eventId: this.state.eventId })
              }
            >
              Report feedback
            </Button>*/}
            <Button
              color="primary"
              autoFocus
              onClick={() => (window.location.href = "/")}
            >
              Take me Home
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return this.props.children;
  }
}
