import {
  AUTH_USER,
  AUTH_MFA,
  AUTH_NEW_PASSWORD_REQUIRED,
  UNAUTH_USER,
  REGISTER_USER,
  REGISTER_USER_CONFIRM,
  REGISTER_MFA,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_CONFIRM,
  AUTH_ERROR,
  AUTH_ERROR_CLEAR,
  CODE_RESEND_SUCCESS,
  AUTH_CONFIRM_SUCCESS,
} from "../actions/types";

import { Logger } from "aws-amplify";
const logger = new Logger("AUTH.REDUCER", "DEBUG");

export default function (state = {}, action) {
  switch (action.type) {
    case AUTH_USER: {
      const returnProps = {
        ...state,
        error: "",
        authenticated: true,
        mfa: false,
        userID: action.currentAuthUser.username,
        currentAuthUser: action.currentAuthUser,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case AUTH_MFA: {
      const returnProps = {
        ...state,
        error: "",
        authenticated: false,
        mfa: true,
        changePassword: false,
        userID: action.currentAuthUser.username,
        currentAuthUser: action.payload,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case AUTH_NEW_PASSWORD_REQUIRED: {
      const returnProps = {
        ...state,
        error: "",
        authenticated: false,
        mfa: false,
        changePassword: true,
        userID: action.payload.username,
        currentAuthUser: action.payload,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case UNAUTH_USER: {
      const returnProps = {
        error: "",
        authenticated: false,
        currentAuthUser: {},
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case REGISTER_USER: {
      const returnProps = {
        ...state,
        error: "",
        authenticated: false,
        register: true,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case REGISTER_USER_CONFIRM: {
      const returnProps = {
        ...state,
        error: "",
        authenticated: false,
        register: true,
        mfa: false,
        resendCode: false,
        userSub: action.userSub,
        user: action.user,
        needConfirm: true,
        username: (action.payload && action.payload.username) ? action.payload.username : action.payload.user.username,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case REGISTER_MFA: {
      const returnProps = {
        ...state,
        error: "",
        mfa: true,
        resendCode: false,
        currentAuthUser: action.payload,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case REGISTER_USER_ERROR: {
      const returnProps = {
        ...state,
        error: action.payload,
        mfa: true,
        resendCode: true
        };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case AUTH_ERROR: {
      const returnProps = {
        ...state,
        error: action.payload,
        authenticated: false,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case FORGOT_PASSWORD: {
      const returnProps = {
        ...state,
        error: "",
        forgotPasswordCode: true,
        authenticated: false,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case FORGOT_PASSWORD_CONFIRM: {
      const returnProps = {
        ...state,
        error: "",
        forgotPasswordCode: false,
        authenticated: false,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case AUTH_ERROR_CLEAR: {
      const returnProps = {
        ...state,
        error: "",
        authenticated: false,
        success: "",
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }

    case CODE_RESEND_SUCCESS: {
      const returnProps = {
        ...state,
        success: "Confirmation code has been resent.",
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case AUTH_CONFIRM_SUCCESS: {
      const returnProps = {
        ...state,
        needConfirm : false,
        register: false,
        success: "Your email has been verified, please log in.",
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    default:
      return state;
  }
}
