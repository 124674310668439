import { FETCH_COINS, RESET_COINS, RESET_MAX_COINS, PURCHASE_COMPLETE, PURCHASE_COMPLETE_CLOSE, RESET_COIN_DEFAULT } from "./types";
// import config from "../config/";
import { Logger, API } from "aws-amplify";
// import _ from "lodash";
import config from "../config/";

import { onUpdateCoins } from "../graphql/subscriptions";
;

const logger = new Logger("REWARD.ACTIONS", "INFO");

/* eslint-disable */
var coinSubscription;
/* eslint-enable */

export function fetchCoins() {
  return async function (dispatch) {
    try {
      const coinData = await API.get(config.API_NAME, `/getCoins`)
      dispatch({ type: FETCH_COINS, data: coinData });
      
    } catch (e) {
      logger.error("fetchCoins():", e);
    }
  };
}

export function resetCoins() {
  return async function (dispatch) {
    dispatch({ type: RESET_COINS });
  };
}

export function resetMaxCoins() {
  return async function (dispatch) {
    dispatch({ type: RESET_MAX_COINS });
  };
}

export function resetCoinDefault() {
  return async function (dispatch) {
    dispatch({ type: RESET_COIN_DEFAULT });
  };
}

export function checkOrders() {
  return async function (dispatch) {
    try {
      const newOrders = await API.get(config.API_NAME, `/checkOrders`);
      if(newOrders && newOrders.success){
        // console.log("new orders, fetching coins")
        await dispatch({ type: PURCHASE_COMPLETE, orders: newOrders.invoices, payments: newOrders.history});
        if(newOrders.invoices && newOrders.invoices.length > 0){
          dispatch(fetchCoins());    
        }
      }
    } catch (e) {
      logger.error("checkOrders ():", e);
    }
  };
}

export function closePurchaseDialogue() {
  return async function (dispatch) {
    await dispatch({ type: PURCHASE_COMPLETE_CLOSE});
  };
}