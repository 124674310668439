import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import {
  forgotPassword as forgotPasswordAction,
  confirmForgotPassword as confirmForgotPasswordAction,
} from "../../actions/auth_actions";

import { ReactComponent as SmLogo } from "../Common/Logo/smLogo.svg";
import { ReactComponent as Mountains } from "./Icons/mountains.svg";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    //marginTop: theme.spacing(1)
  },
  formhold: {
    marginLeft: theme.spacing(4),
    marginRight: 27,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2),
    },
  },
  grid: {
    minHeight: "100vh",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: 720,
    maxWidth: 720,
    [theme.breakpoints.down(740)]: {
      maxWidth: 600,
      minWidth: 600,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: 100,
    },
  },
  logingrid: {
    minHeight: 480,
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
      marginBottom: 14,
    },
  },
  textField: {
    marginTop: 0,
    marginBottom: 0,

    fontSize: 14,
  },
  progress: {},
  progressCont: {
    height: 3,
    [theme.breakpoints.down("md")]: {
      height: 2,
    },
    [theme.breakpoints.up("md")]: {
      height: 3,
    },
  },
  marginLabel: {
    margin: 20,
    marginBottom: 0,
    marginTop: 10,
  },
  snack: {
    marginTop: 10,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: "relative",
  },
  logo: {
    width: "100%",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      maxWidth:"80%",
      marginTop:"15px"
    },
    [theme.breakpoints.up("sm")]: {
      maxHeight: 160,
      maxWidth:"100%",
    }
  },
  logoContainer:{
    display: "flex",
    justifyContent: "center"
  },
  mountainsgrid: {
    backgroundColor: "#FDEFE7",
    //height: "100%"
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    overflow: "hidden",
    display: "flex",
  },
  mountains: {
    width: "105%",
    marginLeft: -1,
    alignSelf: "flex-end",
    marginBottom: -15,
    [theme.breakpoints.down("xs")]: {
      width: "160%",
      height: "160%",
      marginBottom: -20,
      alignSelf: "flex-start",
    },
  },
  title: {
    fontSize: 32,
    [theme.breakpoints.down("sm")]: {
      fontSize: 25,
    },

    [theme.breakpoints.down("xs")]: {},
  },
  titlesubheading:{
    textAlign: "center",
    color: "#5A6375",
    fontSize: 26,
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    color: "#5A6375",
    fontSize: 14,
    marginTop: 3,
    marginBottom: theme.spacing(2),
  },
  formtitle: {
    color: "#202A3E",
    fontSize: 14,
    marginBottom: 4,
    marginTop: 18,
  },
  forgotpw: {
    color: "#FF6A14",
    fontSize: 12,
    textAlign: "right",
    marginTop: 16,
  },
  tfroot: {
    fontSize: 14,
    backgroundColor: "#F5F6F7",
  },
  tfoutline: {
    border: "none",
  },
  signup: {
    color: "#5A6375",
    fontSize: 14,
    marginTop: 14,
    textAlign: "center",
  },
  privacy: {
    color: "#5A6375",
    fontSize: 12,
    textAlign: "left",
    marginTop: 16,
  },
  mobilePadding:{
    marginBottom: theme.spacing(2),
  },
});

class Forget extends Component {
  state = {
    email: "",
    code: "",
    password: "",
    passwordcon: "",
    open: false,
    vertical: "top",
    horizontal: "center",
    error: "",
    loading: false,
    forgotPasswordCode: ""
  };

  constructor(props) {
    super(props);
    this.reset = this.reset.bind(this);
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  componentDidMount() {}
  componentDidUpdate(prevProps) {
    if (
      this.props.errorMessage !== prevProps.errorMessage &&
      this.props.errorMessage !== ""
    ) {
      this.setState({
        open: true,
        error: this.props.errorMessage,
        loading: false,
      });
    }
  }
  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };

  reset(e) {
    e.preventDefault();
    const { forgotPasswordCode, history } = this.props;

    let username = this.state.email.trim();
    let code = this.state.code.trim();
    let newPassword = this.state.password;
    if (
      typeof forgotPasswordCode == "undefined" ||
      forgotPasswordCode === false
    ) {
      this.props.forgotPasswordAction({ username }, history);
    } else {
      if (this.state.password === this.state.passwordcon) {
        this.props.confirmForgotPasswordAction(
          { username, code, newPassword },
          history
        );
      } else {
        alert("Passwords should match!");
      }
    }
  }

  render() {
    const { horizontal, vertical, open, error, loading, email, password } = this.state;
    const { classes, forgotPasswordCode } = this.props;

    const isRightLength = password && password.length >= 8 ? true : false;
    const hasSpecial = /[/\W|_/g]/.test(password);
    const hasNumber = password.match(/\d/);
    const hasUpperCase = password.match(/[A-Z]/);


    return (
      <div className={classes.root}>
        <div className={classes.progressCont}>
          {loading && <LinearProgress className={classes.progress} />}
        </div>

        <Grid
          container
          spacing={0}
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.grid}
        >
          <Grid className={classes.logoContainer}>
              <SmLogo className={classes.logo} />
          </Grid>
          <Grid item>
            <Paper className={classes.main}>
              <Grid
                container
                spacing={0}
                direction="row-reverse"
                justify="center"
                alignItems="stretch"
                className={classes.logingrid}
              >
                <Hidden xsDown>
                  <Grid item xs={12} sm={5} className={classes.mountainsgrid}>
                    <Mountains className={classes.mountains} />
                  </Grid>
                </Hidden>
                <Grid item xs={12} sm={7} className={classes.fieldholdergrid}>
                  {!forgotPasswordCode && (
                    <div className={classes.formhold}>
                      <Typography variant="h4" className={classes.title}>
                      Forgotten your password?
                      </Typography>
                      <Typography variant="body1" className={classes.subtitle}>
                        Enter your email address below and we’ll send you details of how to get back into Skillsminer.
                      </Typography>
                      <form className={classes.form} onSubmit={this.reset}>
                        <Typography
                          variant="body1"
                          className={classes.formtitle}
                        >
                          Email:
                        </Typography>
                        <TextField
                          id="outlined-email-input"
                          placeholder="name@email.com"
                          className={classes.textField}
                          type="email"
                          name="email"
                          fullWidth
                          size="small"
                          autoComplete="email"
                          margin="normal"
                          variant="outlined"
                          autoFocus
                          required
                          onChange={this.handleChange("email")}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.tfoutline,
                              root: classes.tfroot,
                            },
                          }}
                        />
                        <Button
                          variant="contained"
                          className={classes.button}
                          size="large"
                          color="primary"
                          type="submit"
                          fullWidth
                        >
                          Reset
                        </Button>
                      </form>{" "}
                      <Button
                        variant="contained"
                        id="back-btn"
                        className={classes.button}
                        size="large"
                        color="default"
                        type="submit"
                        component={Link}
                        to={"/login"}
                        fullWidth
                      >
                        Back
                      </Button>
                    </div>
                  )}
                  {forgotPasswordCode && (
                    <div className={classes.formhold}>
                      <Typography variant="h4" className={classes.title}>
                        New Password
                      </Typography>
                      <Typography variant="body1" className={classes.subtitle}>
                        Please enter the verification code we sent to{" "}
                        <strong>{email}</strong>
                      </Typography>
                      <form className={classes.form} onSubmit={this.reset}>
                        <Typography
                          variant="body1"
                          className={classes.formtitle}
                        >
                          Verification Code
                        </Typography>
                        <TextField
                          id="outlined-code-input"
                          className={classes.textField}
                          type="text"
                          name="code"
                          placeholder="Code"
                          autoComplete="off"
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          size="small"
                          required
                          onChange={this.handleChange("code")}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.tfoutline,
                              root: classes.tfroot,
                            },
                          }}
                        />
                        <Typography
                          variant="body1"
                          className={classes.formtitle}
                        >
                          New password
                        </Typography>
                        {this.state.password.length > 0 && (
                          <Paper style={{marginBottom:"5px",backgroundColor: "#faebd7"}}>
                            <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                              <Grid item xs={6}>
                                <Typography variant="body2" className={classes.privacy} style={{alignItems: "center", display: "flex"}}>
                                  {hasUpperCase ? <CheckCircleIcon style={{color:"#69cc63"}}/> : <CancelIcon style={{color:"#ff4c4c"}}/>}
                                  1 uppercase character
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body2" className={classes.privacy} style={{alignItems: "center", display: "flex"}}>
                                  {hasNumber ? <CheckCircleIcon style={{color:"#69cc63"}}/> : <CancelIcon style={{color:"#ff4c4c"}}/>}
                                  1 number
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body2" className={classes.privacy} style={{alignItems: "center", display: "flex"}}>
                                  {hasSpecial ? <CheckCircleIcon style={{color:"#69cc63"}}/> : <CancelIcon style={{color:"#ff4c4c"}}/>}
                                  1 symbol
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body2" className={classes.privacy} style={{alignItems: "center", display: "flex"}}>
                                  {isRightLength ? <CheckCircleIcon style={{color:"#69cc63"}}/> : <CancelIcon style={{color:"#ff4c4c"}}/>}
                                  8 characters minimums
                                </Typography>
                              </Grid>
                            </Grid>
                          </Paper>
                        )}
                        <TextField
                          id="password"
                          className={classes.textField}
                          variant="outlined"
                          type="password"
                          size="small"
                          placeholder="Password"
                          autoComplete="off"
                          value={this.state.password}
                          onChange={this.handleChange("password")}
                          fullWidth
                          required
                          InputProps={{
                            classes: {
                              notchedOutline: classes.tfoutline,
                              root: classes.tfroot,
                            },
                          }}
                        />
                        <Typography
                          variant="body1"
                          className={classes.formtitle}
                        >
                          Confirm password
                        </Typography>
                        <TextField
                          id="passwordcon"
                          className={classes.textField}
                          variant="outlined"
                          type="password"
                          size="small"
                          placeholder="Confirm Password"
                          autoComplete="off"
                          value={this.state.passwordcon}
                          onChange={this.handleChange("passwordcon")}
                          required
                          fullWidth
                          InputProps={{
                            classes: {
                              notchedOutline: classes.tfoutline,
                              root: classes.tfroot,
                            },
                          }}
                        />

                        <Button
                          variant="contained"
                          className={classes.button}
                          size="large"
                          color="primary"
                          type="submit"
                          fullWidth
                        >
                          Send
                        </Button>
                      </form>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={!!open}
          className={classes.snack}
          onClose={this.handleClose}
          autoHideDuration={3000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
        >
          <SnackbarContent
            className={classes.error}
            aria-describedby="client-snackbar"
            message={<span id="message-id">{error}</span>}
          />
        </Snackbar>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    forgotPasswordCode: state.auth.forgotPasswordCode,
    errorMessage: state.auth.error,
    ...state,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      { forgotPasswordAction, confirmForgotPasswordAction },
      dispatch
    ),
    dispatch,
  };
}

Forget = connect(mapStateToProps, mapDispatchToProps)(Forget);

export default withStyles(styles)(Forget);
