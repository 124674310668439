import {
    FETCH_ALL_CVS,
    STORE_CV_DATA,
    STORE_OCC_LIST_OPTION,
    CREATE_JOB_CV,
    REMOVE_TEMPLATE,
    CLEAR_NEW_TEMPLATE_PROMPT,
    UPDATE_EXPERIENCE_PROMPTS
} from "../actions/types";
import _ from "lodash";
import moment from "moment";

export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_ALL_CVS: {
            if (action.data){
                let storedCVs = {}
                action.data.forEach(item => {
                    storedCVs[item.versionName] = item
                });
                return {
                    ...state,
                    storedCVs: storedCVs,
                    activeCV: storedCVs['master'],
                    profileSnippets: action.profileSnippets ? action.profileSnippets : state.profileSnippets ? state.profileSnippets : [],
                    promptsData: action.promptsData || {skills:{}, occupations:{}, personalStatements:{}}
                }
            }
        }
        case STORE_CV_DATA: {
            if (action.data){
                return {
                    ...state,
                    storedCVs: state.storedCVs ? {...state.storedCVs, [action.data.versionName] : action.data} : {[action.data.versionName] : action.data},
                    activeCV: action.data
                }
            }
        }
        case REMOVE_TEMPLATE:{
            let storedCVs = state.storedCVs
            delete storedCVs[action.versionName]
            return {
                    ...state,
                    storedCVs: storedCVs,
                    activeCV: storedCVs['master']
            }
        }
        case STORE_OCC_LIST_OPTION: {
            if (action.data){
                return {
                    ...state,
                    occListOptions: action.data
                }
            }
        }
        case CREATE_JOB_CV: {
            let newCV = _.cloneDeep(state.storedCVs.master);
            let newTemplateType = "new"
            newCV.versionName = action.job.jobTitle + " - " + action.job.employerName
            newCV.jobData = action.job
            newCV.updatedAt = moment.utc().valueOf();
            newCV.exportUnlocked = false
            let foundTemplate = false
            if(state.storedCVs){
                Object.keys(state.storedCVs).forEach(function(key) {
                    //console.log(key, state.storedCVs[key]);
                    if(state.storedCVs[key] && state.storedCVs[key].jobData && action.job.jobId === state.storedCVs[key].jobData.jobId){
                        foundTemplate = state.storedCVs[key]
                    }
                });
            }
            if(foundTemplate){
                return {
                    ...state,
                    activeCV: foundTemplate,
                    newTemplatePrompt: {versionName: foundTemplate.versionName, type: "existing"}
                }
            }
            else if(state.storedCVs[action.job.jobTitle + " - " + action.job.employerName]){
                let newVersionIndex = 2
                while (state.storedCVs[newCV.versionName + " - Version " + newVersionIndex]) {
                    newVersionIndex = newVersionIndex + 1 
                }                    
                newCV.versionName = newCV.versionName + " - Version " + newVersionIndex
                newTemplateType = "version"
            }

            return {
                ...state,
                storedCVs: state.storedCVs ? {...state.storedCVs, [newCV.versionName] : newCV} : {[newCV.versionName] : newCV},
                activeCV: newCV,
                newTemplatePrompt: {versionName: newCV.versionName, type: newTemplateType}
            }
        }
        case CLEAR_NEW_TEMPLATE_PROMPT:{
            return {
                ...state,
                newTemplatePrompt:false
            }
        }
        case UPDATE_EXPERIENCE_PROMPTS:{
            let newPromptData = state.promptsData
            if(action.promptContent.occupation){
                newPromptData.occupations[action.promptContent.occupation.name] = action.promptContent.occupation.value
            }
            if(action.promptContent.skills){
                for (let item of action.promptContent.skills) {
                    newPromptData.skills[item.name] = item.value
                }
            }
            if(action.promptContent.personalStatements){
                for (let item of action.promptContent.personalStatements) {
                    newPromptData.personalStatements[item.name] = item.value
                }
            }
            return {
                ...state,
                promptsData: newPromptData
            }
        }
        default: return state;
    }
}