import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import green from "@material-ui/core/colors/green";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import React, { Component } from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Content from "../Privacy/Content";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Cache } from "aws-amplify";
import "react-phone-number-input/style.css";
import {
  register,
  resendConfirmationCode,
  confirmRegistration,
} from "../../actions/auth_actions";
import { AUTH_ERROR_CLEAR } from "../../actions/types";
import * as ROUTES from "../../constants/routes";

import { ReactComponent as SmLogo } from "../Common/Logo/smLogo.svg";
import { ReactComponent as Mountains } from "./Icons/mountains.svg";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../config";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    //marginTop: theme.spacing(1)
  },
  formhold: {
    marginLeft: theme.spacing(4),
    marginRight: 27,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2),
    },
  },
  grid: {
    minHeight: "100vh",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: 720,
    maxWidth: 720,
    [theme.breakpoints.down(740)]: {
      maxWidth: 600,
      minWidth: 600,
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "100vh",
      minWidth: 100,
    },
  },
  logingrid: {
    minHeight: 450,
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
      marginBottom: 14,
    },
  },
  textField: {
    marginTop: 0,
    marginBottom: 0,

    fontSize: 14,
  },
  progress: {},
  progressCont: {
    height: 3,
    [theme.breakpoints.down("md")]: {
      height: 2,
    },
    [theme.breakpoints.up("md")]: {
      height: 3,
    },
  },
  marginLabel: {
    margin: 20,
    marginBottom: 0,
    marginTop: 10,
  },
  snack: {
    marginTop: 10,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  success: {
    backgroundColor: green[600],
  },
  version: {
    fontSize: 9,
    color: "#bbb",
    textAlign: "right",
    marginTop: 3,
    marginRight: theme.spacing(2),
    marginBottom: 5,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: "relative",
  },
  logo: {
    width: "100%",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      maxWidth:"80%",
      marginTop:"15px"
    },
    [theme.breakpoints.up("sm")]: {
      maxHeight: 160,
      maxWidth:"100%",
    }
  },
  mountainsgrid: {
    backgroundColor: "#FDEFE7",
    //height: "100%"
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    overflow: "hidden",
    display: "flex",
  },
  mountains: {
    width: "105%",
    marginLeft: -1,
    alignSelf: "flex-end",
    marginBottom: -15,
    [theme.breakpoints.down("xs")]: {
      width: "160%",
      height: "160%",
      marginBottom: -80,
      alignSelf: "flex-start",
    },
  },
  title: {
    fontSize: 32,
    [theme.breakpoints.down("sm")]: {
      fontSize: 25,
    },

    [theme.breakpoints.down("xs")]: {},
  },
  titlesubheading:{
    textAlign: "center",
    color: "#5A6375",
    fontSize: 26,
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    color: "#5A6375",
    fontSize: 14,
    marginTop: 3,
    marginBottom: theme.spacing(2),
  },
  formtitle: {
    color: "#202A3E",
    fontSize: 14,
    marginBottom: 4,
    marginTop: 18,
  },
  privacy: {
    color: "#5A6375",
    fontSize: 12,
    textAlign: "left",
    marginTop: 16,
  },
  tfroot: {
    fontSize: 14,
    backgroundColor: "#F5F6F7",
  },
  tfoutline: {
    border: "none",
  },
  signup: {
    color: "#5A6375",
    fontSize: 14,
    marginTop: 14,
    textAlign: "center",
  },
  poweredBylogo:{
    height: 40, 
    width: 165,
    marginBottom: theme.spacing(2),
  },
  orgLogo:{
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      maxWidth:"80%",
      marginTop:"15px"
    },
    [theme.breakpoints.up("sm")]: {
      maxHeight: 160,
      maxWidth:"100%",
    }
  },
  logoContainer:{
    display: "flex",
    justifyContent: "center"
  },
  mobilePadding:{
    marginBottom: theme.spacing(2),
  },
});

class Signup extends Component {
  state = {
    email: "",
    password: "",
    cpassword: "",
    showPassword: false,
    open: false,
    vertical: "top",
    horizontal: "center",
    firstname: "",
    lastname: "",
    error: "",
    loading: false,
    mobile: "",
    code: "",
    openPrivacy: false,
    org: false
  };

  constructor(props) {
    super(props);
    this.register = this.register.bind(this);
    this.confirmCode = this.confirmCode.bind(this);
    this.resend = this.resend.bind(this);
    this.recaptchaRef = React.createRef();
  }

  handleClose = () => {
    this.setState({ open: false });
    this.props.dispatch({ type: AUTH_ERROR_CLEAR });
  };

  async componentDidMount() {
    //Auth.currentCredentials();
    //global.AWS.config.credentials = await Auth.currentCredentials();

    let url = this.props.location.search;
    let params = queryString.parse(url);

    if (params.origin) {
      //console.log(params.origin);

      try {
        const k = params.origin.split(":");
        const org = k[0];
        const provider = k[1];
        const contract = k[2];

        if (org && provider && contract) {
          //found all, save it to cache
          Cache.setItem("origin", {
            organisation: org,
            provider: provider,
            contract: contract,
          });
          //window.location = url.split("?")[0];
          window.history.pushState({}, document.title, window.location.pathname);
          this.setState({
            org: org,
          });
        }
      } catch (e) {
        //console.log("origin not correct - ignore");
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.errorMessage !== prevProps.errorMessage &&
      this.props.errorMessage !== "" &&
      this.props.errorMessage
    ) {
      this.setState({
        open: true,
        error: this.props.errorMessage,
        loading: false,
      });
    }
    if (this.props.needConfirm !== prevProps.needConfirm) {
      this.setState({
        loading: false,
      });
    }
  }

  handleChange = (prop) => (event) => {
    var v = event.target.value;
    if (prop === "firstname" || prop === "lastname") {
      v = v.trimStart();
      //console.log(v);
    }
    this.setState({ [prop]: v });
  };
  handleChangeMobile = (value) => {
    //console.log(value);
    this.setState({
      mobile: value,
    });
  };

  handleCloseDialog = () => {
    //console.log(value);
    this.setState({
      openPrivacy: false,
    });
  };
  handleOpenDialog = () => {
    //console.log(value);
    this.setState({
      openPrivacy: true,
    });
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  register(e) {
    e.preventDefault();

    let username = this.state.email.trim().toLowerCase();
    let password = this.state.password;
    let passwordconf = this.state.cpassword;

    let phone = (this.state.mobile) ? this.state.mobile.trim() : "";

    let given_name = this.state.firstname.trim();
    let family_name = this.state.lastname.trim();
    let recaptchaValue = this.recaptchaRef.current.getValue();

    this.setState({
      loading: true,
      error: "",
      open: false,
    });

    if (password === passwordconf) {
      if (recaptchaValue) {
      this.props.register(
        {
          username: username,
          password: password,
          email: username,
          phone: phone,
          given_name,
          family_name,
          captcha: recaptchaValue
        },
        this.props.history
      );
      } else {
        this.setState({
          loading: false,
          open: true,
          error: "Please use the captcha.",
        });
      }
    } else {
      this.setState({
        loading: false,
        open: true,
        error: "Passwords should match",
      });
    }
  }

  confirmCode(e) {
    e.preventDefault();

    this.props.confirmRegistration(
      { username: this.props.auth.username, code: this.state.code },
      this.props.history
    );
  }

  resend(e) {
    this.props.resendConfirmationCode(
      this.props.auth.username,
      this.props.history
    );
  }

  render() {
    const {
      horizontal,
      vertical,
      open,
      error,
      loading,
      version,
      openPrivacy,
      org,
    } = this.state;

    

    const { classes, needConfirm = false, success } = this.props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
        
    const isRightLength = this.state.password && this.state.password.length >= 8 ? true : false;
    const hasSpecial = /[/\W|_/g]/.test(this.state.password);
    const hasNumber = this.state.password.match(/\d/);
    const hasUpperCase = this.state.password.match(/[A-Z]/);

    return (
      <div className={classes.root}>
        <div className={classes.progressCont}>
          {loading && <LinearProgress className={classes.progress} />}
        </div>

        <Grid
          container
          spacing={0}
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.grid}
        >
            <Grid item>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item className={classes.logoContainer}>
                {org && org !== "skillzminer" ? (
                  <img src={config.STORAGE_CLOUDFRONT_URL +"/logos/"+ org +"_appnav_logo.png"} className={classes.orgLogo}/>
                ) : (
                  <SmLogo className={classes.logo} />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Paper className={classes.main}>
              <Grid
                container
                spacing={0}
                direction="row-reverse"
                justify="center"
                alignItems="stretch"
                className={classes.logingrid}
              >
                <Hidden xsDown>
                  <Grid item xs={12} sm={5} className={classes.mountainsgrid}>
                    <Mountains className={classes.mountains} />
                  </Grid>
                </Hidden>
                <Grid item xs={12} sm={7} className={classes.fieldholdergrid}>
                  {!needConfirm && (
                    <div className={classes.formhold}>
                      <Typography variant="h4" className={classes.title}>
                        Sign up
                      </Typography>
                      <Typography variant="h6" className={classes.subtitle}>
                        If you have been sent an invitation to join our platform by a 3rd party, please use the link provided in that email.
                      </Typography>
                      {!org && (
                        <Typography variant="h6" className={classes.subtitle}>
                          If you discovered Skillsminer via a QR code or link and haven't signed up yet, please re-scan the QR code or re-click the link
                        </Typography>
                      )}
                      <form className={classes.form} onSubmit={this.register}>
                        <Typography
                          variant="body1"
                          className={classes.formtitle}
                        >
                          First name
                        </Typography>
                        <TextField
                          id="outlined-firstname-input"
                          className={classes.textField}
                          type="text"
                          name="firstname"
                          fullWidth
                          autoComplete="given-name"
                          placeholder="John"
                          margin="normal"
                          value={this.state.firstname}
                          size="small"
                          data-test="signup-firstname"
                          variant="outlined"
                          required
                          onChange={this.handleChange("firstname")}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.tfoutline,
                              root: classes.tfroot,
                            },
                          }}
                        />

                        <Typography
                          variant="body1"
                          className={classes.formtitle}
                        >
                          Last name
                        </Typography>
                        <TextField
                          id="outlined-lastname-input"
                          className={classes.textField}
                          type="text"
                          name="lastname"
                          fullWidth
                          autoComplete="family-name"
                          placeholder="Doe"
                          margin="normal"
                          size="small"
                          data-test="signup-lastname"
                          variant="outlined"
                          required
                          value={this.state.lastname}
                          onChange={this.handleChange("lastname")}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.tfoutline,
                              root: classes.tfroot,
                            },
                          }}
                        />

                        <Typography
                          variant="body1"
                          className={classes.formtitle}
                        >
                          Email
                        </Typography>
                        <TextField
                          id="outlined-email-input"
                          className={classes.textField}
                          type="email"
                          name="email"
                          fullWidth
                          autoComplete="email"
                          placeholder="name@email.com"
                          margin="normal"
                          size="small"
                          data-test="signup-email"
                          variant="outlined"
                          required
                          //value={this.state.email}
                          onChange={this.handleChange("email")}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.tfoutline,
                              root: classes.tfroot,
                            },
                          }}
                        />

                        <Typography
                          variant="body1"
                          className={classes.formtitle}
                        >
                          Mobile number
                        </Typography>
                        <PhoneInput
                          id="outlined-phone-input"
                          placeholder="Enter phone number"
                          defaultCountry="GB"
                          value={this.state.mobile}
                          international
                          flags={flags}
                          onChange={this.handleChangeMobile}
                        />

                        <Typography
                          variant="body1"
                          className={classes.formtitle}
                        >
                          Password
                        </Typography>
                        {this.state.password.length > 0 && (
                          <Paper style={{marginBottom:"5px",backgroundColor: "#faebd7"}}>
                            <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                              <Grid item xs={6}>
                                <Typography variant="body2" className={classes.privacy} style={{alignItems: "center", display: "flex"}}>
                                  {hasUpperCase ? <CheckCircleIcon style={{color:"#69cc63"}}/> : <CancelIcon style={{color:"#ff4c4c"}}/>}
                                  1 uppercase character
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body2" className={classes.privacy} style={{alignItems: "center", display: "flex"}}>
                                  {hasNumber ? <CheckCircleIcon style={{color:"#69cc63"}}/> : <CancelIcon style={{color:"#ff4c4c"}}/>}
                                  1 number
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body2" className={classes.privacy} style={{alignItems: "center", display: "flex"}}>
                                  {hasSpecial ? <CheckCircleIcon style={{color:"#69cc63"}}/> : <CancelIcon style={{color:"#ff4c4c"}}/>}
                                  1 symbol
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body2" className={classes.privacy} style={{alignItems: "center", display: "flex"}}>
                                  {isRightLength ? <CheckCircleIcon style={{color:"#69cc63"}}/> : <CancelIcon style={{color:"#ff4c4c"}}/>}
                                  8 characters minimums
                                </Typography>
                              </Grid>
                            </Grid>
                          </Paper>
                        )}
                        <TextField
                          fullWidth
                          id="password"
                          size="small"
                          placeholder="Enter your password"
                          autoComplete="off"
                          className={classes.textField}
                          variant="outlined"
                          type={this.state.showPassword ? "text" : "password"}
                          value={this.state.password}
                          onChange={this.handleChange("password")}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.tfoutline,
                              root: classes.tfroot,
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                >
                                  {this.state.showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          required
                        />
                        <Typography
                          variant="body1"
                          className={classes.formtitle}
                        >
                          Confirm password
                        </Typography>
                        <TextField
                          fullWidth
                          id="cpassword"
                          size="small"
                          placeholder="Confirm password"
                          autoComplete="off"
                          className={classes.textField}
                          variant="outlined"
                          type={this.state.showPassword ? "text" : "password"}
                          value={this.state.cpassword}
                          onChange={this.handleChange("cpassword")}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.tfoutline,
                              root: classes.tfroot,
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                >
                                  {this.state.showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          required
                        />
                        <ReCAPTCHA
                          style={{marginTop: 15}}
                          ref={this.recaptchaRef}
                          sitekey="6LfkUdoaAAAAACWTV3biAMmcp8JLPQpsuWzrFDMa"
                        />
                        <Typography variant="body2" className={classes.privacy}>
                          By signing up, I acknowledge the{" "}
                          <Link id="privacy-policy-link" href="https://www.skillsminer.ai/privacy-policy/" target="_blank" rel="noopener noreferrer">
                            Privacy Policy
                          </Link>
                            {" "} and {" "}
                          <Link id="terms-link" href="https://www.skillsminer.ai/terms-of-use/" target="_blank" rel="noopener noreferrer">
                            Terms of use
                          </Link>
                          .
                        </Typography>

                        <div className={classes.wrapper}>
                          <Button
                            variant="contained"
                            className={classes.button}
                            color="primary"
                            type="submit"
                            fullWidth
                            disabled={loading}
                          >
                            Sign up
                          </Button>
                          {loading && (
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                        </div>
                      </form>
                      <Divider />
                      <Typography variant="body2" className={classes.signup}>
                        Already have a Skillsminer account?{" "}
                        <Link id="sign-in-btn" component={RouterLink} to={ROUTES.LOGIN}>
                          Sign In
                        </Link>
                      </Typography>
                      <Typography variant="body2" className={classes.signup}>
                        <Link id="youtube-link" href="https://www.youtube.com/watch?v=1u9GtcsA5z4&list=PLaqkddI5TjYUXqGx-0rFFF2vZVTqYc2qp/" target="_blank" rel="noopener noreferrer">
                          Visit
                        </Link>
                        {" "} our Youtube Channel
                      </Typography>
                    </div>
                  )}

                  {needConfirm && (
                    <div className={classes.formhold}>
                      <Typography variant="h4" className={classes.title}>
                        Verify your email
                      </Typography>
                      <Typography variant="body1" className={classes.subtitle}>
                        Thanks for signing up for Skillsminer.  You’re just a couple of steps away from unearthing your potential and discovering a world of jobs you never knew you could do.  Please verify your email address by entering the confirmation code we sent to {" "}
                        <strong>{this.props.auth.username}</strong>
                      </Typography>
                      <form
                        className={classes.form}
                        onSubmit={this.confirmCode}
                      >
                        <Typography
                          variant="body1"
                          className={classes.formtitle}
                        >
                          Confirmation code
                        </Typography>
                        <TextField
                          id="outlined-code-input"
                          className={classes.textField}
                          type="text"
                          name="code"
                          fullWidth
                          autoComplete="off"
                          placeholder="Code"
                          margin="normal"
                          size="small"
                          data-test="signup-code"
                          variant="outlined"
                          required
                          onChange={this.handleChange("code")}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.tfoutline,
                              root: classes.tfroot,
                            },
                          }}
                        />

                        <Button
                          variant="contained"
                          className={classes.button}
                          color="primary"
                          type="submit"
                          fullWidth
                        >
                          Verify
                        </Button>
                      </form>{" "}
                      <Typography variant="body1" className={classes.subtitle}>
                       If you haven't received your confirmation code, please check your spam folder.  If it hasn't arrived or has expired, you can request a new code using the button below.
                      </Typography>
                      <Button
                        variant="contained"
                        className={classes.button}
                        color="default"
                        fullWidth
                        onClick={this.resend}
                      >
                        Resend Code
                      </Button>
                    </div>
                  )}
                  {org && org !== "skillzminer" && (
                    <Grid
                    container
                    spacing={0}
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item style={{marginBottom:5, marginTop:0}}>
                      <Typography variant="body2" className={classes.signup}>
                        Powered By
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img
                        src={
                          config.STORAGE_CLOUDFRONT_URL +"/logos/"+ org +"_powered_by_logo.png"
                        }
                        className={classes.poweredBylogo}
                      />
                    </Grid>
                  </Grid>
                  )}
                  <div className={classes.version}>{version}</div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={!!open}
          className={classes.snack}
          onClose={this.handleClose}
          autoHideDuration={3000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
        >
          <SnackbarContent
            className={classes.error}
            aria-describedby="client-snackbar"
            message={<span id="message-id">{error}</span>}
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={!!success}
          className={classes.snack}
          onClose={this.handleClose}
          autoHideDuration={3000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
        >
          <SnackbarContent
            className={classes.success}
            aria-describedby="client-snackbar"
            message={<span id="message-id">{success}</span>}
          />
        </Snackbar>

        <Dialog
          open={openPrivacy}
          onClose={this.handleCloseDialog}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth={true}
          maxWidth={"md"}
          fullScreen={fullScreen}
        >
          <DialogTitle id="scroll-dialog-title">Privacy Policy</DialogTitle>
          <DialogContent dividers={true}>
            <Content />
          </DialogContent>
          <DialogActions>
            <Button id="privacy-policy-close" onClick={this.handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog> 
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.auth.error,
    mfaRequired: state.auth.mfa,
    changePassword: state.auth.changePassword,
    currentAuthUser: state.auth.currentAuthUser,
    ...state,
    needConfirm: state.auth.needConfirm,
    success: state.auth.success,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        register,
        resendConfirmationCode,
        confirmRegistration,
      },
      dispatch
    ),
    dispatch,
  };
}

Signup = connect(mapStateToProps, mapDispatchToProps)(Signup);

export default withStyles(styles)(Signup);
