import { API, Storage, label } from "aws-amplify";
import {FETCH_ALL_CVS, STORE_CV_DATA, STORE_OCC_LIST_OPTION, CREATE_JOB_CV, REMOVE_TEMPLATE, CLEAR_NEW_TEMPLATE_PROMPT, UPDATE_EXPERIENCE_PROMPTS} from "./types";
import _ from "lodash";
import config from "../config/";
import moment from "moment";
import {fetchCoins} from "./reward_actions";

const templateCV = {
    versionName: "master",
    personalInformation :{
        name: "",
        email:"",
        phoneNumber: "",
        location: "",
        linkedIn: "",
        personalWebsite: ""
    },
    personalStatement: {
        htmlStatement: ""
    },
    experienceSection: [],
    educationSection: [],
    languageSection: [],
    achievementsSection: [],
    projectsSection: [],
    hobbiesSection: [],
    referencesSection: [],
}

export function getCVData(versionName = "master") {
    return async function (dispatch, getState) {
      const {
        user: {
          lastName = "",
          firstName = "",
          email = "",
          phoneNumber = ""
        },
      } = getState();
        try {
            let data = await API.post(config.API_NAME, `/user/applicationAssistant/get`, {});  // cvData will be a list of cv items
            let profileSnippets = await API.get(config.API_NAME, `/personalstatementsummaries`, {}); 
            if (data && data.success && data.cvData && data.cvData.length > 0){
                data.cvData = data.cvData.map((item) => {
                  if(item.sort.includes("master")){
                    if(!item.versionName){
                      item.versionName = versionName
                    }
                    if(!item.personalInformation){
                        item.personalInformation = templateCV.personalInformation
                    }
                    if(!item.personalStatement){
                        item.personalStatement = templateCV.personalStatement
                    }
                    if(!item.experienceSection){
                        item.experienceSection = templateCV.experienceSection
                    }
                    if(!item.educationSection){
                      item.educationSection = templateCV.educationSection
                    }
                    if(!item.languageSection){
                      item.languageSection = templateCV.languageSection
                    }
                    if(!item.achievementsSection){
                      item.achievementsSection = templateCV.achievementsSection
                    }
                    if(!item.projectsSection){
                      item.projectsSection = templateCV.projectsSection
                    }
                    if(!item.hobbiesSection){
                      item.hobbiesSection = templateCV.hobbiesSection
                    }
                    if(!item.referencesSection){
                      item.referencesSection = templateCV.referencesSection
                    }
                  }
                  return item
                })

                if(!data.cvData.find((item) => item.versionName.includes("master"))){
                  let newMaster = _.cloneDeep(templateCV);
                  newMaster.updatedAt = moment.utc().valueOf();
                  data.cvData.push(newMaster)
                }

                await dispatch({ type: FETCH_ALL_CVS, data: data.cvData, profileSnippets: profileSnippets, promptsData: data.promptsData});
            }
            else{
                if(versionName === "master"){
                    let newMaster = _.cloneDeep(templateCV);
                    newMaster.updatedAt = moment.utc().valueOf();
                    newMaster.personalInformation.name = firstName + " " + lastName
                    newMaster.personalInformation.email = email
                    newMaster.personalInformation.phoneNumber = phoneNumber ? phoneNumber : ""
                    await  dispatch({ type: FETCH_ALL_CVS, data: [newMaster], profileSnippets: profileSnippets, promptsData: undefined});
                }
                else{
                  await dispatch({ type: FETCH_ALL_CVS, data: false, profileSnippets: profileSnippets, promptsData: undefined});
                }
            }       
        } catch (e) {
            console.log("getCVData error ():", e);
        }
    }
}

export function setCVData(versionName, cvData) {
    return async function (dispatch, getState) {
        try {
            let data = await API.post(config.API_NAME, `/user/applicationAssistant/set`, {
                body: {
                    versionName: versionName ? versionName : "master",
                    personalInformation: cvData.personalInformation,
                    personalStatement: cvData.personalStatement,
                    experienceSection: cvData.experienceSection,
                    educationSection: cvData.educationSection,
                    languageSection: cvData.languageSection,
                    achievementsSection: cvData.achievementsSection,
                    projectsSection: cvData.projectsSection,
                    hobbiesSection: cvData.hobbiesSection,
                    referencesSection: cvData.referencesSection,
                    jobData: cvData.jobData,
                    prompts: cvData.prompts,
                    exportUnlocked: cvData.exportUnlocked
                },
            });
            if (data && data.success && data.cvData){
                await dispatch({ type: STORE_CV_DATA, data: data.cvData});
                return true
            }
        } catch (e) {
            console.log("setCVData error ():", e);
        }
    }
}

export function changeTemplate(versionName) {
  return async function (dispatch, getState) {
    // console.log("changeTemplate")
    const {
      applicationAssistant : {
        storedCVs
      }
    } = getState();
    dispatch({ type: STORE_CV_DATA, data: storedCVs[versionName]});
  }
}

export function createTemplateForJob(jobData) {
  return async function (dispatch, getState) {
    const {
      applicationAssistant : {
        storedCVs,
        activeCV,
      }
    } = getState();

    if(!storedCVs && !activeCV){
      await dispatch(getCVData())          
      await dispatch(getOccsForSelection())
    }
    await dispatch({ type: CREATE_JOB_CV, job: jobData});

  }
}

export function deleteTemplate(versionName){
  return async function (dispatch, getState) {

    let data = await API.post(config.API_NAME, `/user/applicationAssistant/delete`, {
      body: {
          versionName: versionName
      },
    });

    await dispatch({ type: REMOVE_TEMPLATE, versionName: versionName});
  }
}

export const autoCompleteOccupation = (text) => {
    return async function (dispatch) {
      try {
        const data = await API.post(config.API_NAME, "/nlu/occupation", {
          body: {
            text: text, 
            dualType: false
          }
        })
        if (data && data.response){
          return data.response
        }
      } catch (e) {
        console.log(e)
      }
      try {
        const data = await API.post(config.API_NAME, "/nlu/fallbackoccupation", {
          body: {
            text: text, 
            dualType: false
          }
        })
        if (data && data.response){
          return data.response
        }
      } catch (e) {
        throw e;
      }
      return [];
    }
}

export const autoCompleteV2 = (text) => {
  return async function (dispatch) {
    try {
      const data = await API.post(config.API_NAME, "/nlu/occupationAutoComplete", {
        body: {
          text: text, 
          dualType: false
        }
      })
      if (data && data.response){
        return data.response
      }
    } catch (e) {
      console.log(e)
    }
    try {
      const data = await API.post(config.API_NAME, "/nlu/occupationAutoCompleteFallback", {
        body: {
          text: text, 
          dualType: false
        }
      })
      if (data && data.response){
        return data.response
      }
    } catch (e) {
      throw e;
    }
    return {
      directMatches: [],
      closeMatches: []
    };
  }
}

export const autoCompleteSkill = (text) => {
  return async function (dispatch) {
    try {
      const data = await API.post(config.API_NAME, "/nlu/skill", {
        body: {
          text: text, 
          dualType: false
        }
      })
      if (data && data.response){
        return data.response
      }
    } catch (e) {
      console.log(e)
    }
    try {
      const data = await API.post(config.API_NAME, "/nlu/fallbackskill", {
        body: {
          text: text, 
          dualType: false
        }
      })
      if (data && data.response){
        return data.response
      }
    } catch (e) {
      throw e;
    }
    return [];
  }
}

export const autoCompleteV2Skill = (text) => {
  return async function (dispatch) {
    // console.log("skillAutoComplete(): ");
    try {
      const data = await API.post(config.API_NAME, "/nlu/skillAutoComplete", {
        body: {
          text: text, 
          dualType: false
        }
      })
      if (data && data.response){
        return data.response
      }
    } catch (e) {
      console.log(e)
    }
    try {
      const data = await API.post(config.API_NAME, "/nlu/skillAutoCompleteFallback", {
        body: {
          text: text, 
          dualType: false
        }
      })
      if (data && data.response){
        return data.response
      }
    } catch (e) {
      throw e;
    }
    return {
      directMatches: [],
      closeMatches: []
    };
  }
}


export const getOccDetails = (isOcc, text) => {
  return async function (dispatch, getState) {
    const {
      user : {
        occupationSkills,
        preferredOccupations,
        previousOccupations
      }
    } = getState();
    if(isOcc){
      let response = await API.post(config.API_NAME, "/data/occupation", {
        body: {
          label: text
        }
      })
      try {
        if (response && response.data){
          let details = response.data
          details.skills = {"Expert":[], "Proficient":[],"Novice":[], "Beginner":[],"Missing":[]}          
          let allSkills = {}
          try {
            
            for(const skill of [...response.data.essentialSkills, ...response.data.optionalSkills]){
              allSkills[skill] = true
            }
            let existingSkills = occupationSkills.find((item) => item.occupation == text)
            if ((preferredOccupations.includes(text) || previousOccupations.includes(text)) && existingSkills){
              for (const skill of existingSkills.skills){
                if(allSkills[skill.name]){
                  if (skill.level === 4){
                    details.skills["Expert"].push(skill.name)
                    delete allSkills[skill.name]
                  }
                  else if(skill.level === 3){
                    details.skills["Proficient"].push(skill.name)
                    delete allSkills[skill.name]
                  }
                  else if(skill.level === 2){
                    details.skills["Novice"].push(skill.name)
                    delete allSkills[skill.name]
                  }
                  else{
                    details.skills["Beginner"].push(skill.name)
                    delete allSkills[skill.name]
                  }
                }
              }
            }

            for (const [key, value] of Object.entries(allSkills)){
              details.skills["Missing"].push(key)
            }
            details.skills=[{type:"Expert", skills:details.skills.Expert},{type:"Proficient", skills:details.skills.Proficient}, {type:"Novice", skills:details.skills.Novice}, {type:"Beginner", skills:details.skills.Beginner},{type:"Missing", skills:details.skills.Missing}]
            
          } catch (e) {
            console.log(e)
          }
          return details
        }
      } catch (e) {
        throw e;
      }
    }
  else{
    try {
      let details = {skills : {"Expert":[], "Proficient":[],"Novice":[], "Beginner":[]}}
      if(text){
        details.skills.Beginner = [...details.skills.Beginner, text]
      }
      else{
        for (const skill of occupationSkills){
          if (skill.occupation.includes("Manually Added") || skill.occupation.includes("Resource Added Skills")){
            for (const _skill of skill.skills){
              if (_skill.level === 4){
                details.skills["Expert"].push(_skill.name)
              }
              else if(_skill.level === 3){
                details.skills["Proficient"].push(_skill.name)
              }
              else if(_skill.level === 2){
                details.skills["Novice"].push(_skill.name)
              }
              else{
                details.skills["Beginner"].push(_skill.name)
              }
            }
          }
        } 
      }

      details.skills=[{type:"Expert", skills:details.skills.Expert},{type:"Proficient", skills:details.skills.Proficient}, {type:"Novice", skills:details.skills.Novice}, {type:"Beginner", skills:details.skills.Beginner}]
      return details

    } catch (e) {
      throw e;
    }
  }
  }
}

export function getOccsForSelection() {
  return async function (dispatch, getState) {
      const {
          user : {
            desiredExp,
            preferredOccupations,
            previousOccupations
          }
        } = getState();
      let occList = []

      for (const idx in desiredExp) {
        if(idx){
          occList.push(preferredOccupations[idx])
        }
      }
      occList.push(...previousOccupations)

      await dispatch({ type: STORE_OCC_LIST_OPTION, data: occList});
  }
}


export function generatePrompts(promptType, promptText, sectionIndex) {
  return async function (dispatch, getState) {
    const {
      applicationAssistant : {
        activeCV,
      }
    } = getState();
    let newActiveCV  = _.cloneDeep(activeCV);

    let prompt = ""
    if(promptType === "job_description"){
      prompt = activeCV.jobData.fullDescription
    }
    else{
      prompt = promptText
    }

    const coinsCheck = await API.post(config.API_NAME, "/coinsCheck",
      {
        body: {
          promptType: promptType,
        },
      }
    );
    if(coinsCheck){
      try {
        const {jobs} = await API.post(config.API_NAME, "/jobs/query/chatgpt", {
          body: {
              prompt: prompt,
              temperature: 0,
              reason: promptType, 
          },
        });
        if(jobs){
          let cleanedPrompts = jobs.map((value) => value.replaceAll("\n", "<br>"));
          const spendCoins = await API.post(config.API_NAME, "/spendCoins",
            {
              body: {
                promptType: promptType,
                promptContent: cleanedPrompts
              },
            }
          );
          if(spendCoins){
            dispatch(fetchCoins())
            if(promptType === "job_description"){
              newActiveCV.jobData.prompts = cleanedPrompts
              await dispatch(setCVData(newActiveCV.versionName, newActiveCV));
            }
            else{
              return {array: cleanedPrompts, text: cleanedPrompts.map((value) => {return value.length > 2 ? '<p>' + value + '</p>': value}).join("")}
            }
          }
        }
      } catch (e) {
        console.log("generatePrompts error ():", e);
      }
    }
  }
}


export function generateExperiencePrompts(promptType, promptText, preferredLabel) {
  return async function (dispatch, getState) {
    const {
      applicationAssistant : {
        activeCV,
      }
    } = getState();

    const coinsCheck = await API.post(config.API_NAME, "/coinsCheck",
      {
        body: {
          promptType: promptType,
          promptContent: promptType === "experience_occupation" ? {occupation: preferredLabel} : {skills: promptText} 
        },
      }
    );

    if(coinsCheck){
      if(promptType === "experience_occupation"){
        try {
          const occPrompt = await API.post(
            config.API_NAME,
            "/users/getGPTDescription",
            {
              body: {
                occupation: preferredLabel,
              },
            }
          );
          if(occPrompt && occPrompt.length > 0){
            let cleanedDescription = occPrompt.split("\n").map((item) => {
              if(item.charAt(0) === "-"){
                return "<li>" + item.replace("- ", "") + "</li>"
              }
              return "<li>" + item + "</li>"
            }).join("")
            cleanedDescription = "<em>Roles and Responsibilities:</em><ul>" + cleanedDescription + "</ul>"
            let promptContentOccupation = {occupation: {name: promptText, value: cleanedDescription}}
            const spendCoins = await API.post(config.API_NAME, "/spendCoins",
              {
                body: {
                  promptType: promptType,
                  promptContent: promptContentOccupation
                },
              }
            );
            if(spendCoins){
              dispatch(fetchCoins())
              await dispatch({ type: UPDATE_EXPERIENCE_PROMPTS, promptContent: promptContentOccupation});
              return {text: cleanedDescription}
            }
          }
        } catch (e) {
          console.log(e)
        }
      }     
      else if(promptType === "experience_skill"){
          try {
          const skillDetails = await API.post(
            config.API_NAME,
            "/users/getSkillsDetails",
            {
              body: {
                skills: promptText,
              },
            }
          );
          if(skillDetails && skillDetails.length > 0){
            let promptContentSkills = {skills: skillDetails.map((item) => {return {name: item.name, value: item.description}})}
            let missingSkills = promptText.filter((item) => !skillDetails.find((_item) => item === _item.name))
            const spendCoins = await API.post(config.API_NAME, "/spendCoins",
              {
                body: {
                  promptType: promptType,
                  promptContent: promptContentSkills
                },
              }
            );
            if(spendCoins){
              dispatch(fetchCoins())
              await dispatch({ type: UPDATE_EXPERIENCE_PROMPTS, promptContent: promptContentSkills});
              return {text: promptContentSkills.skills.map((item) => {return '<li>' + item.value + '</li>'}).join(""), selectedSkills: skillDetails.map((item) => item.name), warningSkills: missingSkills && missingSkills.length > 0 ? missingSkills : false}
            }
          }
          else{
            return {warningSkills: promptText}
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
  }
}


export function generateStatementPrompts(promptType, promptList) {
  return async function (dispatch, getState) {
    const {
      applicationAssistant : {
        activeCV,
      }
    } = getState();

    const coinsCheck = await API.post(config.API_NAME, "/coinsCheck",
      {
        body: {
          promptType: promptType,
          promptContent: {personalStatements: promptList}
        },
      }
    );

    if(coinsCheck){
      try {
        const statementDetails = await API.post(
          config.API_NAME,
          "/personalstatementtext",
          {
            body: {
              dimensions: promptList,
            },
          }
        );
        if(statementDetails && statementDetails.length > 0){
          let promptContentStatements = {personalStatements: statementDetails.map((item) => {return {name: item.dimension, value: item}})}
          const spendCoins = await API.post(config.API_NAME, "/spendCoins",
            {
              body: {
                promptType: promptType,
                promptContent: promptContentStatements
              },
            }
          );
          if(spendCoins){
            dispatch(fetchCoins())
            await dispatch({ type: UPDATE_EXPERIENCE_PROMPTS, promptContent: promptContentStatements});
            return {text: promptContentStatements.personalStatements.map((item) => {return "<p>" + item.value.text + "</p>"}).join("")}
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}


export function clearNewTemplatePrompt() {
  return async function (dispatch, getState) {
    dispatch({ type: CLEAR_NEW_TEMPLATE_PROMPT});
  }
}




export function unlockCVExport() {
  return async function (dispatch, getState) {
    const {
      applicationAssistant : {
        activeCV,
      }
    } = getState();

    const coinsCheck = await API.post(config.API_NAME, "/coinsCheck",
      {
        body: {
          promptType: "cvExport",
          promptContent: false
        },
      }
    );

    if(coinsCheck){
      try {
        const spendCoins = await API.post(config.API_NAME, "/spendCoinsUnlock",
          {
            body: {
              activeCV: activeCV
            },
          })
        if(spendCoins){
          await dispatch(setCVData(activeCV.versionName, {...activeCV, exportUnlocked: true}))
          await dispatch(fetchCoins())
          return true
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}

export function generatePreview(activeCV, cvStyle) {
  return async function (dispatch, getState) {
    try {
      const generatedHtml = await API.post(
        config.API_NAME,
        "/createHtml",
        {
          body: {...activeCV, cvStyle}
        }
      );
      if (generatedHtml && generatedHtml.rawHtml){
        return {success: true, data: generatedHtml.rawHtml}
      }
    }
  catch (e) {
    console.log("generatePdf():", e);
  }
  }
}


export function generatePDF(activeCV, rawHTML) {
  return async function (dispatch, getState) {
    const {
      user: {
        rawdata: {
          userID
        } = {},
      },
    } = getState();
    try {
      const genResponse = await API.post(
        config.API_NAME,
        "/pdf-service/application-assistant-pdf",
        {
          body: { id: userID, templateName: activeCV.versionName, html:rawHTML},
        },
          { timeout: 1200000 }
      );
      if (genResponse && genResponse.success){
        // console.log("genResponse.success")
        const s3Url = await Storage.get(`applicationAssistant/${activeCV.versionName}.pdf`,
          {
            level: "private",
          });
        return {success: true, data: s3Url}
      }
    }
    catch (e) {
      console.log("generatePdf():", e);
    }
  }
}

export function downloadCV(cvLink) {
  return async function (dispatch, getState) {
    try {
        const blob = await fetch(cvLink, { method: "get" });
        return {success: true, data: await blob.blob()}
    }
    catch (e) {
      console.log("generatePdf():", e);
    }
  }
}


export function triggerCheckout(type) {
  return async function (dispatch, getState) {
    try {
      const endpointString = type === "bundle" ? "/bundleCheckout" : "/subscriptionCheckout"
      // const endpointString = type === "bundle" ? "/bundleCheckout" : "/subscriptionCheckout"
      const checkoutResponse = await API.get(config.API_NAME, endpointString);
      if (checkoutResponse && checkoutResponse.success && checkoutResponse.session){
        return {url : checkoutResponse.session.url}
      }
    }
  catch (e) {
    console.log("triggerCheckout():", e);
  }
}}

export function triggerCustomerPortal() {
  return async function (dispatch, getState) {
    try {
      const checkoutResponse = await API.get(config.API_NAME, "/portalSession");
      if (checkoutResponse && checkoutResponse.success && checkoutResponse.session){
        return {url : checkoutResponse.session.url}
      }
    }
  catch (e) {
    console.log("triggerCustomerPortal():", e);
  }
}}