import { createMuiTheme } from "@material-ui/core/styles";
let base = createMuiTheme();

// A custom theme for this app
const theme = createMuiTheme({
  spacing: 10,
  typography: {
    fontFamily: [
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    primary: {
      main: "#FF6A14",
      contrastText: "#fff",
    },
    secondary: {
      main: "#19857b",
    },
    /* error: {
      main: red.A400
    },*/
    background: {
      default: "#F8F9FA",
    },
    type : "light"
  },
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      root: {
        borderRadius: 4,
        boxShadow: "none",
      },
      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
        "&$disabled": { boxShadow: "none" },
      },

      label: {
        textTransform: "capitalize",
        //fontSize: "1.1rem"
      },
    },
    MuiLinearProgress: {
      root: {
        height: 3,
        [base.breakpoints.down("md")]: {
          height: 2,
        },
        [base.breakpoints.up("md")]: {
          height: 3,
        },
      },
    },
    MuiBackdrop: {
      root: {
        /*zIndex: 8000,*/
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 8,
      },
    },
    MuiSnackbar: {
      root: {
        // Some CSS
        zIndex: '9999',
      },
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          zIndex: '9999',
        },
      },
    },
  },
});
//boxShadow: "0px 1px 0px ",
//theme.shadows[0] = "0px 0px 0px 1px #DDE0E5";
theme.shadows[10] = "0px 0px 0px 1px #DDE0E5";
theme.shadows[2] =
  "0px 3px 1px -2px rgba(0,0,0,0.06),0px 2px 2px 0px rgba(0,0,0,0.06),0px 1px 5px 0px rgba(0,0,0,0.06)";
theme.shadows[1] =
  "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)";
theme.shadows[6] = "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.06),0px 1px 18px 0px rgba(0,0,0,0.06)";
//  theme.shadows[1] = "0px 2px 1px -1px rgba(0,0,0,0.06),0px 1px 1px 0px rgba(0,0,0,0.06),0px 1px 3px 0px rgba(0,0,0,0.06)";

export default theme;
