import { FETCH_COINS, RESET_COINS, RESET_MAX_COINS, PURCHASE_COMPLETE, PURCHASE_COMPLETE_CLOSE, RESET_COIN_DEFAULT } from "../actions/types";
import { Logger } from "aws-amplify";
import _ from 'lodash';
/* eslint-disable */
const logger = new Logger("REWARD.REDUCER", "DEBUG");
/* eslint-enable */

export const rewardReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_COINS: {
      let dailyLimitReachedNotification = false
      let showOrgDefault = false
      var currentLevel = 1;
      var toNextLevel = 0;
      var levelRange = 50;
      const levels = [
        50,
        100,
        200,
        400,
        800,
        1600,
        3200,
        6400,
        12800,
        25600,
        51200,
        102400,
        204800,
        409600,
        819200,
        1638400,
        3276800,
        6553600,
        13107200,
        26214400,
      ];

      let coins = 0;
      let totalEarnedCoins = 0;
      if (action.data) {
        coins = action.data.coins;
        totalEarnedCoins = Object.entries(action.data).map(([k,v]) => ["coins", "userID", "sort"].includes(k) ?  0 : v).reduce((partialSum, a) => partialSum + a, 0);
        if(action.data.showOrgDefault && action.data.orgDefault){
          showOrgDefault = action.data.orgDefault
        }
      }

      var newCoins = 0;
      var setcoins = 1;

      if (parseInt(coins) >= 0) {
        setcoins = coins;
      }

      if (state.coins >= 0) {
        newCoins = setcoins - state.coins;
      }

      for (var [i, l] of levels.entries()) {
        if (totalEarnedCoins <= l) {
          currentLevel = i + 1;
          toNextLevel = l - totalEarnedCoins;
          if (i - 1 >= 0) {
            levelRange = l - levels[i - 1];
          }

          break;
        }
      }
      if(action.data.dailyLimitReached && setcoins === state.coins){
        dailyLimitReachedNotification = true
      }   

      const returnProps = {
        ...state,
        coins: setcoins,
        newCoins,
        level: currentLevel,
        toNextLevel,
        levelRange,
        dailyLimitReachedNotification,
        totalEarnedCoins,
        showOrgDefault
      };
      return returnProps;
    }

    case RESET_COINS: {
      const returnProps = {
        ...state,
        newCoins: 0,
      };
      return returnProps;
    }

    case RESET_COIN_DEFAULT: {
      const returnProps = {
        ...state,
        showOrgDefault : false,
      };
      return returnProps;
    }
    case RESET_MAX_COINS: {
      const returnProps = {
        ...state,
        dailyLimitReachedNotification : false,
      };
      return returnProps;
    }
    case PURCHASE_COMPLETE: {
      const returnProps = {
        ...state,
        payments: _.orderBy(action.payments.map((item) => {return {...item, timestamp: item.timestamp ? item.timestamp : 0}}), "timestamp", "desc"),
        stripeOrders: action.orders,
        purchaseComplete : (action.orders && action.orders.length > 0) ? true :  false,
      };
      return returnProps;
    }
    case PURCHASE_COMPLETE_CLOSE: {
      const returnProps = {
        ...state,
        purchaseComplete : false,
      };
      return returnProps;
    }
    

    default:
      return state;
  }
};
