import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from '@sentry/browser';
import "./index.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ServiceWorkerProvider, useServiceWorker } from "./useServiceWorker";
import { ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import configureStore from "./store";
import Amplify from "aws-amplify";
import { Cache } from "aws-amplify";
import config from "./config/";
import App from "./App";
import "react-universal-hooks";
//this will catch the error, if something goes bad with the serviceworker!
import "./apocalypseHandler.js";
import { ErrorBoundary } from "./components/Common/ErrorBoundary";
import theme from "./theme/theme";


//SENTRY INIT
Sentry.init({
  dsn: "https://ded22177f03b49b4891ddaad28357a84@o341614.ingest.sentry.io/5271094",
  release:
    process.env.REACT_APP_STAGE +
    "@" +
    process.env.REACT_APP_VERSION +
    (process.env.REACT_APP_BUILD
      ? "." + parseInt(process.env.REACT_APP_BUILD, 10)
      : ""),
      environment: process.env.REACT_APP_STAGE
});


const AppParent = () => {
  const { isUpdateAvailable, updateAssets } = useServiceWorker();
  return (
    <App isUpdateAvailable={isUpdateAvailable} updateAssets={updateAssets} />
  );
};

Amplify.configure({
  Auth: {
    identityPoolId: config.AWS_COGNITO_IDENTITY_POOL_ID,
    region: config.AWS_REGION,
    userPoolId: config.AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: config.AWS_COGNITO_CLIENT_ID,
    oauth: {
      domain: "pwa-test.auth.eu-west-2.amazoncognito.com",
      scope: ["email", "openid", "profile"],
      redirectSignIn: "http://localhost:3001/login/",
      redirectSignOut: "http://localhost:3001/login/",
      responseType: "token"
    }
  },
  API: {
    endpoints: [
      {
        name: config.API_NAME,
        endpoint: config.API_ENDPOINT,
        region: config.AWS_REGION,
        custom_header: async () => {
          return {
            "x-api-key": config.AWS_API_APIKEY,
          };
        },
      },
    ]
  },
  Storage: {
    AWSS3: {
      bucket: config.STORAGE_BUCKET,
      region: config.AWS_REGION,
    },
  },
  Analytics: {
    // OPTIONAL - disable Analytics if true
    disabled: false,
    // OPTIONAL - Allow recording session events. Default is true.
    autoSessionRecord: true,
    AWSPinpoint: {
      // OPTIONAL -  Amazon Pinpoint App Client ID
      appId: config.AWS_PINPOINT_APPID,
      // OPTIONAL -  Amazon service region
      region: config.AWS_PINPOINT_REGION,
      bufferSize: 100,
      flushInterval: 5000, // 5s
      flushSize: 100,
      resendLimit: 5,
      optOut: "NONE"
    },
  },
  // aws_appsync_graphqlEndpoint: config.aws_appsync_graphqlEndpoint,
  // aws_appsync_region: config.aws_appsync_region,
  // aws_appsync_authenticationType: config.aws_appsync_authenticationType
});

Cache.configure({
  itemMaxSize: 3000000, // 3000 bytes
  capacityInBytes : 5000000
});

ReactDOM.render(
  //<React.StrictMode>
  <ServiceWorkerProvider>
    <Provider store={configureStore()}>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <ErrorBoundary>
          <AppParent />
        </ErrorBoundary>
      </ThemeProvider>
    </Provider>
  </ServiceWorkerProvider>,
  //</React.StrictMode>,
  document.getElementById("root")
);
