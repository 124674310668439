import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const useStyles = makeStyles((theme) => ({
  mainTitle: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 20,
    color: "#202A3E",
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 17,
    color: "#5A6375",
  },
  body: {
    fontSize: 12,
    color: "#5A6375",
    marginBottom: 12,
  },
}));

const Content = (props) => {
  const classes = useStyles(props);

  return (
    <>
      <Typography variant="h5" className={classes.mainTitle}>
        Skillsminer Limited Privacy Notice
      </Typography>
      <Typography variant="body1" className={classes.body}>
        We are Skillsminer Limited. We are a company registered in Scotland with
        company number SC447870. We are a software company and have developed an
        AI system which identifies people’s talents and skills to determine what
        job opportunities are available to them or what training would be
        required for them to get their next job.
      </Typography>
      <Typography variant="body1" className={classes.body}>
        We provide these services to individuals and we provide these services
        to organisations who are:
      </Typography>
      <ul variant="body1" className={classes.body}>
          <li>looking to place people in work;</li>
          <li>looking to identify training opportunities;</li>
          <li>wanting to learn more about their existing talent; or</li>
          <li>who are looking for the perfect candidate for a position.</li>
        </ul>
      <Typography variant="body1" className={classes.body}>
        In order to help individuals and organisations in these ways we ask
        people to give us information about themselves. This notice sets out how
        we use that information and what rights you as an individual user have
        in relation to it whether you have found us yourself or another
        organisation has directed you here.
      </Typography>
      <Typography variant="h6" className={classes.subTitle}>
        Contact details
      </Typography>
      <Typography variant="body1" className={classes.body}>
        You can contact us in the following ways:
      </Typography>
      <ul variant="body1" className={classes.body}>
          <li>e-mail to: enquiries@skillsminer.ai</li>
          <li>
            write to us at our registered address: SkillzMiner Limited, Stirling House, 226 St Vincent Street, Glasgow, G2 5RQ, Scotland
          </li>
        </ul>
      <Typography variant="h6" className={classes.subTitle}>
        The data we collect from you
      </Typography>
      <Typography variant="body1" className={classes.body}>
        When you sign up to an account for our Platform we collect information
        from you so that you can create an account with us, including contact
        details. We will then ask you to provide us with information about your
        qualifications, experience, interests, personality and employment
        history which will allow us to create your profile.
      </Typography>
      <Typography variant="h6" className={classes.subTitle}>
        How we use data collected from users:
      </Typography>
      <Typography variant="body1" className={classes.body}>
        You sign up to use our Platform so that we can provide you with a
        personal profile to help you find work or training. To provide that
        service it is necessary for us to use the information you have given us
        to create your profile. More information about how we do that is
        provided when you sign up. You will then be able to access that profile
        through our Platform, and the organisation that directed you to our
        Platform will be able to access that profile giving an insight into your
        occupational preferences, working styles and interests.
      </Typography>
      <Typography variant="h6" className={classes.subTitle}>
        Sharing information with third parties
      </Typography>
      <Typography variant="body1" className={classes.body}>
        We use other companies to assist us to provide our service. Some of
        these are software providers and some help us to store our data
        securely. We do not allow these third-party service providers to use
        your personal data for their own purposes and only permit them to use
        your personal data for specified purposes, in accordance with our
        instructions. We have a contract with them setting out our instructions
        with provisions in relation to the security of your data.
      </Typography>
      <Typography variant="body1" className={classes.body}>
        If you were directed to use our Platform by another organisation your
        profile will be shared with that organisation so that they can provide
        you with their services. Once that data is shared it will be processed
        in accordance with their privacy notice.
      </Typography>
      <Typography variant="body1" className={classes.body}>
        We collect, use and share aggregated data, such as statistical or
        demographic data but this is anonymised when shared with organisations
        that we work with, meaning that there will be no information about you
        share din this way. For example we may combine your usage data with the
        usage data of other users to calculate the percentage of users accessing
        the Platform or a specific feature of the Platform.
      </Typography>
      <Typography variant="body1" className={classes.body}>
        Information will be shared in the event that our business is sold, or
        its assets are sold, to any third party, or as part of any business
        restructuring or reorganisation, but we will take steps with the aim of
        ensuring that your privacy rights continue to be protected and you are
        informed as soon as possible;
      </Typography>
      <Typography variant="body1" className={classes.body}>
        We will share your data with law enforcement agencies only if we are
        required to so by a court order or by the law.
      </Typography>
      <Typography variant="body1" className={classes.body}>
        We may share information in anonymised form with data aggregators and
        service providers as part of an analysis of user metrics or performance;
        along with elements of your participation on our Platform.
      </Typography>
      <Typography variant="h6" className={classes.subTitle}>
        Security
      </Typography>

      <Typography variant="body1" className={classes.body}>
        Our Platform is hosted in the UK in a server run by Amazon Web Services.
        Your information is stored there and encrypted. Direct access to our
        database is provided to third party organisations through a web-based
        interface. They can only the data of the individuals that they have
        directed to us.
      </Typography>
      <Typography variant="body1" className={classes.body}>
        We have implemented technology and information security policies in our
        organisations to safeguard your privacy and to protect your data from
        unauthorised access and improper use.
      </Typography>

      <Typography variant="h6" className={classes.subTitle}>
        Data Retention
      </Typography>
      <Typography variant="body1" className={classes.body}>
        If you have not used our Platform for six months, we will contact you
        and ask if you would like to continue to use our services. If you
        indicate that you no longer wish to use our services, your personal data
        will be deleted from our Platform.
      </Typography>
      <Typography variant="body1" className={classes.body}>
        If we receive no response from you, we will retain your personal data
        for five years following your last use of our Platform, and then it will
        be deleted.
      </Typography>
      <Typography variant="h6" className={classes.subTitle}>
        Your Data Protection Rights
      </Typography>
      <Typography variant="body1" className={classes.body}>
        Under data protection law, you have rights including:
      </Typography>
        <ul variant="body1" className={classes.body}>
          <li>
            <strong>Your right of access </strong>- to ask us for a copy of your
            personal information.
          </li>
          <li>
            <strong>Your right to rectification</strong> - to ask us to rectify
            information you think is inaccurate. You also have the right to ask
            us to complete information you think is incomplete.
          </li>
          <li>
            <strong>Your right to erasure</strong> - to ask us to erase your
            personal information in certain circumstances.
          </li>
          <li>
            <strong>Your right to restriction of processing</strong> - You have
            the right to ask us to restrict the processing of your information
            in certain circumstances.
          </li>
          <li>
            <strong>Your right to object to processing</strong> - to the
            processing of your personal data in certain circumstances, and an
            absolute right to object to receiving marketing messages from us.
          </li>
          <li>
            <strong>Your right to data portability</strong> - to ask for a copy
            of the personal data that you have provided to us in a structured,
            commonly used and machine-readable format.
          </li>
        </ul>
        <Typography variant="h6" className={classes.subTitle}>
        WE ARE NOT RESPONSIBLE FOR WEBSITES WE LINK TO
      </Typography>
      <Typography variant="body1" className={classes.body}>Where our site contains links to other sites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them.</Typography>
      <Typography variant="body1" className={classes.body}>We have no control over the contents of those sites or resources.</Typography>
      <Typography variant="h6" className={classes.subTitle}>
        How to complain
      </Typography>
      <Typography variant="body1" className={classes.body}>
        If you are concerned about the contents of this notice or if you have
        other concerns about the personal data that we have, then please contact
        us using the details above and we will do our best to address you
        concerns.
      </Typography>
      <Typography variant="body1" className={classes.body}>
        If you remain concerned, you are always free to contact the Information
        Commissioner’s Office. Their contact details can be found here:
        www.ico.org.uk/concerns
      </Typography>
    </>
  );
};

export default Content;
