import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import DoneIcon from '@material-ui/icons/Done';
import { Cache } from "aws-amplify";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
    getAppliedJobs,
    setJobItemRead,
    setSelectedJob
} from "../../actions/job_actions";
import {
    setNotification,
    setNotificationAmount
} from "../../actions/user_actions";
import _ from 'lodash';
import moment from 'moment';
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    alertModal: {
        width: 320,
        height: 300,
        backgroundColor: "#ffffff",
        position: "fixed",
        zIndex: 1000,
        right: 10,
        top: 75,
        boxShadow: "3px 3px 7px 0px",
        overflowY: "auto"
    },
    padding25: {
        padding: 25,
        paddingBottom: 0,
        margin: 0
    },
    hrColor: {
        backgroundColor: "#EDEFF2",
        height: 1,
        width: "100%",
        marginTop: 5
    },
    fontSizeSmall: {
        fontSize: 10
    },
    fontSizeMedium: {
        fontSize: 16
    },
    smallFontGrey: {
        color: "#959596",
        textAlign: "right",
        fontSize: 10
    },
    description: {
        marginTop: 10
    },
    containerRow: {
        padding: 25,
        paddingBottom: 0,
        margin: 0,
        cursor: "pointer"
    }
}));

function NotificationsCenter(props){
    
    let classes = useStyles();
    let jobList = [];
    let [fetchJobs, setFetchJobs] = React.useState(false);
    let [amount, setAmount] = React.useState(0);
    let display = props.display;
    let setDisplay = props.setDisplay;

    if (props.jobs && props.jobs.appliedJobs && props.jobs.favourited) {
        // lose scoping
        jobList = props.jobs.appliedJobs.concat(props.jobs.favourited.filter(item => item.isInvited))
        jobList = JSON.stringify(jobList);
        jobList = JSON.parse(jobList);
        jobList = _.orderBy(jobList, "savedTimestamp", "desc");
        // console.log("jobList")
        // console.log(jobList)
        jobList = _.filter(jobList, (item) => {
            if (!item.qual_name){
                if (item.isInvited && !item.read){
                    item.notificationDate = moment(item.savedTimestamp).fromNow();
                    item.notificationTitle = "Job Application Invite";
                    item.color = "#93D69E";
                    item.notificationClick = () => {
                        Cache.setItem("sm_current_tab", 4);
                        props.history.push("/app/jobs");
                        // console.log("apply click 0")
                        setTimeout(() => {
                            props.setNotification("saved");
                        }, 100);
                        props.setSelectedJob(item.jobId, item.jobTitle, item.occupation, item.tier_one_category);
                        // props.history.push("/app/jobs/details");
                    };
                    item.icon = <FiberManualRecordIcon style={{color: "#93D69E"}} size="small" className={classes.fontSizeSmall} />;
                    item.notificationDesc = `You have been invited to apply for: ${item.jobTitle}. Click here to view your saved job list and apply.`
                    return item;
                }
                else if (typeof item.interview === "undefined" && !item.unsuccessful && !item.read){
                    item.notificationDate = moment(item.savedTimestamp).fromNow();
                    item.notificationTitle = "Job Status";
                    item.color = "#93D69E";
                    item.notificationClick = () => {
                        Cache.setItem("sm_current_tab", 5);
                        props.history.push("app/jobs");
                        // console.log("apply click")
                        setTimeout(() => {
                        props.setNotification("applied");
                        }, 100);
                        props.setSelectedJob(item.jobId, item.jobTitle, item.occupation, item.tier_one_category);
                        // props.history.push("/app/jobs/details");
                    };
                    item.icon = <FiberManualRecordIcon style={{color: "#93D69E"}} size="small" className={classes.fontSizeSmall} />;
                    item.notificationDesc = `${item.jobTitle}. Click here to go to your applied list and change your status.`
                    return item;
                } else if (typeof item.started === "undefined" && !item.unsuccessful && !item.read){

                    item.notificationDate = moment(item.savedTimestamp).fromNow();
                    item.notificationTitle = "Job Status";
                    item.notificationClick = () => {
                        Cache.setItem("sm_current_tab", 5);
                        props.history.push("app/jobs");
                        // console.log("apply click 2")
                        setTimeout(() => {
                            props.setNotification("applied");
                        }, 100);
                        props.setSelectedJob(item.jobId, item.jobTitle, item.occupation, item.tier_one_category);
                    //     props.history.push("/app/jobs/details");
                    }
                    item.color = "#93D69E";
                    item.icon = <FiberManualRecordIcon style={{color: "#93D69E"}} size="small" className={classes.fontSizeSmall} />;
                    item.notificationDesc = `${item.jobTitle}. Click here to go to your applied list and change your status.`
                    return item;
                } else {
                    return false;
                }
            } else {
                if (typeof item.interview === "undefined" && !item.unsuccessful && !item.read){
                    item.notificationDate = moment(item.savedTimestamp).fromNow();
                    item.notificationTitle = "Training Status 1";
                    item.color = "#6A99FC";
                    item.icon = <FiberManualRecordIcon style={{color: "#6A99FC"}} size="small" className={classes.fontSizeSmall} />;
                    item.notificationClick = () => {
                        Cache.setItem("sm_current_tab", 5);
                        props.history.push("app/jobs");
                        setTimeout(() => {
                            props.setNotification("applied");
                        }, 100);
                        props.setSelectedJob(item.qual_id, item.qual_name, item.occupation, item.tier_one_category, true);
                        // props.history.push("/app/jobs/details");
                        
                    };
                    item.notificationDesc = `${item.qual_name}. Click here to go to your applied list and change your status.`
                    return item;
                } else if (typeof item.started === "undefined" && !item.unsuccessful && !item.read){

                    item.notificationDate = moment(item.savedTimestamp).fromNow();
                    item.notificationTitle = "Training Status";
                    item.color = "#6A99FC";
                    item.icon = <FiberManualRecordIcon style={{color: "#6A99FC"}} size="small" className={classes.fontSizeSmall} />;
                    item.notificationClick = () => {
                        Cache.setItem("sm_current_tab", 5);
                        props.history.push("app/jobs");
                        setTimeout(() => {
                            props.setNotification("applied");
                        }, 100);
                        props.setSelectedJob(item.qual_id, item.qual_name, item.occupation, item.tier_one_category, true);
                        // props.history.push("/app/jobs/details");
                        
                    }
                    item.notificationDesc = `${item.qual_name}. Click here to go to your applied list and change your status.`
                    return item;
                } else {
                    return false;
                }
            }
        });
        if (amount !== jobList.length){
            props.setNotificationAmount(jobList.length);
            setAmount(jobList.length);
        }

        if (jobList.length === 0){
            let item = {};
            // item.notificationDate = moment(item.savedTimestamp).fromNow();
            item.notificationTitle = "No actions pending";
            item.color = "#6A99FC";
            item.notificationClick = () => {}
            item.notificationDesc = `All caught up, way to go!`
            item.icon = <DoneIcon style={{color: "#6A99FC"}} size="small" className={classes.fontSizeMedium} />;
            jobList.push(item);

        }

    } else {
        if (!fetchJobs){
            setFetchJobs(true);
            props.getAppliedJobs();
        }
    }

    return <div onClick={() => {
        setDisplay(false);
    }} style={{top: 0, zIndex: 100000, position: "fixed", width: "100%", height: "100%", display: (display) ? "block": "none" }}>
        <div style={{display: (display) ? "block": "none" }}className={classes.alertModal}>
        <Typography variant="body1" className={`${classes.padding25}`}> 
            Notifications
        </Typography>
        <div className={classes.hrColor}> </div>
        {jobList.map((object, i) => {
        return <Grid onClick={(e) => {
            e.stopPropagation();
            object.notificationClick();
            props.setJobItemRead(object)
            setDisplay(false);
        }} key={`${i}-notifcation`} container className={`${classes.containerRow}`}>
            <Grid item>
                {object.icon}
            </Grid>
            <Grid item xs={7}>
                <Typography variant="body2" style={{color: object.color, fontWeight: "bold"}}>&nbsp; {object.notificationTitle}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography className={classes.smallFontGrey} variant="body2">{object.notificationDate}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.description} variant="body2"> {object.notificationDesc}</Typography>
            </Grid>
            <div className={classes.hrColor}> </div>
        </Grid>
        })}
    </div>
    </div>
}

function mapStateToProps(state) {
    return state;
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      ...bindActionCreators({ getAppliedJobs, setNotification, setNotificationAmount, setJobItemRead, setSelectedJob}, dispatch),
      dispatch,
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(NotificationsCenter));
  