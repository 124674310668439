import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import green from "@material-ui/core/colors/green";
import { Cache } from "aws-amplify";

import {
  confirmLogin as confirmLoginAction,
  login as loginAction,
  setNewPassword as setPasswordAction,
} from "../../actions/auth_actions";
import { AUTH_ERROR_CLEAR } from "../../actions/types";
import * as ROUTES from "../../constants/routes";

import { ReactComponent as SmLogo } from "../Common/Logo/smLogo.svg";
import { ReactComponent as Mountains } from "./Icons/mountains.svg";
import config from "../../config";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    //marginTop: theme.spacing(1)
  },
  formhold: {
    marginLeft: theme.spacing(4),
    marginRight: 27,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2),
    },
  },
  grid: {
    minHeight: "100vh",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: 720,
    maxWidth: 720,
    [theme.breakpoints.down(740)]: {
      maxWidth: 600,
      minWidth: 600,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: 100,
    },
  },
  logingrid: {
    minHeight: 450,
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
      marginBottom: 14,
    },
  },
  textField: {
    marginTop: 0,
    marginBottom: 0,

    fontSize: 14,
  },
  progress: {},
  progressCont: {
    height: 3,
    [theme.breakpoints.down("md")]: {
      height: 2,
    },
    [theme.breakpoints.up("md")]: {
      height: 3,
    },
  },
  marginLabel: {
    margin: 20,
    marginBottom: 0,
    marginTop: 10,
  },
  snack: {
    marginTop: 10,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  success: {
    backgroundColor: green[600],
  },
  version: {
    fontSize: 9,
    color: "#bbb",
    textAlign: "right",
    marginTop: 3,
    marginRight: theme.spacing(2),
    marginBottom: 5,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: "relative",
  },
  logo: {
    width: "100%",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      maxWidth:"80%",
      marginTop:"15px"
    },
    [theme.breakpoints.up("sm")]: {
      maxHeight: 160,
      maxWidth:"100%",
    }
  },
  mountainsgrid: {
    backgroundColor: "#FDEFE7",
    //height: "100%"
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    overflow: "hidden",
    display: "flex",
  },
  mountains: {
    width: "105%",
    marginLeft: -1,
    alignSelf: "flex-end",
    marginBottom: -15,
    [theme.breakpoints.down("xs")]: {
      width: "160%",
      height: "160%",
      marginBottom: -20,
      alignSelf: "flex-start",
    },
  },
  title: {
    fontSize: 32,
    [theme.breakpoints.down("sm")]: {
      fontSize: 25,
    },

    [theme.breakpoints.down("xs")]: {},
  },
  titlesubheading:{
    textAlign: "center",
    color: "#5A6375",
    fontSize: 26,
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    color: "#5A6375",
    fontSize: 14,
    marginTop: 3,
    marginBottom: theme.spacing(2),
  },
  formtitle: {
    color: "#202A3E",
    fontSize: 14,
    marginBottom: 4,
    marginTop: 18,
  },
  forgotpw: {
    color: "#FF6A14",
    fontSize: 12,
    textAlign: "right",
    marginTop: 16,
  },
  tfroot: {
    fontSize: 14,
    backgroundColor: "#F5F6F7",
  },
  tfoutline: {
    border: "none",
  },
  signup: {
    color: "#5A6375",
    fontSize: 14,
    marginTop: 14,
    textAlign: "center",
  },
  poweredBylogo:{
    height: 40, 
    width: 165,
    marginBottom: theme.spacing(2),
  },
  orgLogo:{
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      maxWidth:"80%",
      marginTop:"15px"
    },
    [theme.breakpoints.up("sm")]: {
      maxHeight: 160,
      maxWidth:"100%",
    }
  },
  logoContainer:{
    display: "flex",
    justifyContent: "center"
  },
  privacy: {
    color: "#5A6375",
    fontSize: 12,
    textAlign: "left",
    marginTop: 16,
  },
});

class Login extends Component {
  state = {
    email: "",
    password: "",
    npassword: "",
    presumedOrg: undefined,
    showPassword: false,
    open: false,
    vertical: "top",
    horizontal: "center",
    error: "",
    loading: false,
    version:
      (process.env.REACT_APP_REGION === "eu-west-2" ? "uk." : "ireland.") +
      process.env.REACT_APP_STAGE +
      "@" +
      process.env.REACT_APP_VERSION +
      (process.env.REACT_APP_BUILD
        ? "." + parseInt(process.env.REACT_APP_BUILD, 10)
        : ""),
    manualURL: "",
  };

  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.changePass = this.changePass.bind(this);
  }
  handleClose = () => {
    
    this.setState({ open: false });
    this.props.dispatch({ type: AUTH_ERROR_CLEAR });
  };
  handleForceClose = () => {
    this.setState({ open: false, forceClose: true });
  }
  async componentDidMount() {
    //Auth.currentCredentials();
    //global.AWS.config.credentials = await Auth.currentCredentials();
    if (!this.state.presumedOrg){
      const tryCache = Cache.getItem("origin")
      // console.log(tryCache)
      if (tryCache && tryCache.organisation){
        this.setState({ presumedOrg: tryCache.organisation });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.errorMessage !== prevProps.errorMessage &&
      this.props.errorMessage !== "" &&
      this.props.errorMessage
    ) {
      //temporary fix bypassing Auth.completeNewPassword device key error
      if (this.props.errorMessage && this.props.errorMessage ==="Invalid device key given."){
        window.location.reload();
      }
      else{
        this.setState({
          open: true,
          error: this.props.errorMessage,
          loading: false,
        });
      }      
    }
    if (this.props.changePassword !== prevProps.changePassword) {
      this.setState({
        loading: false,
      });
    }
    if (this.props.needConfirm !== prevProps.needConfirm) {
      this.setState({
        loading: false,
      });
    }
  }

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  login(e) {
    e.preventDefault();

    let username = this.state.email.trim().toLowerCase();
    let password = this.state.password.trim();

    this.setState({
      loading: true,
      error: "",
      open: false,
    });

    this.props.loginAction({ username, password }, this.props.history);
  }

  changePass(e) {
    e.preventDefault();

    this.setState({
      loading: true,
      error: "",
      open: false,
    });

    this.props.setPasswordAction(
      this.props.currentAuthUser,
      this.state.npassword,
      this.props.history
    );
  }

  render() {
    let   { open, error} = this.state;
    const { horizontal, vertical, loading, version, forceClose} = this.state;
    const { classes, changePassword, success } = this.props;
    let queryDict = {};
    window.location.search.substr(1).split("&").forEach(function(item) {queryDict[item.split("=")[0]] = item.split("=")[1]})
    let duration = 3000;

    if (queryDict.message === "inactivity" && !error && !loading && !forceClose){
      duration = false;
      open = true;
      error = "Sorry you have been logged out due to inactivity of 60 minutes, please sign in again.";
    }
    
    let {password,email,presumedOrg} = this.state;

    if (queryDict.email && queryDict.pass && !this.state.queryParamsLoaded){
      password = decodeURIComponent(queryDict.pass);
      email = queryDict.email;
      this.setState({
        password: password,
        email: email,
        queryParamsLoaded: true
      });
    }

    if (!presumedOrg && queryDict.org){
      presumedOrg = queryDict.org;
      this.setState({
        presumedOrg: presumedOrg
      });
    } 

    const isRightLength = this.state.npassword && this.state.npassword.length >= 8 ? true : false;
    const hasSpecial = /[/\W|_/g]/.test(this.state.npassword);
    const hasNumber = this.state.npassword.match(/\d/);
    const hasUpperCase = this.state.npassword.match(/[A-Z]/);

    return (
      <div className={classes.root}>
        <div className={classes.progressCont}>
          {loading && <LinearProgress className={classes.progress} />}
        </div>

        <Grid
          container
          spacing={0}
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.grid}
        >
          <Grid item>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item className={classes.logoContainer}>
                {presumedOrg && presumedOrg !== "skillzminer" ? (
                  <img src={config.STORAGE_CLOUDFRONT_URL +"/logos/"+ presumedOrg +"_appnav_logo.png"} className={classes.orgLogo}/>
                ) : (
                  <SmLogo className={classes.logo} />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Paper className={classes.main}>
              <Grid
                container
                spacing={0}
                direction="row-reverse"
                justify="center"
                alignItems="stretch"
                className={classes.logingrid}
              >
                <Hidden xsDown>
                  <Grid item xs={12} sm={5} className={classes.mountainsgrid}>
                    <Mountains className={classes.mountains} />
                  </Grid>
                </Hidden>
                <Grid item xs={12} sm={7} className={classes.fieldholdergrid}>
                  {!changePassword && (
                    <div className={classes.formhold}>
                      <Typography variant="h4" className={classes.title}>
                        Sign in
                      </Typography>
                      <form className={classes.form} onSubmit={this.login}>
                        <Typography
                          variant="body1"
                          className={classes.formtitle}
                        >
                          Email:
                        </Typography>
                        <TextField
                          id="outlined-email-input"
                          className={classes.textField}
                          type="email"
                          name="email"
                          fullWidth
                          autoComplete="email"
                          placeholder="name@email.com"
                          margin="normal"
                          size="small"
                          data-test="login-email"
                          variant="outlined"
                          required
                          defaultValue={email}
                          onChange={this.handleChange("email")}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.tfoutline,
                              root: classes.tfroot,
                            },
                          }}
                        />
                        <Typography
                          variant="body1"
                          className={classes.formtitle}
                        >
                          Password:
                        </Typography>
                        <TextField
                          fullWidth
                          id="password"
                          size="small"
                          placeholder="Enter your password"
                          autoComplete="current-password"
                          className={classes.textField}
                          variant="outlined"
                          type={this.state.showPassword ? "text" : "password"}
                          defaultValue={password}
                          onChange={this.handleChange("password")}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.tfoutline,
                              root: classes.tfroot,
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                >
                                  {this.state.showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          required
                        />
                        <Typography
                          variant="body2"
                          className={classes.forgotpw}
                        >
                          <Link id="forgot-password-link" component={RouterLink} to={ROUTES.FORGOT}>
                            Forgotten your password?
                          </Link>
                        </Typography>
                        <div className={classes.wrapper}>
                          <Button
                            variant="contained"
                            className={classes.button}
                            color="primary"
                            type="submit"
                            fullWidth
                            disabled={loading}
                            id="sign-in-btn"
                          >
                            Sign in
                          </Button>
                          {loading && (
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                        </div>
                      </form>
                      <Divider />

                      {!this.state.queryParamsLoaded &&
                      <>
                        <Typography variant="body2" className={classes.signup}>
                          Don't have an account?{" "}
                          <Link id="sign-up-link" component={RouterLink} to={ROUTES.SIGNUP}>
                            Sign Up
                          </Link>
                        </Typography>
                        <Typography variant="body2" className={classes.signup}>
                          <Link id="youtube-link" href="https://www.youtube.com/watch?v=1u9GtcsA5z4&list=PLaqkddI5TjYUXqGx-0rFFF2vZVTqYc2qp/" target="_blank" rel="noopener noreferrer">
                            Visit
                          </Link>
                          {" "} our Youtube Channel
                        </Typography>
                      </>
                      }
                      {this.state.queryParamsLoaded &&
                      <Typography variant="body2" className={classes.signup}>Your temporary login details have been entered, please push sign in to set your permanent credentials.</Typography>
                      }
                    </div>
                  )}

                  {changePassword && (
                    <div className={classes.formhold}>
                      <form className={classes.form} onSubmit={this.changePass}>
                        <Typography variant="h4" className={classes.title}>
                          Final Step
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.subtitle}
                        >
                          Please set a new password
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.formtitle}
                        >
                          Password
                        </Typography>
                        {this.state.npassword.length > 0 && (
                          <Paper style={{marginBottom:"5px",backgroundColor: "#faebd7"}}>
                            <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                              <Grid item xs={6}>
                                <Typography variant="body2" className={classes.privacy} style={{alignItems: "center", display: "flex"}}>
                                  {hasUpperCase ? <CheckCircleIcon style={{color:"#69cc63"}}/> : <CancelIcon style={{color:"#ff4c4c"}}/>}
                                  1 uppercase character
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body2" className={classes.privacy} style={{alignItems: "center", display: "flex"}}>
                                  {hasNumber ? <CheckCircleIcon style={{color:"#69cc63"}}/> : <CancelIcon style={{color:"#ff4c4c"}}/>}
                                  1 number
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body2" className={classes.privacy} style={{alignItems: "center", display: "flex"}}>
                                  {hasSpecial ? <CheckCircleIcon style={{color:"#69cc63"}}/> : <CancelIcon style={{color:"#ff4c4c"}}/>}
                                  1 symbol
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body2" className={classes.privacy} style={{alignItems: "center", display: "flex"}}>
                                  {isRightLength ? <CheckCircleIcon style={{color:"#69cc63"}}/> : <CancelIcon style={{color:"#ff4c4c"}}/>}
                                  8 characters minimums
                                </Typography>
                              </Grid>
                            </Grid>
                          </Paper>
                        )}
                        <TextField
                          id="npassword"
                          className={classes.textField}
                          variant="outlined"
                          placeholder="New password"
                          type={this.state.showPassword ? "text" : "password"}
                          fullWidth
                          autoComplete="off"
                          size="small"
                          value={this.state.npassword}
                          onChange={this.handleChange("npassword")}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.tfoutline,
                              root: classes.tfroot,
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                >
                                  {this.state.showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          required
                        />
                        <Button
                          variant="contained"
                          className={classes.button}
                          color="primary"
                          type="submit"
                          fullWidth
                        >
                          Change password
                        </Button>
                      </form>{" "}
                    </div>
                  )}
                  {presumedOrg && presumedOrg !== "skillzminer" && (
                    <Grid
                    container
                    spacing={0}
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item style={{marginBottom:5, marginTop:0}}>
                      <Typography variant="body2" className={classes.signup}>
                        Powered By
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img
                        src={
                          config.STORAGE_CLOUDFRONT_URL +"/logos/"+ presumedOrg +"_powered_by_logo.png"
                        }
                        className={classes.poweredBylogo}
                      />
                    </Grid>
                  </Grid>
                  )}
                  <div className={classes.version}>{version}</div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={!!open}
          className={classes.snack}
          onClose={this.handleClose}
          onClick={this.handleForceClose}
          autoHideDuration={duration}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
        >
          <SnackbarContent
            className={classes.error}
            aria-describedby="client-snackbar"
            message={<span id="message-id">{error}</span>}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={!!success}
          className={classes.snack}
          onClose={this.handleClose}
          autoHideDuration={3000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
        >
          <SnackbarContent
            className={classes.success}
            aria-describedby="client-snackbar"
            message={<span id="message-id">{success}</span>}
          />
        </Snackbar>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.auth.error,
    mfaRequired: state.auth.mfa,
    changePassword: state.auth.changePassword,
    currentAuthUser: state.auth.currentAuthUser,
    success: state.auth.success,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        loginAction,
        confirmLoginAction,
        setPasswordAction,
      },
      dispatch
    ),
    dispatch,
  };
}

Login = connect(mapStateToProps, mapDispatchToProps)(Login);

export default withStyles(styles)(Login);
