import ReactGA from 'react-ga';
import config from "../../config/";
import { Cache } from "aws-amplify";


function GA () {
    if (Cache.getItem("CookiePermissions")){
        this.permissions = true;
        ReactGA.initialize(config.ga_uuid, {
            // debug: true
        });
    } else {
        this.permissions = false;
    }
}


GA.prototype.setUserFromCache = function(){
    let userId = Cache.getItem("UserID");
    if (userId) this.setData({userId: userId});
}


GA.prototype.setData = function (data){
    if (this.permissions) ReactGA.set(data);
}

GA.prototype.trackPage = function(pageURL, title) {
    if (this.permissions) ReactGA.pageview(pageURL, "", title);
}

GA.prototype.trackEvent = function(event) {
    if (this.permissions) ReactGA.event(event);
}

export {GA}
