import { API } from "aws-amplify";
import {
  SEND_CHAT_MESSAGE,
  RECEIVE_BOT_MESSAGE,
  RECEIVE_BOT_STATE,
  CHAT_WELL_DONE,
  RECEIVE_BOT_DONE,
  RESET_MESSAGES,
  // USER_PROFILE_EDIT_DONE,
} from "./types";
import config from "../config/";
import { v4 as uuidv4 } from "uuid";
import { Logger } from "aws-amplify";
import _ from "lodash";
import moment from "moment";
import {fetchCoins} from "./reward_actions";
//import { fetchUser } from "./user_actions";

const logger = new Logger("CHAT.ACTIONS", "INFO");

const botDelay = 1000;

export function sendMessage(messages) {
  return async function (dispatch, getState) {
    const state = getState();

    var isInterventionBot = state.user.isInterventionOngoing || false;
    //console.log(messages);
    var msg = messages[0];

    if (!msg.hidden) {
      dispatch({
        type: SEND_CHAT_MESSAGE,
        messages: messages,
        disableInput: true,
      });
    }

    if (msg.isRetry) {
      if (state.chat.messages[1]) {
        msg.text = state.chat.messages[1].text;
      }
    }

    try {   
      const r = await API.post(config.API_NAME, "/standalone-chatbot", {
        body: {
          id: msg.user._id,
          target_bot: isInterventionBot ? "intervention" : "skillzminer_web",
          utterance: msg.text,
          //isTest: this.props.isTest,
        },
      });
      //logger.info(r);
      dispatch(receiveMessageArray(r));
      if (r.state) {
        dispatch({ type: RECEIVE_BOT_STATE, payload: r.state });
        //trigger caseload increments from bot here
        if (r.state.caseloadEvent && r.state.caseloadEvent.eventType && r.state.caseloadEvent.timestamp){
          const ts = moment.unix(r.state.caseloadEvent.timestamp).utc()
          API.patch(config.API_NAME, `/caseload/increment`, {
            body: {
              contract: state.user.contract,
              provider: state.user.provider,
              organisation: state.user.organisation,
              keyworker: state.user.keyworkerID,
              eventType: r.state.caseloadEvent.eventType,
              startOfDay: ts.startOf("day").unix(),
              startOfWeek: ts.startOf("week").unix(),
              startOfMonth: ts.startOf("month").unix()
            }
          });
        }
      }
      if (r.isDone === true) {
        dispatch({ type: RECEIVE_BOT_DONE });
        dispatch(fetchCoins());
      }
      if (r.clearProfileImage === true) {
        dispatch({ type: RESET_MESSAGES });
        //remove profile image
        /* Storage.remove("profile.png", { level: "private" })
          .then((result) => {
            dispatch(fetchUser());
          })
          .catch((err) => {});*/
      }
    } catch (e) {
      logger.error(e);
      dispatch(
        receiveMessageArray({
          Bot_Response: ["Sorry, it seems that an error happened... "],
          Question_Flag: "hasChoices",
          hasChoices: "True",
          choices: [{ title: "Try again", value: "retry" }],
          state: {},
        })
      );
    }
  };
}

export function receiveMessageArray(response) {
  return async function (dispatch) {
    var messages = response.Bot_Response;

    logger.info(messages);

    _.each(messages, (text, index) => {
      var disableInput = true;

      var video = null;

      //check for video
      let pattern = /<a\s.*?['"]\s*>((?:(?!<\/a>).)*)<\/a>/;
      let match = text.match(pattern);
      //console.log(match);

      if (
        match &&
        text.indexOf("video") !== -1 &&
        text.indexOf("youtube") !== -1
      ) {
        /*eslint-disable */
        let pattern = /<a[^>]+href=\"(.*?)\"[^>]*>/;
        /*eslint-enable */
        let m = text.match(pattern);

        video = m[1];
      }

      var botMsg = {
        text: text,
        _id: uuidv4(),
        user: {
          _id: "bot",
        },
        system: false,
        video: video,
      };

      if (index + 1 === messages.length) {
        if (response.hasChoices || response.hasOptionalChoices || response.hasChoicesMultiple) {
          botMsg.typing = false;
          botMsg.ui_event = response.state.ui_event ? response.state.ui_event : [] ;
          botMsg.quickReplies = {
            type: "radio",
            keepIt: false,
            values: [],
          };

          disableInput = response.hasChoices || response.hasChoicesMultiple ? true : false;
          if (response.hasChoicesMultiple){
            botMsg.quickReplies.values.push({
              value: "",
              title: "Confirm",
              color: undefined
            });
            botMsg.quickReplies.choices = response.choices
            botMsg.ui_event = ["multiSelect"]
          }
          else{
            _.each(response.choices, (item, i) => {
              var val;
              var title;
              var c;
  
              if (item.value) {
                val = item.value;
                title = item.title;
                
              } else {
                val = item;
                title = item;
              }
  
              if(response.choicesColours){
                c = response.choicesColours[i];
              }
  
              botMsg.quickReplies.values.push({
                value: val,
                title: title,
                color: c
              });
            });
          }
        } else {
          disableInput = false;
        }
      }

      setTimeout(
        () =>
          dispatch({
            type: RECEIVE_BOT_MESSAGE,
            messages: [botMsg],
            disableInput: disableInput,
          }),
        botDelay * (index + 1)
      );
    });
  };
}

export function clickWellDone() {
  return async function (dispatch) {
    //Cache.setItem("clickedHello", true)
    dispatch({ type: CHAT_WELL_DONE });
  };
}

export function resetMessages() {
  return async function (dispatch) {
    dispatch({ type: RESET_MESSAGES });
  };
}
