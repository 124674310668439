import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Spring, config } from "react-spring/renderprops";

import * as ROUTES from "../../constants/routes";

import { ReactComponent as Coin } from "./Icons/coin.svg";

import { clickWellDone } from "../../actions/chat_actions";
import { fetchUser, doneProfileEdit } from "../../actions/user_actions";
import { getMissionsAndInterventions } from "../../actions/mission_actions";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 12000,
    position: "absolute",
    backgroundColor: "#53D28E",
    width: "100%",
    height: "100%",
    overflow: 'scroll' 
  },
  grid: {
    height: "100%",
  },
  gridin: {
    textAlign: "center",
  },
  icon: {
    width: "100%",
    height: "100%",
  },
  icondiv: {
    width: 155,
    height: 181,
    marginLeft: "auto",
    marginRight: "auto",
  },
  title: {
    fontSize: 30,
    fontWeight: "bold",
    color: "#ffffff",
    marginTop: theme.spacing(2),
    marginBottom: 15,
  },
  subtitle: {
    fontSize: 22,
    fontWeight: "bold",
    color: "#ffffff",
    marginTop: theme.spacing(2),
    marginBottom: 8,
  },
  body: {
    //maxWidth: "70%",
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8),
    textAlign: "center",
    fontSize: 20,
    color: "#ffffff",

    marginBottom: 36,
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      fontSize: 16,
    },
  },
  cardholder: {
    maxWidth: 880,
    margin: "auto",
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  card: {
    height: "100%",
    padding: 15,
    textAlign: "left",
  },
  bodycard: {
    fontSize: 12,
    color: "#5A6375",
    textAlign: "left",
    marginTop: 5,
  },
  button: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  cardtitle: {
    fontWeight: 600,
    fontSize: 14,
  },
  skills: {
    color: "#53D28E",
  },
  interests: {
    color: "#2988FF",
  },
  styles: {
    color: "#FF9800",
  },
  backdrop: {
    zIndex: 8000,
  },
});

class WellDone extends Component {
  state = {
    show: false,
    transformTo: {},
  };
  async componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):

    if (
      this.props.chat &&
      prevProps.chat &&
      this.props.chat.showWellDone !== prevProps.chat.showWellDone &&
      this.props.chat.showWellDone === true
    ) {
      var that = this;

      setTimeout(async function () {
        if (
          !that.props.user.isInterventionOngoing &&
          !that.props.user.isProfileBeingEdited
        ) {
          that.setState({
            show: true,
            transformTo: { transform: "scale(1, 1)", opacity: 1 },
          });
        }
      }, 4000);

      setTimeout(async function () {
        let runDoneEdit  = false
        if (that.props.user.isInterventionOngoing) {
          that.props.history.push(ROUTES.MISSIONS);
          runDoneEdit = true
        }
        if (that.props.user.isProfileBeingEdited) {
          that.props.history.push(ROUTES.PROFILE);
          runDoneEdit = true
        }
        await that.props.fetchUser();
        if (runDoneEdit) {
          that.props.doneProfileEdit();
        }
        await that.props.getMissionsAndInterventions(true);
      }, 5000);

      setTimeout(async function () {
        if (!prevProps.user.isInterventionOngoing && !prevProps.user.isProfileBeingEdited) {
          that.props.history.push(ROUTES.JOBS);
        } else {
          that.props.clickWellDone();
        }
      }, 6000);

      // this.props.history.push(ROUTES.JOBS);
    }

    if (
      this.props.chat &&
      prevProps.chat &&
      this.props.chat.showWellDone !== prevProps.chat.showWellDone
    ) {
      this.setState({
        show: this.state.show && this.props.chat.showWellDone,
        transformTo: { transform: "scale(-0.1, 0.1)", opacity: 0.5 },
      });
    }
  }

  render() {
    const {
      classes,
      /*eslint-disable */
      chat: { showWellDone = false },
      /*eslint-enable */
      user: { showChat = true },
    } = this.props;
    return (
      <Backdrop
        open={this.state.show}
        transitionDuration={{
          appear: 0,
          exit: 500,
          enter: 200,
        }}
        classes={{
          root: classes.backdrop,
        }}
      >
        <div className={classes.root}>
          <Grid
            container
            spacing={0}
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.grid}
          >
            <Grid item className={classes.gridin}>
              <Spring
                from={{ transform: "scale(-0.1, 0.1)", opacity: 0.5 }}
                to={this.state.transformTo}
                delay={200}
                config={config.wobbly}
              >
                {(props) => (
                  <div style={props} className={classes.icondiv}>
                    <Coin className={classes.icon} />
                  </div>
                )}
              </Spring>

              <Typography variant="h4" className={classes.title}>
                Well Done!
              </Typography>
              <Typography variant="h5" className={classes.subtitle}>
                You’ve completed the initial assessment.
              </Typography>
              <Typography variant="body1" className={classes.body}>
                We now understand you a lot better – and your opportunities are waiting for you. 
                <br />
                But we’d like to get to know you even more. Take part in our missions and we’ll assess your answers further to match you to vacancies perfect for you.
                <br />
                Use the filters to sort and find new jobs, watch videos to see if a new career is right for you and find learning opportunities to fill your skills gaps.
              </Typography>

              <Button
                variant="contained"
                className={classes.button}
                color="primary"
                disabled={showChat}
                onClick={() => {
                  this.props.clickWellDone();
                }}
              >
                Let's Go
              </Button>
            </Grid>
          </Grid>
        </div>
      </Backdrop>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      { clickWellDone, fetchUser, getMissionsAndInterventions, doneProfileEdit },
      dispatch
    ),
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(WellDone)));
