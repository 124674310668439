import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import WifiOffIcon from "@material-ui/icons/WifiOff";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { createGenerateClassName, StylesProvider } from "@material-ui/styles";
import { Cache, Logger, Storage } from "aws-amplify";
import React from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import { bindActionCreators } from "redux";
import { validateUserSession as validateUserAction } from "./actions/auth_actions";
import { getHelpVideos } from "./actions/resources_actions";
import "./App.css";
import Forget from "./components/Auth/Forget";
import Login from "./components/Auth/Login";
import Unsub from "./components/Auth/Unsub";
import ApplicationTracking from "./components/Auth/ApplicationTracking";
import Signup from "./components/Auth/Signup";
import { RestoreScroll } from "./components/Common/RestoreScroll";
import Landing from "./components/Landing";
import Main from "./components/Main";
import Help from "./components/Help";
import config from "./config/";
import * as ROUTES from "./constants/routes";
import AutoLogout from './components/AutoLogout';

Storage.configure({ track: true });

const generateClassName = createGenerateClassName({
  productionPrefix: "sm",
  seed: "sm",
});

const styles = (theme) => ({
  alert: {
    margin: theme.spacing(1),
    root: {
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.1),0px 1px 3px 0px rgba(0,0,0,0.1)",
    },
  },
  alertcontainer: {
    position: "absolute",
    top: 10,
  },
  backdrop: {
    zIndex: 8000,
  },
});

Logger.LOG_LEVEL = process.env.REACT_APP_STAGE === "prod" ? "ERROR" : "INFO";

const logger = new Logger("MAIN", "INFO");

const NotFoundRedirect = () => <Redirect to="/" />;

const PublicRoute = ({ component: ReactComponent, authStatus, ...rest }) => {
  logger.info("App.PublicRoute() authStatus:", authStatus);

  return (
    <Route
      {...rest}
      render={(props) =>
        authStatus === true ? (
          rest.path === ROUTES.APPLICATION ? 
            <ReactComponent {...props} />
          :
            <Redirect
              to={
                Cache.getItem("lastpath") ? Cache.getItem("lastpath") : ROUTES.APP
              }
              {...props}
            />
        ) : authStatus === false ? (
          <ReactComponent {...props} />
        ) : (
          <LinearProgress />
        )
      }
    />
  );
};

const PrivateRoute = ({ component: ReactComponent, authStatus, ...rest }) => {
  var x = { ...rest };
  let path = x.location.pathname;

  logger.info("App.PrivateRoute() authStatus:", authStatus, path);
  return (
    <div>
    <AutoLogout />
    <Route
      {...rest}
      render={(props) =>
        authStatus === true ? (
          <ReactComponent {...props} />
        ) : authStatus === false ? (
          <Redirect to={ROUTES.LOGIN} />
        ) : (
          <LinearProgress />
        )
      }
    />
    </div>
  );
};

const DefaultRoute = ({ authStatus, ...rest }) => {
  logger.info("App.DefaultRoute() authStatus:", authStatus);
  Cache.removeItem("lastpath");
  Cache.removeItem("previouspath");
  return (
    <Route
      {...rest}
      render={(props) =>
        authStatus === true ? (
          <Redirect to={ROUTES.APP} {...props} />
        ) : (
          /* <Redirect to={ROUTES.HELLO} /> */
          <Redirect to={ROUTES.LOGIN} />
        )
      }
    />
  );
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);
    this.currentPathname = null;
    this.currentSearch = null;
  }
  state = {
    isConnected: true,
  };

  componentDidMount() {
    logger.info("App.componentDidMount() props: ", this.props);
    // if session contains valid

    this.validateUser();
    //window.addEventListener("beforeunload", this.handleWindowClose);

    this.interval = setInterval(() => {
      this.validateUser();
    }, config.DEFAULT_SESSIONCHECK_TIME);
    //session check in every 5 mins

    this.handleConnectionChange();
    window.addEventListener("load", this.handleLoad);
    window.addEventListener("online", this.handleConnectionChange);
    window.addEventListener("offline", this.handleConnectionChange);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    //window.removeEventListener("beforeunload", this.handleWindowClose);
    window.removeEventListener("load", this.handleLoad);
    window.removeEventListener("online", this.handleConnectionChange);
    window.removeEventListener("offline", this.handleConnectionChange);
  }

  handleLoad = async (e) => {
    e.preventDefault();
    //console.log("load");
  };

  handleConnectionChange = () => {
    const condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") {
      const webPing = setInterval(() => {
        fetch("https://google.com", {
          mode: "no-cors",
        }).then(() => {
            this.setState({ isConnected: true }, () => {
              return clearInterval(webPing);
            });
          }).catch(() => {
            fetch("https://bing.com", {
              mode: "no-cors",
            }).then(() => {
                this.setState({ isConnected: true }, () => {
                  return clearInterval(webPing);
                });
              }).catch(() => this.setState({ isConnected: false }));
          });
      }, 2000);
      return;
    }
    return this.setState({ isConnected: false });
  };

  validateUser() {
    if (this.props.isAuthenticated !== false) {
      this.props.validateUserAction();
    }
  }

  render() {
    const {
      isUpdateAvailable,
      updateAssets,
      classes,
      isAuthenticated,
    } = this.props;

    const { isConnected } = this.state;
    return (
      <div>
        <Backdrop
          open={!isConnected || isUpdateAvailable}
          classes={{
            root: classes.backdrop,
          }}
        >
          <Container
            component="div"
            maxWidth="sm"
            className={classes.alertcontainer}
          >
            {!isConnected && (
              <Alert
                severity="warning"
                className={classes.alert}
                icon={<WifiOffIcon fontSize="inherit" />}
              >
                <AlertTitle>Warning</AlertTitle>It seems that you are offline,
                please check your network connection!
              </Alert>
            )}

            {isUpdateAvailable && (
              <Alert
                severity="info"
                className={classes.alert}
                action={
                  <Button
                    aria-label="refresh"
                    color="inherit"
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      updateAssets();
                    }}
                  >
                    Refresh
                  </Button>
                }
              >
                <AlertTitle>Info</AlertTitle>An update is available, please
                refresh!
              </Alert>
            )}
          </Container>
        </Backdrop>
        <StylesProvider generateClassName={generateClassName}>
          <Router>
            <Help isAuthenticated={isAuthenticated} getHelpVideos={this.props.getHelpVideos}/>
            <RestoreScroll />
            <Switch>
              <DefaultRoute
                exact
                path={ROUTES.DEFAULT}
                authStatus={isAuthenticated}
              />
              <PrivateRoute
                path={ROUTES.APP}
                component={Main}
                authStatus={isAuthenticated}
              />
              <PublicRoute
                exact
                path={ROUTES.LOGIN}
                component={Login}
                authStatus={isAuthenticated}
              />
              <PublicRoute
                exact
                path={ROUTES.SIGNUP}
                component={Signup}
                authStatus={isAuthenticated}
              />
              <PublicRoute
                exact
                path={ROUTES.FORGOT}
                component={Forget}
                authStatus={isAuthenticated}
              />
              <PublicRoute
                exact
                path={ROUTES.HELLO}
                component={Landing}
                authStatus={isAuthenticated}
              />
              <PublicRoute
                exact
                path={ROUTES.UNSUB}
                component={Unsub}
                authStatus={isAuthenticated}
              />
              <PublicRoute
                exact
                path={ROUTES.APPLICATION}
                component={ApplicationTracking}
                authStatus={isAuthenticated}
              />
              <Route component={NotFoundRedirect} />
            </Switch>
          </Router>
        </StylesProvider>
      </div>
    );
  }
}

// Runtime type checking for React props
/*
App.propTypes = {
  validateUser: PropTypes.func,
  history: PropTypes.object,
  errorMessage: PropTypes.string
};
*/

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.authenticated,
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ validateUserAction, getHelpVideos }, dispatch),
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(App));
