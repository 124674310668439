import { combineReducers } from "redux";
import { RESET_APP } from "../actions/types";
import authReducer from "./authReducer";
import { chatReducer } from "./chatReducer";
import { jobReducer } from "./jobReducer";
import { missionReducer } from "./missionReducer";
import { rewardReducer } from "./rewardReducer";
import resourceReducer from "./resourceReducer";
import applicationAssistantReducer from "./applicationAssistantReducer";
import userReducer from "./userReducer";

const allReducers = combineReducers({
  auth: authReducer,
  user: userReducer,
  jobs: jobReducer,
  chat: chatReducer,
  rewards: rewardReducer,
  missions: missionReducer,
  resources: resourceReducer,
  applicationAssistant: applicationAssistantReducer
});

const rootReducer = (state, action) => {
  if (action.type === RESET_APP) {
    state = {
      ...state,
      user: {},
      chat: {
        messages: [],
      },
    };
  }
  return allReducers(state, action);
};

export default rootReducer;
